import StartupScreen from '../views/SetupScreen.vue'
import Company from '../views/Company/Index.vue'
import Invitations from '../views/Invitations.vue'
import Vehicles from '../views/Company/Vehicles/Vehicles.vue'
import VehicleIndex from '../views/Company/Vehicle/Index.vue'
import VehicleSettings from '../views/Company/Vehicle/Settings.vue'
import VehicleCalendar from '../views/Company/Vehicle/Calendar.vue'
import VehicleUsers from '../views/Company/Vehicle/Users.vue'
import VehicleUserAdd from '../views/Company/Vehicle/UserAdd.vue'
import VehicleUserEdit from '../views/Company/Vehicle/UserEdit.vue'
import Trips from '../views/Company/Vehicle/Trips.vue'
import Expenses from '../views/Company/Vehicle/Expenses.vue'

import CompanySelectionListsIndex from '../views/Company/SelectionLists/Index.vue'
import CompanySelectionListTags from '../views/Company/SelectionLists/Tags.vue'

import Employees from '../views/Company/Employees/Employees.vue'
import EmployeesIndex from '../views/Company/Employees/Index.vue'
import EmployeesInvitations from '../views/Company/Employees/Invitations.vue'

import ReportTemplates from '../views/Company/Reports/ReportTemplates.vue'
import SettingsIndex from '../views/Company/Settings/Index.vue'
import SettingsGeneral from '../views/Company/Settings/General.vue'
import SettingsBilling from '../views/Company/Settings/Billing.vue'

import store from '../store'

export default [
    // {
    //     path: '/startup',
    //     component: StartupScreen,
    //     beforeEnter: async (to, from, next) => {
    //         if (store.state.company) {
    //             next('/company')
    //             return
    //         }
    //         next()
    //     },
    // },
    {
        path: '/company/:companyId',
        component: Company,
        // redirect: { name: 'Vehicles' },
        // name: 'Company',
        // beforeEnter: async (to, from, next) => {
        //     if (!store.state.company) {
        //         next('/startup')
        //         return
        //     }
        //     next()
        // },
        children: [
            // { path: '/', name: 'Main', component: Driverslog },
            { path: '/', redirect: { name: 'Vehicles' }, name: 'Company' },
            { path: '/invitations', name: 'Invitations', component: Invitations },

            // Verhicles
            { path: 'vehicles', name: 'Vehicles', component: Vehicles, meta: { menuId: 'company.vehicles' } },
            // { path: 'vehicles/add', name: 'VehicleAdd', component: AddVehicle, meta: { category: 'index' } },
            {
                path: 'vehicles/:vehicleId', name: 'vehicle', component: VehicleIndex, children: [
                    { path: 'trips', name: 'VehicleTrips', component: Trips, meta: { menuId: 'company.vehicles', category: 'trips' } },
                    { path: 'expenses', name: 'VehicleExpenses', component: Expenses, meta: { menuId: 'company.vehicles', category: 'expenses' } },
                    { path: 'users', name: 'VehicleUsers', component: VehicleUsers, meta: { menuId: 'company.vehicles', category: 'users' } },
                    { path: 'users/add', name: 'VehicleUserAdd', component: VehicleUserAdd, meta: { menuId: 'company.vehicles', category: 'users' } },
                    { path: 'users/:userId', name: 'VehicleUserEdit', component: VehicleUserEdit, meta: { menuId: 'company.vehicles', category: 'users' } },
                    { path: 'calendar', name: 'VehicleCalendar', component: VehicleCalendar, meta: { menuId: 'company.vehicles', category: 'calendar' } },
                    { path: 'settings', name: 'VehicleSettings', component: VehicleSettings, meta: { menuId: 'company.vehicles', category: 'settings' } },
                ]
            },

            // Reports
            { path: 'report-templates', name: 'ReportTemplates', component: ReportTemplates, meta: { menuId: 'company.reportTemplates' } },

            // SelectionLists
            {
                path: 'selectionlists', name: 'CompanySelectionLists', redirect: { name: 'CompanySelectionListTags' }, component: CompanySelectionListsIndex, children: [
                    { path: 'tags', name: 'CompanySelectionListTags', component: CompanySelectionListTags, meta: { menuId: 'company.selectionLists', category: 'tags' } },
                ]
            },

            // Employees
            {
                path: 'employees', name: 'Employees', redirect: { name: 'EmployeesList' }, component: EmployeesIndex, children: [
                    { path: 'list', name: 'EmployeesList', component: Employees, meta: { menuId: 'company.employees', category: 'list' } },
                    { path: 'invitations', name: 'EmployeesInvitations', component: EmployeesInvitations, meta: { menuId: 'company.employees', category: 'invitations' } },
                ]
            },

            // Settings
            {
                path: 'settings', name: 'Settings', redirect: { name: 'SettingsGeneral' }, component: SettingsIndex, children: [
                    { path: 'general', name: 'SettingsGeneral', component: SettingsGeneral, meta: { menuId: 'company.settings', category: 'general' } },
                    { path: 'billing', name: 'SettingsBilling', component: SettingsBilling, meta: { menuId: 'company.settings', category: 'billing' } },
                ]
            },

            // { path: '/company', name: 'Company', component: Company, children: [
            //     { path: 'settings', name: 'CompanySettings', component: CompanySettings },
            //     { path: 'users', name: 'CompanyUsers', component: CompanyUsers },
            //     { path: 'vehicle', name: 'CompanyVehicles', component: CompanyVehicles },
            // ]},
            // { path: 'settings', name: 'Settings', component: SettingsGeneral },
        ]
    },
]