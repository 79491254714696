import Vue from 'vue'

export default {
    async mergeTrips(tripIds) {
        let result = await Vue.axios.post('/api/transactions/merge-trips', {
            data: {
                type: 'merge-trip-transaction',
                attributes: {
                    tripIds: tripIds
                }
            }
        })
        return result.data
    },
}