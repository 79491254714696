<template>
  <div v-if="isMounted">
    <b-modal id="modal-editor" size="md" :title="editorTitle" ok-only no-stacking centered>
      <template #default="">
        <b-container fluid class="p-0">
          <b-row>
            <b-col>
              <b-form-group :label="$t('view.vehicles.editor.licensePlate')" label-class="label">
                <b-form-input v-model="vehicle.licensePlate" type="text" :disabled="isBusy"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('view.vehicles.editor.timeZone')" label-class="label">
                <b-form-select v-model="vehicle.timeZone" :options="timeZones" class="form-control" :disabled="isBusy">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('view.vehicles.editor.systemOfUnits')" label-class="label">
                <b-form-select v-model="vehicle.systemOfUnits" :options="systemOfUnits" class="form-control"
                  :disabled="isBusy"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('view.vehicles.editor.currencyCode')" label-class="label">
                <b-form-select v-model="vehicle.currencyCode" :options="currencyCodes" class="form-control"
                  :disabled="isBusy"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('view.vehicles.editor.subscription')" label-class="label">
                <b-form-select v-model="selectedSubscription" :options="subscriptionItems" class="form-control"
                  :disabled="isBusy"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="4">
              <b-form-checkbox v-model="vehicle.isAutoRecordingEnabled" :value="true" :unchecked-value="false">
                {{ $t('view.vehicle.settings.autoRecording') }}</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox v-model="vehicle.isRecordTrackEnabled" :value="true" :unchecked-value="false">
                {{ $t('view.vehicle.settings.recordTrack') }}</b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox v-model="vehicle.isSaveTrackEnabled" :value="true" :unchecked-value="false">
                {{ $t('view.vehicle.settings.saveTrack') }}</b-form-checkbox>
            </b-col>
          </b-row>
          <!-- <b-row align-v="end">
                  <b-col cols="4">
                      <b-form-group :label="$t('view.vehicle.settings.autoRecording')" label-class="label">
                          <b-form-select v-model="vehicle.isAutoRecordingEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col cols="4">
                      <b-form-group :label="$t('view.vehicle.settings.recordTrack')" label-class="label">
                          <b-form-select v-model="vehicle.isRecordTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col cols="4">
                      <b-form-group :label="$t('view.vehicle.settings.saveTrack')" label-class="label">
                          <b-form-select v-model="vehicle.isSaveTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                  </b-col>
                </b-row> -->
          <!-- <b-row><b-col>
                      <b-form-group :label="$t('view.vehicle.settings.autoRecording')" label-class="label">
                          <b-form-select v-model="vehicle.isAutoRecordingEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                      <b-form-group :label="$t('view.vehicle.settings.recordTrack')" label-class="label">
                          <b-form-select v-model="vehicle.isRecordTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                      <b-form-group :label="$t('view.vehicle.settings.saveTrack')" label-class="label">
                          <b-form-select v-model="vehicle.isSaveTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                      </b-form-group>
                </b-col></b-row> -->

          <!-- <b-row><b-col>
                    <b-form-group :label="$t('view.vehicles.editor.subscription')" label-class="label">
                        <b-form-select v-model="selectedSubscription" :options="subscriptionItems" class="form-control" :disabled="isBusy"></b-form-select>
                    </b-form-group>
                </b-col></b-row> -->
        </b-container>
      </template>
      <template #modal-footer="{ cancel }">
        <div class="d-flex w-100">
          <b-button variant="outline-danger" v-if="vehicle.id" :disabled="isBusy" @click="onDeleteClicked()">Löschen
          </b-button>
          <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy" @click="cancel()">Abbrechen
          </b-button>
          <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">Speichern</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import CompanyApi from '@/api/company'
import Toaster from '@/utils/toaster'
import { zones } from 'tzdata';
import { DateTime } from 'luxon'

export default {
  components: {
  },
  props: {
    vehicle: { type: Object, required: true },
    isBusy: { type: Boolean, default: false },
  },
  data: function () {
    return {
      isMounted: false,
      selectedSubscription: null,
      subscription: null,
      timeZones: [],
      systemOfUnits: [
        { value: 'metric', text: this.$t('systemOfUnits.metric') },
        { value: 'imperial', text: this.$t('systemOfUnits.imperial') },
        { value: 'usa', text: this.$t('systemOfUnits.usa') },
      ],
      onOff: [
        { value: true, text: this.$t('option.on') },
        { value: false, text: this.$t('option.off') },
      ],
      currencyCodes: [
        'GBP',
        'EUR',
        'CHF',
        'USD',
      ],
    }
  },
  computed: {
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
    editorTitle() {
      if (this.vehicle.id) {
        return this.$t('view.vehicles.editor.title.edit')
      } else {
        return this.$t('view.vehicles.editor.title.add')
      }
    },
    subscriptionItems() {
      let items = [
        { value: null, text: this.$t('view.vehicles.editor.subscription.none') }
      ]
      if (this.subscription) {
        let includeIt = this.subscription.usage < this.subscription.quantity
        if (this.vehicle.subscriptions?.length > 0 && this.subscription.id == this.vehicle.subscriptions[0].id) {
          includeIt = true
        }
        if (includeIt) {
          items = [
            ...items,
            {
              value: this.subscription.id,
              text: this.formatSubscription(this.subscription)
            }
          ]
        }
        // items = [
        //     ...items,
        //     ...this.subscriptions
        //         .filter(subscription => {
        //             return subscription.targetCount < subscription.totalQuantity
        //         })
        //         .map(subscription => {
        //             return {
        //                 value: subscription.id,
        //                 text: this.formatSubscription(subscription)
        //             }
        //         })
        // ]
      }
      return items
    },
  },
  methods: {
    onSaveClicked: function () {
      this.vehicle.subscriptions = this.selectedSubscription ? [this.selectedSubscription] : []
      this.$emit('save')
    },
    onDeleteClicked: function () {
      this.$emit('delete')
    },
    formatSubscription(subscription) {
      let text = subscription.subscriptionProduct.name
      text += ' (' + subscription.usage + " / " + subscription.quantity + ")"
      return text
    },
  },
  mounted: async function () {
    try {
      this.timeZones = Object.entries(zones)
        .filter(([zoneName, v]) => Array.isArray(v))
        .map(([zoneName, v]) => zoneName)
        .filter(tz => DateTime.local().setZone(tz).isValid)
      try {
        this.subscription = await CompanyApi.getSubscription(this.company.id)
      } catch (error) {
        if (error.isAxiosError && error.response.status == 404) {
            this.subscription = null
        }
      }
      if (this.vehicle.subscriptions && this.vehicle.subscriptions.length > 0) {
        this.selectedSubscription = this.vehicle.subscriptions[0].id // maybe later there will be more licenses / verhicle
      } else {
        this.selectedSubscription = null
      }

    } catch (error) {
      Toaster.showError(this, error)
    }
    this.isMounted = true
  },
  watch: {
  },
}
</script>

<style scoped>

</style>
