<template>
    <div>
        <b-modal
          id="invitation-received-modal"
          size="md"
          :title="$t('view.invitation.dialog.title')"
          no-close-on-backdrop
          no-close-on-esc
          hide-header-close
          ok-only
          no-stacking
          centered>
            <template #default="">
              <b-container fluid class="p-0" v-if="invitation != null">
                <b-row><b-col>
                  {{$t('view.invitation.dialog.text', [invitation.companyName])}}
                </b-col></b-row>
            </b-container>
          </template>
          <template #modal-footer>
            <div class="d-flex w-100">
              <b-button variant="outline-danger" :disabled="isBusy" @click="onDeclineClicked()">{{$t('view.invitation.dialog.buttons.decline')}}</b-button>
              <b-button variant="success" class="ml-auto" :disabled="isBusy" @click="onAcceptClicked()">{{$t('view.invitation.dialog.buttons.accept')}}</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    invitation: { type: Object, required: false },
    isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
      isMounted: false,
      isLoading: false,
      periodMode: null,

      dateStart: null,
      dateEnd: null,
      dataFields: [],
      
      // selectedTags: [],
      companyTags: [],
      templateTags: [],
      queryTag: null,

      vehicleOptions: [],
      vehicleIds: [],
      item: {
        value: '',
        text: ''
      },
    }
  },
  computed: {
  },
  methods: {
    onAcceptClicked: async function() {
      this.$emit('accepted', this.invitation)
    },
    onDeclineClicked: async function() {
      this.$emit('declined', this.invitation)
    },
    onCreateReportClicked: function() {
      this.$emit('create-report', this.template)
    },
  },
  mounted: async function() {
    this.isMounted = true
  },
  watch: {
    invitation: function() {

    }
  },
}
</script>

<style>
.details-row {
    margin-bottom: 20px;
}

/* .label {
    font-size: 0.8em;
} */
</style>
