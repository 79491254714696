import Vue from 'vue'

export default {
    async getUserInfo() {
        let result = await Vue.axios.get('/api/users/me')
        let userInfo = {
            id: result.data.data.id,
            ...result.data.data.attributes
        }
        return userInfo
    },
    async updateUserInfo(objectInfo) {
        let result = await Vue.axios.patch('/api/users/me', {
            data: {
                type: 'user',
                attributes: objectInfo
            }
        })
        return result.data
    },
    async getCompanies() {
        let result = await Vue.axios.get('/api/users/me/companies')
        let companies = result.data.data.map(data => {
            return {
                id: data.id,
                ...data.attributes
            }
        })
        return companies
    },
    async getInvitations() {
        let result = await Vue.axios.get('/api/users/me/invitations')
        let companies = result.data.data.map(data => {
            return {
                id: data.id,
                ...data.attributes
            }
        })
        return companies
    },

    // SelectionLists

    // --------------------------------------
    //  Drivers
    // --------------------------------------
    async getSelectionListDrivers(parameters) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/drivers', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListDriver(driverId) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/drivers/' + driverId)
        return result.data
    },
    async addSelectionListDriver(attributes) {
        let result = await Vue.axios.post('/api/users/me/selectionlists/drivers', {
            data: {
                type: 'driver',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListDriver(driverId, attributes) {
        let result = await Vue.axios.patch('/api/users/me/selectionlists/drivers/' + driverId, {
            data: {
                type: 'driver',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListDriver(driverId) {
        let result = await Vue.axios.delete('/api/users/me/selectionlists/drivers/' + driverId)
        return result.data
    },


    // --------------------------------------
    //  Contacts
    // --------------------------------------
    async getSelectionListContacts(parameters) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/contacts', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListContact(contactId) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/contacts/' + contactId)
        return result.data
    },
    async addSelectionListContact(attributes) {
        let result = await Vue.axios.post('/api/users/me/selectionlists/contacts', {
            data: {
                type: 'contact',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListContact(contactId, attributes) {
        let result = await Vue.axios.patch('/api/users/me/selectionlists/contacts/' + contactId, {
            data: {
                type: 'contact',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListContact(contactId) {
        let result = await Vue.axios.delete('/api/users/me/selectionlists/contacts/' + contactId)
        return result.data
    },



    // --------------------------------------
    //  Reasons
    // --------------------------------------
    async getSelectionListReasons(parameters) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/reasons', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListReason(reasonId) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/reasons/' + reasonId)
        return result.data
    },

    async addSelectionListReason(attributes) {
        let result = await Vue.axios.post('/api/users/me/selectionlists/reasons', {
            data: {
                type: 'reason',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListReason(reasonId, attributes) {
        let result = await Vue.axios.patch('/api/users/me/selectionlists/reasons/' + reasonId, {
            data: {
                type: 'reason',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListReason(reasonId) {
        let result = await Vue.axios.delete('/api/users/me/selectionlists/reasons/' + reasonId)
        return result.data
    },



    // --------------------------------------
    //  Tags
    // --------------------------------------
    async getSelectionListTags(parameters) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/tags', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListTag(tagId) {
        let result = await Vue.axios.get('/api/users/me/selectionlists/tags/' + tagId)
        return result.data
    },

    async addSelectionListTag(attributes) {
        let result = await Vue.axios.post('/api/users/me/selectionlists/tags', {
            data: {
                type: 'tag',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListTag(tagId, attributes) {
        let result = await Vue.axios.patch('/api/users/me/selectionlists/tags/' + tagId, {
            data: {
                type: 'tag',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListTag(tagId) {
        let result = await Vue.axios.delete('/api/users/me/selectionlists/tags/' + tagId)
        return result.data
    },

}