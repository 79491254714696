<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.settings.general.title')}}</div>
        </div>
        <b-container fluid class="p-0">
            <b-row><b-col>
                <b-form-group :label="$t('view.settings.general.name')" label-class="label">
                    <b-form-input v-model="item.name" type="text" :disabled="isBusy || !canChangeCompany"/>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.settings.general.email')" label-class="label">
                    <b-form-input v-model="item.email" type="text" :disabled="true"/>
                </b-form-group>
            </b-col></b-row>
            <b-row class="actions" v-if="canChangeCompany"><b-col>
                <b-button variant="success" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
                <!-- <b-button variant="danger" class="ml-auto" v-if="!isNewItem" @click="onDeleteClicked()">Löschen</b-button> -->
            </b-col></b-row>
        </b-container>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import Toaster from '@/utils/toaster'
import PermissionUtils from "@/utils/permission-utils"
import DataUtils from '@/utils/data-utils'

export default {
    components: {
    },

    data: function () {
        return {
            itemId: undefined,
            item: {},
            isBusy: false,
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        canChangeCompany() {
            return PermissionUtils.isAuthorizedInCompany('company:change', this.company)
        },
    },

    watch: {
        async company() {
            await this.reloadData();
        }
    },

    methods: {
        async reloadData() {
            this.itemId = this.company.id
            this.item = {
                name: this.company.name,
                email: this.company.email,
            }
        },

        onSaveClicked: async function() {
            try {
                this.isBusy = true
                DataUtils.setClientTimestamps(this.item)

                await CompanyApi.updateCompany(this.itemId, this.item)
                this.$store.dispatch('User/updateCompanies')
                Toaster.showSuccess(this, {
                    title: 'Hinweis',
                    message: `Ihre Änderungen wurden gespeichert`
                })
                this.isBusy = false
            } catch(error) {
                this.isBusy = false
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
        }
    },

    mounted: async function() {
        await this.reloadData()
    },
};
</script>

<style scoped>

</style>