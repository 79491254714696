<template>
<div>
  <div class="flex-fill" style="overflow-y: scroll;">
    <div id="company">
      <div class="d-flex">
        <div class="flex-fill">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "Company",
  components: {
  },
  computed: {
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
  },
  watch: {
  },

  methods: {
  },
  mounted: function () {
  }
}
</script>

<style>
</style>
