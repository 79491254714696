<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="title">
            Willkommen bei Driverslog Pro Connect!
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="section">Firmenfahrzeug nutzen</div>
          <div class="section-text">
            Wenn Sie Fahrzeuge nutzen möchten, die von einer bestehenden Firma verwaltet werden, müssen Sie von dieser
            Firma eingeladen werden. Sie brauchen dann keine eigene Firma anzulegen.
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="section">Eigene Firma anlegen</div>
          <div class="section-text">
            Um eigene Fahrzeuge verwalten zu können, können Sie über die nachfolgende Taste eine eigene Firma anlegen.
          </div>
          <b-button class="add-company-button" variant="outline-primary" @click="onCreateCompanyClicked()">Neue Firma anlegen</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "SetupScreen",
  components: {
  },
  computed: {
  },
  watch: {
  },

  methods: {
    onCreateCompanyClicked() {
      this.$router.push({name: 'CreateCompany'})
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
.title {
  font-size: 2rem;
}

.section {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.section-text {
  max-width: 600px;
}

.add-company-button {
  margin-top: 10px;
}
</style>
