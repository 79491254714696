<template>
    <div>
      <b-modal id="modal-vehicle-deletion-confirmation" :title="title" ok-only no-stacking centered>
        <template #default="">
          <b-container v-if="isMounted && !isBusy" fluid class="p-0">
            <b-row class="details-row">
              <b-col>
                <div>{{ $t('view.vehicles.deletionConfirmation.text')}}</div>
                <div class="licensePlate">{{vehicle.licensePlate}}</div>
              </b-col>
            </b-row>
            <b-row class="details-row">
              <b-col>
                <b-form-input type="text" v-model="confirmationLicensePlate"></b-form-input>
              </b-col>
            </b-row>
          </b-container>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button variant="outline-danger" :disabled="confirmationLicensePlate != vehicle.licensePlate" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
          <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      vehicle: { type: Object, required: true },
    },
    components: {
    },
    data: function () {
      return {
        isMounted: false,
        isBusy: false,
        isLoading: false,
        confirmationLicensePlate: '',
      }
    },
    computed: {
      title() {
        return this.$t('view.vehicles.deletionConfirmation.title')
      },
    },
    methods: {
      onDeleteClicked() {
        this.$emit('delete-clicked', this.vehicle)
      },
    },
    mounted: async function () {
      this.isBusy = true
      this.isBusy = false
      this.isMounted = true;
    },
  }
  </script>
  
  <style scoped>
  .details-row {
    margin: 10px 0px;
  }

  .licensePlate {
    text-align: center;
    padding: 10px;
    font-family: monospace, monospace;
  }
  </style>
  