<template>
    <div>
        <div class="section d-flex">
            <div class="mt">
                <span>Benutzer ändern</span>
            </div>
        </div>
        <b-container fluid class="p-0">
            <!-- <b-row><b-col class="label">Vorname</b-col></b-row>
            <b-row><b-col><b-form-input v-model="item.firstName" type="text"></b-form-input></b-col></b-row>
            <b-row><b-col class="label">Nachname</b-col></b-row>
            <b-row><b-col><b-form-input v-model="item.lastName" type="text"></b-form-input></b-col></b-row> -->
            <b-row class="actions"><b-col>
                <b-button @click="onSaveClicked()">Speichern</b-button>
                <b-button variant="danger" class="ml-auto" @click="onDeleteClicked()">Löschen</b-button>
            </b-col></b-row>
        </b-container>
    </div>
</template>

<script>
import VehicleApi from '@/api/vehicle'

export default {
    components: {
    },

    data: function () {
        return {
            isLoading: false,
            item: {},
        };
    },

    computed: {
        vehicleId() {
            return this.$route.params.vehicleId
        },
        userId() {
            return this.$route.params.userId
        },
    },

    methods: {
        async onSaveClicked() {
            // var result = null
            // if (this.isNewItem) {
            //     result = await CompanyApi.addSelectionListDriver(
            //         this.$store.state.company.id,
            //         this.item.firstName,
            //         this.item.lastName,
            //     )
            // } else {
            //     result = await CompanyApi.updateSelectionListDriver(
            //         this.$store.state.company.id,
            //         this.item.id,
            //         this.item.firstName,
            //         this.item.lastName,
            //     )
            // }
            // this.$router.push({ name: 'SelectionListDrivers' })
        },
        async onDeleteClicked() {
            const result = await VehicleApi.setUser(
                this.vehicleId,
                this.userId,
                [],
            )
            this.$router.push({
                name: 'VehicleUsers',
                params: {vehicleId: this.vehicleId}
            })
        }
    },

    mounted: async function() {
        // if (!this.isNewItem) {
        //     const result = await CompanyApi.getSelectionListDriver(
        //         this.$store.state.company.id,
        //         this.itemId,
        //     )
        //     if (result) {
        //         this.item = {
        //             id: result.data.id,
        //             ...result.data.attributes
        //         }
        //     }
        // }
    },
};
</script>

<style scoped>

</style>