<template>
    <div>
        <b-modal id="modal-editor" size="lg" :title="editorTitle" ok-only no-stacking centered>
            <template #default="">
              <b-container fluid class="p-0">
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('view.contacts.editor.firstName')" label-class="label">
                      <b-form-input v-model="contact.firstName" type="text" :disabled="isBusy"/>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('view.contacts.editor.lastName')" label-class="label">
                      <b-form-input v-model="contact.lastName" type="text" :disabled="isBusy"/>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row><b-col>
                    <b-form-group :label="$t('view.contacts.editor.company')" label-class="label">
                      <b-form-input v-model="contact.company" type="text" :disabled="isBusy"/>
                    </b-form-group>
                </b-col></b-row>
                <b-row><b-col>
                    <b-form-group :label="$t('view.contacts.editor.address')" label-class="label">
                      <vue-typeahead-bootstrap
                          :data="suggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="addressSearchString"
                          @hit="onAddressSelected($event)"
                      />
                    </b-form-group>
                </b-col></b-row>
                  <b-row><b-col>
                    <TripMap ref="map"
                      :mapOptions="mapOptions"
                      :markers="markers"
                      class="p-0"
                    />
                  </b-col></b-row>
              </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="contact.id" :disabled="isBusy" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

import TripMap from '@/components/TripMap'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import DisplayUtils from '@/utils/display-utils'
import _ from "underscore";

export default {
  components: {
      TripMap,
      VueTypeaheadBootstrap
  },
  props: {
      contact: { type: Object, required: true },
      isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
      mapOptions: {
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: true,
      },
      markers: [],
      suggestions: [],
      addressSearchString: '',
      selectedAddressString: '',
    }
  },
  computed: {
    editorTitle() {
        if (this.contact.id) {
            return this.$t('view.contacts.editor.title.edit')
        } else {
            return this.$t('view.contacts.editor.title.add')
        }
    },
  },
  methods: {
    onSaveClicked: function() {
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete')
    },
    async getSuggestions(queryString) {
      let request = {
        input: queryString
      }

      this.$refs.map.autocompleteService.getPlacePredictions(
        request,
        (results, status) => {
          if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
            this.suggestions = results;
          }
        }
      )
    },

    updateMap: function() {
      let markers = []
      let mapOptions = {
        ...this.mapOptions,
        center: {
          lat: 48.45342,
          lng: 9.09484,
        },
        zoom: 3,
      }
      if (this.contact.place) {
        if (this.contact.place.coordinate) {
          mapOptions = {
            ...this.mapOptions,
            center: {
              lat: this.contact.place.coordinate.latitude,
              lng: this.contact.place.coordinate.longitude,
            },
            zoom: 18
          }
          markers = [{
            place: this.contact.place,
            lat: this.contact.place.coordinate.latitude,
            lng: this.contact.place.coordinate.longitude,
          }]
        }
      }
      this.mapOptions = mapOptions
      this.markers = markers
    },

    async onAddressSelected(address) {
      this.selectedAddressString = address.description
      const request = {
        placeId: address.place_id,
      };

      this.$refs.map.placesService.getDetails(request, (result, status) => {
        if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
          let details = {};
          result.address_components.forEach((component) => {
            component.types.forEach((type) => {
              details[type] = component.long_name;
              details[type + "short"] = component.short_name;
            });
          });

          // compile dlp address
          let address = {};
          let streetArray = [];
          if (details.route) {
            streetArray.push(details.route);
          }
          if (details.street_number) {
            streetArray.push(details.street_number);
          }
          address.street = streetArray.join(" ");
          address.postalCode = details.postal_code;
          address.city = details.locality;
          address.country = details.country;

          let place = {};
          place.address = address;
          if (result.geometry && result.geometry.location) {
            place.coordinate = {
              latitude: result.geometry.location.lat(),
              longitude: result.geometry.location.lng(),
            };
          }

          this.contact.place = place
          this.updateMap()
        }
      })
    },
  },
  mounted: async function() {
    this.updateMap()
  },
  watch: {
    addressSearchString: _.debounce(function(queryString) {
      if (queryString == this.selectedAddressString) {
        return
      }
      this.contact.place = {
        name: queryString,
        coordinate: null,
      }
      this.getSuggestions(queryString)
    }, 500),
    contact: function() {
      if (this.contact.place) {
        this.selectedAddressString = DisplayUtils.stringFromPlace(this.contact.place)
      } else {
        this.selectedAddressString = ''
      }
      this.addressSearchString = this.selectedAddressString
      this.updateMap()
    }
  },

}
</script>

<style scoped>
.label {
    font-size: 0.8em;
}
</style>
