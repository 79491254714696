<template>
    <div>
        <b-modal id="modal-vehicle-user-editor" size="lg" :title="$t('view.vehicle.users.editor.title')" ok-only no-stacking centered>
            <template #default="">
            <b-container fluid class="p-0">
              <b-row class="details-row">
                  <b-col>
                      <div class="label">{{$t('view.vehicle.users.editor.firstName')}}</div>
                      <div>{{user.firstName}}</div>
                  </b-col>
              </b-row>
              <b-row class="details-row">
                  <b-col>
                      <div class="label">{{$t('view.vehicle.users.editor.lastName')}}</div>
                      <div>{{user.lastName}}</div>
                  </b-col>
              </b-row>
              <b-row class="details-row">
                  <b-col>
                      <div class="label">{{$t('view.vehicle.users.editor.email')}}</div>
                      <div>{{user.email}}</div>
                  </b-col>
              </b-row>
            </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="user.id" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
              <b-button variant="outline-secondary" class="ml-auto"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <!-- <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button> -->
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>


export default {
  components: {
  },
  props: {
      user: { type: Object, required: true },
  },
  data: function() {
    return {
    }
  },
  computed: {
  },
  methods: {
    onSaveClicked: function() {
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete', this.user)
    },
  },
  mounted: async function() {
  },
  watch: {
  },

}
</script>

<style scoped>
.details-row {
    margin-bottom: 20px;
}

/* .label {
    font-size: 0.8em;
} */
</style>
