<template>
  <div class="layout_default">
    
    <!-- Header Start -->
    <div class="d-flex align-items-center header">
      <!-- title -->
      <slot name="header_large"/>

      <!-- categories large screen -->
      <div v-if="categories.length > 0" class="categories d-none d-lg-flex ml-4">
              <router-link
          :to="{ name: category.target }"
          class="category"
          replace
          :class="{ selected: selectedCategory.id === category.id }"
          v-for="category in categories"
          :key="category.id"
        >
          {{ category.label }}
        </router-link>
<!-- 
        <div class="category" :class="{'selected': selectedCategory.id === category.id}" v-for="category in categories" :key="category.id" @click="onCategoryClicked(category)">
          <div>{{category.label}}</div>
        </div> -->
      </div>

      <!-- categories small screen -->
      <div class="header ml-3 d-lg-none">
        <div v-if="categories.length > 0">
          <b-dropdown variant="link" size="lg" :text="selectedCategory.label">
            <b-dropdown-group>
                <b-dropdown-item v-for="category in categories" :key="category.id" @click="onCategoryClicked(category)">{{category.label}}</b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </div>
      </div>  
    </div>
    <!-- Header End -->
    
    <main>
      <slot/>
    </main>

    <footer>
      &copy; Driverslog Pro GmbH
    </footer>
  </div>
</template>
<script>

export default ({
  props: {
    categories: {
      type: Array,
      default: () => []
    },
  },

  methods: {
    onCategoryClicked: function(category) {
      const isCurrentRoute = this.$route.matched.some(match => match.name == category.target)
        if (!isCurrentRoute) {
          this.$router.push({ name: category.target})
        }
    },
    // onVehicleClicked: function(vehicle) {
    //     this.$store.dispatch('setVehicle', vehicle)
    // },
  },

  data: () => {
    return {
    }
  },

  computed: {
    // vehicles() {
    //   return this.$store.state.vehicles
    // },
    // vehicle() {
    //     return this.$store.state.vehicle
    // },
    selectedCategory() {
      return this.categories.find(category => {
        return this.$route.matched.some(match => match.meta.category == category.id)
      })
    }
  },

  watch: {
  //   isVehiclePickerNeeded: {
  //   // the callback will be called immediately after the start of the observation
  //   immediate: true, 
  //   handler (val, oldVal) {
  //     this.$store.dispatch('setIsVehiclePickerNeeded', val)
  //     // do your stuff
  //   }
  // }
  },
})
</script>


<style scoped>
.layout_default {
}

.header {
  /* border-bottom: solid 1px lightgray; */
}

.categories {
  color: black;
  /* margin-top: 15px; */
  /* border-bottom: solid 1px black; */
}

.category {
  /* width: 100px; */  
  text-align: center;
  margin: 8px 20px;
  color: black;
  text-decoration: none;
  /* border-right: solid 1px black; */
}

.category:hover {
  border-bottom: 2px solid blue;
}

.selected {
  /* color: white; */
  font-weight: bold;
  border-bottom: 2px solid blue;
  /* background-color: #666; */
}

</style>