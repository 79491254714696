<template>
    <div v-if="isMounted">
        <div class="section d-flex">
            <div class="mt">{{$t('view.selectionlists.reasons.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3"><b-button variant="link" @click="onAddReasonClicked()">{{$t('view.selectionlists.reasons.addReason')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table hover select-mode="single" :items="items" :fields="fields" @row-clicked="onRowClicked" >
                        <template #cell(label)="data">
                            {{ data.item.label }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <ReasonEditor :reason="selectedItem" :isBusy="isBusy" @save="onSaveReason" @delete="onDeleteReason"/>
    </div>
</template>

<script>
import UserApi from '@/api/user'
import Toaster from '@/utils/toaster'
import ReasonEditor from "@/components/selectionlist/ReasonEditor.vue";
import DataUtils from '@/utils/data-utils'


export default {
    components: {
        ReasonEditor
    },

    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            selectedItem: {},
            fields: [
                { key: 'label', label: this.$t('view.selectionlists.reasons.label')},
            ],

        };
    },

    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        async onSaveReason() {
            this.isBusy = true
            try {
                let attributes = { ...this.selectedItem }
                DataUtils.setClientTimestamps(attributes)
                // const attributes = {
                //     label: this.selectedItem.label,
                // }
                if (this.selectedItem.id) {
                    await UserApi.updateSelectionListReason(
                        this.selectedItem.id,
                        attributes
                    )
                } else {
                    await UserApi.addSelectionListReason(
                        attributes
                    )
                }
                await this.reloadItems()
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        async onDeleteReason() {
            this.isBusy = true
            try {
                await UserApi.deleteSelectionListReason(
                    this.selectedItem.id,
                )
                this.items = this.items.filter(item => item.id !== this.selectedItem.id)
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        onAddReasonClicked() {
            this.selectedItem = {}
            this.$bvModal.show('modal-editor')
            // this.$router.push({ name: 'ReasonAdd' })
        },
        onRowClicked(item, index) {
            this.selectedItem = {
                ...this.items[index]
            }
            this.$bvModal.show('modal-editor')

            // this.selectedItem = this.items[index]
            // this.$router.push({
            //     name: 'ReasonEdit',
            //     params: {reasonId: this.selectedItem.id}
            // })
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.totalItems == -1 || this.items.length < this.totalItems) {
                this.isBusy = true;
                this.isLoading = true;
                try {
                    // await new Promise(r => setTimeout(r, 2000));
                    let offset = this.items.length
                    const result = await UserApi.getSelectionListReasons({
                        "page[limit]": this.limit,
                        "page[offset]": offset
                    })

                    if (result.data) {
                        result.data.forEach(item => {
                            this.items.push({
                                id: item.id,
                                ...item.attributes
                            })
                        })
                    }
                    var total = this.items.length
                    if (result.meta) {
                        if (result.meta.page) {
                            total = result.meta.page.total
                        }
                    }
                    this.totalItems = total
                    if (result.data.length > 0) {
                        setTimeout(() => {
                            this.checkAutoappendItems()
                        }, 20)
                    }
                } catch(error) {
                    Toaster.showError(this, error)
                }
                this.isLoading = false;
                this.isBusy = false;
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        }
    },

    mounted: async function() {
        this.isBusy = true
        try {
            await this.reloadItems();
        } catch (error) {
            Toaster.showError(this, error)
        }
        this.isBusy = false
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>
