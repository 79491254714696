<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.reportTemplates.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3" v-if="canAddReportTemplate"><b-button variant="link" @click="onAddTemplateClicked()">{{$t('view.reportTemplates.addTemplate')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table v-if="canListReportTemplates" striped hover selectable select-mode="single" :items="getItems" :fields="fields" @row-clicked="onRowClicked" >
                        <template #cell(label)="data">
                            {{ data.item.label }}
                        </template>
                        <template #cell(format)="data">
                            {{ $t('reportTemplate.formats.'+data.item.format) }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner>Einträge werden geladen...</div>
                    </div>
                <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <ReportTemplateEditor 
            :template="selectedItem"
            :isBusy="isBusy"
            @save="onSaveTemplate"
            @delete="onDeleteTemplate"
            @create-report="onCreateReport"
        />
    </div>
</template>

<script>
import ReportApi from '@/api/report'
import ReportTemplateApi from '@/api/report-template'
import Toaster from '@/utils/toaster'
import ReportTemplateEditor from "@/components/ReportTemplateEditor.vue";
import PermissionUtils from "@/utils/permission-utils"
import DataUtils from '@/utils/data-utils'

export default {
    name: "ReportTemplates",
    components: {
        ReportTemplateEditor,
    },

    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            selectedItem: {},
            fields: [
                { key: 'label', label: this.$t('view.reportTemplates.label') },
                { key: 'format', label: this.$t('view.reportTemplates.format') },
            ]
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        getItems() {
            return this.items.map((item) => {
                return {
                    label: item.label,
                    format: item.format,
                }
            });
        },
        canListReportTemplates() {
            return PermissionUtils.isAuthorizedInCompany('company:reportTemplates:list', this.company)
        },
        canAddReportTemplate() {
            return PermissionUtils.isAuthorizedInCompany('company:reportTemplates:add', this.company)
        },

    },

    watch: {
        async company() {
            await this.reloadItems();
        }
    },

    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddTemplateClicked() {
            this.selectedItem = {
                format: 'pdf',
                locale: 'de',
                filter: {
                    relativeDateIntervalUnit: 'year',
                    relativeDateIntervalOffset: 0,
                },
                tripsCoverage: 'itemsAndSummary',
                expensesCoverage: 'itemsAndSummary',
                isReportTagsEnabled: true,
                isTripsReportOdometerEnabled: true,
                isTripsReportGapsEnabled: true,
                isTripsReportChangesEnabled: true,
                isTripsReportCreationTimestampEnabled: true,
                isTripsCombinePrivateTripsEnabled: false,
                isTripsAnonymousPrivateTripsEnabled: true,
            }
            this.$bvModal.show('modal-editor')
        },
        async onCreateReport() {
            this.isBusy = true
            Toaster.showSuccess(this, {message: "Creating report."})
            try {
                let companyId = this.company.id
                DataUtils.setClientTimestamps(this.selectedItem)
                
                let createResult = await ReportApi.createReports(
                    companyId,
                    this.selectedItem
                )

                let blob = new Blob([createResult.data], { type: createResult.contentType })
                let link = document.createElement('a')
                let fileName = createResult.filename

                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            } catch(error) {
                if (error.isAxiosError && error.response.status === 409) {
                    Toaster.showErrorMessage(this, 'Eine Einladung mit dieser E-Mail-Adresse existiert bereits.', {
                        title: `Einladung fehlgeschlagen`
                    })
                } else {
                    Toaster.showError(this, error, {
                        title: `Einladung fehlgeschlagen`
                    })
                }
            }
            this.isBusy = false
        },
        async onSaveTemplate() {
            this.isBusy = true
            try {
                DataUtils.setClientTimestamps(this.selectedItem)
                if (this.selectedItem.id) {
                    await ReportTemplateApi.updateReportTemplate(
                        this.selectedItem.id,
                        this.selectedItem
                    )
                } else {
                    await ReportTemplateApi.addReportTemplate(
                        this.company.id,
                        this.selectedItem
                    )
                }
                await this.reloadItems()
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                if (error.isAxiosError && error.response.status === 409) {
                    Toaster.showErrorMessage(this, 'Eine Einladung mit dieser E-Mail-Adresse existiert bereits.', {
                        title: `Einladung fehlgeschlagen`
                    })
                } else {
                    Toaster.showError(this, error, {
                        title: `Einladung fehlgeschlagen`
                    })
                }
            }
            this.isBusy = false
        },
        async onDeleteTemplate() {
            this.isBusy = true
            try {
                await ReportTemplateApi.deleteReportTemplate(
                    this.selectedItem.id,
                )
                this.items = this.items.filter(item => item.id !== this.selectedItem.id)
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        onRowClicked(item, index) {
            this.selectedItem = {
                ...this.items[index]
            }
            if (this.selectedItem.filter === null) {
                this.selectedItem.filter = {}
            }
            delete this.selectedItem.companyId
            this.$bvModal.show('modal-editor')
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (!this.canListReportTemplates) {
                return
            }
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                const result = await ReportTemplateApi.getReportTemplates(this.company.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset
                })
                if (result.data) {
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        }
    },

    mounted: async function() {
        this.reloadItems();
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>
