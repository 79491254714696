<template>
  <div id="app" style="height: 100vh;">
  <!-- <div id="app" style="height: 100%"> -->
    <Navbar class="topbar"/>
    <!-- <div class="d-flex" style="height: calc(100% - 64px);"> -->
      <router-view style="height: calc(100% - 64px);" />
      <!-- <div class="d-none d-md-block" v-if="isMenuAvailable">
        <Sidebar style="height: 100%;"/>
      </div>
      <div class="flex-fill px-md-4 px-2 py-3" style="overflow-y: scroll;">
        <router-view/>
      </div> -->
    <!-- </div> -->
    <!-- <b-navbar toggleable="md" type="dark" variant="dark">
      <b-navbar-brand href="#">brand</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
          <b-nav-item href="#">foo</b-nav-item>
          <b-nav-item href="#" disabled>bar</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar> -->

    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <InvitationReceivedDialog :invitation="invitation" :isBusy="isBusy" @accepted="onInvitationAccepted" @declined="onInvitationDeclined"/>
  </div>
</template>

<script>
import InvitationApi from './api/invitation'
import Toaster from '@/utils/toaster'
import Navbar from "./components/Navbar.vue";
// import Sidebar from "./components/sidebar/Sidebar.vue";
import InvitationReceivedDialog from "./components/InvitationReceivedDialog.vue";
import Config from '@/config.js'

export default {
  name: "app",
  components: {
    Navbar,
    // Sidebar,
    InvitationReceivedDialog
  },
  data: function() {
    return {
      isBusy: false,
      invitation: null
    }
  },
  computed: {
    // isMenuAvailable() {
    //   return this.$store.state.mainMenu != null
    // },
    invitations() {
      return this.$store.getters['User/invitations']
    }
  },
  methods: {
    onInvitationAccepted: async function() {
      if (!this.isBusy) {
        this.isBusy = true
        try {
          await InvitationApi.acceptInvitation(this.invitation.id)
          await this.$store.dispatch("setCompany", this.invitation.companyId);
          this.$bvModal.hide('invitation-received-modal')
          this.invitation = null
          this.$router.push({name: 'Company'})
        } catch (error) {
          if (error.isAxiosError && error.response.status == 404) {
            this.invitation = null
            this.$bvModal.hide('invitation-received-modal')
          } else {
            Toaster.showError(this, error, {
                title: `Einladung annehmen fehlgeschlagen`
            })
          }
        }
        await this.$store.dispatch('User/updateInvitations')
        await this.$store.dispatch('User/updateCompanies')
        this.isBusy = false
      }
    },
    onInvitationDeclined: async function() {
        this.isBusy = true
        try {
          await InvitationApi.deleteInvitation(this.invitation.id)
          this.$bvModal.hide('invitation-received-modal')
        } catch (error) {
          if (error.isAxiosError && error.response.status == 404) {
            this.invitation = null
            this.$bvModal.hide('invitation-received-modal')
          } else {
            Toaster.showError(this, error, {
                title: `Einladung ablehnen fehlgeschlagen`
            })
          }
        }
        await this.$store.dispatch('User/updateInvitations')
        this.isBusy = false
    }
  },
  mounted: function() {
    if (this.invitations && this.invitations.length > 0) {
      if (!this.invitation) {
        this.invitation = this.invitations[0]
        this.$bvModal.show('invitation-received-modal')
      }
    }
    setInterval(function () {
      this.$store.dispatch('User/updateInvitations')
    }.bind(this), 60000);
  },
  watch: {
    invitations() {
      if (this.invitations && this.invitations.length > 0) {
        if (!this.invitation) {
          this.invitation = this.invitations[0]
          this.$bvModal.show('invitation-received-modal')
        }
      } else {
        this.invitation = null
        this.$bvModal.hide('invitation-received-modal')
      }
    }
  }
}
</script>

<style>
@import './assets/base.css';
/* html {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: red;
}
body {
  flex:1;
  background: green;
} */

body {
  margin: 0;
  /* background-color: #202020; */
  background-color: #fff;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.label {
  /* font-size: 0.8em; */
  padding: 0px;
  font-weight: 500;
}

.actions {
    margin-top: 15px;
    margin-bottom: 20px;
}

</style>
