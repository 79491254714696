<template>
  <div id="map" ref="gmap">
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

const STROKE_COLOR = "#0031ff"
// const STROKE_COLOR = "#2631ff"

const loader = new Loader({
  apiKey: "AIzaSyCeWeZhN86arwOwRKDD66KcbSGLH7448Q8",
  version: "weekly",
  libraries: ["places"],
});

export default {

  props: {
    mapOptions: { type: Object, required: false },
    track: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    markers: {
      type: Array,
      required: false,
      default() {
        return [] 
      }
    },
  },

  data: function () {
    return {
      google: null,
      map: null,
      autocompleteService: null,
      placesService: null,
      mapMarkers: [],
      mapPath: null,
    };
  },

  methods: {
    zoomAll() {
      var bounds = new this.google.maps.LatLngBounds();
      this.mapMarkers.forEach(marker => {
        bounds.extend({lat: marker.position.lat(), lng: marker.position.lng()});
      })
      if (this.track?.points) {
        this.track.points.forEach(point => {
          bounds.extend(point);
        })
      }
      this.map.fitBounds(bounds, 15);
    },
    updateTrack() {
      // remove old path
      if (this.mapPath) {
        this.mapPath.setMap(null)
        this.mapPath = null
      }
      // add new path
      if (this.track?.points) {
        this.mapPath = new this.google.maps.Polyline({
          path: this.track.points,
          geodesic: true,
          // strokeColor: "#5f6da4",
          strokeColor: STROKE_COLOR,
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
        this.mapPath.setMap(this.map);
      } else {
        this.addDashedLine()
      }
      this.$nextTick(() => {
        this.zoomAll()
      })
    },
    addDashedLine() {
      // remove old path
      if (this.mapPath) {
        this.mapPath.setMap(null)
        this.mapPath = null
      }
      const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        strokeColor: STROKE_COLOR,
        strokeWeight: 5,
        // scale: 4,
      };
      this.mapPath = new this.google.maps.Polyline({
        path: this.mapMarkers.map(marker => {
          return {
            lat: marker.position.lat(),
            lng: marker.position.lng(),
          }
        }),
        strokeOpacity: 0,
        strokeColor: STROKE_COLOR,
        strokeWeight: 5,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        map: this.map,
      });
    },
    updateMarkers() {
      // remove old markers
      this.mapMarkers.forEach(marker => {
        marker.setMap(null)
      })

      // add new markers
      this.mapMarkers = this.markers.map(markerData => {
        return new this.google.maps.Marker({
          map: this.map,
          position: markerData,
          // label: "HUHU"
        })
      })

      if (this.track == null) {
        this.addDashedLine()
      }

      this.$nextTick(() => {
        this.zoomAll()
      })
    }
  },

  updated() {
  },

  mounted: async function () {
    this.google = await loader.load();
    this.map = new this.google.maps.Map(
      document.getElementById("map"),
      {
        ...this.mapOptions,
        maxZoom: 17,
      }
    );
    this.placesService = new this.google.maps.places.PlacesService(this.map);
    this.autocompleteService = new this.google.maps.places.AutocompleteService();
  },

  watch: {
    markers: function() {
      this.updateMarkers()
    },
    track: function() {
      this.updateTrack()
    },
    mapOptions: function() {
      this.map.setOptions(this.mapOptions)
    }
  }
};
</script>

<style scoped>
</style>
