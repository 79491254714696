import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import auth from './modules/auth'

import CompanyApi from '../api/company'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    company: null,
    vehicle: null,
    vehicles: [],
    isVehiclePickerNeeded: false,
    mainMenu: null,
    isSidebarOpen: false,
  },

  getters: {
    currentCompany: state => state.company,
    currentVehicle: state => state.vehicle,
    // mainMenu: state => {
    //   console.log(state.company)
    //   let menu = []
    //   if (state.company && state.company.scopes) {
    //   if (state.company.scopes.includes('vehicle:list')) {
    //     menu.push({ label: 'Fahrzeuge', path: '/company/vehicles' })
    //   }
    //   menu.push({ label: 'Berichte', path: '/company/reports' }),
    //   menu.push({ label: 'Auswahllisten', path: '/company/selectionlists'})
    //   if (state.company.scopes.includes('company:users:list')) {
    //     menu.push({ label: 'Mitarbeiter', path: '/company/employees' })
    //   }
    //   menu.push({ label: 'Allgemein', path: '/company/settings' })
    //   menu.push({ label: 'Abonnements', path: '/company/subscriptions' })
    // }
    //   return menu
    // }
  },

  mutations: {
    initialiseStore(state) {
      if (localStorage.companyId) {
        state.companyId = localStorage.companyId;
      }
    },
    setMainMenu(state, menu) {
      state.mainMenu = menu
    },
    updateVehicles(state, vehicles) {
      state.vehicles = vehicles
      let current = vehicles.find(v => v.id === state.vehicle?.id)
      if (current) {
        state.vehicle = current
      }
    },
    setIsVehiclePickerNeeded(state, isNeeded) {
      state.isVehiclePickerNeeded = isNeeded
    },
    setCompany(state, company) {
      state.company = company
      localStorage.companyId = company?.id
    },
    setVehicle(state, vehicle) {
      state.vehicle = vehicle
    }
  },

  actions: {
    setMainMenu({commit}, menu) {
      commit('setMainMenu', menu)
    },

    async refreshVehicles({commit, state}) {
      if (state.company != null) {
        const result = await CompanyApi.getVehicles(state.company.id)
        commit('updateVehicles', result.data.map(result => {
          return {
              id: result.id,
              ...result.attributes
          }
      }))
      }
    },

    async setCompany({ commit, dispatch, state }, companyId) {
      if (!companyId) {
        commit('setCompany', null)
        commit('setVehicle', null)
        return
      }
      const companyResult = await CompanyApi.getCompany(companyId)
      commit('setCompany', {
        id: companyResult.data.id,
        ...companyResult.data.attributes
      })
      await dispatch('refreshVehicles')
      const vehicles = state.vehicles
      if (vehicles.length > 0) {
        commit('setVehicle', vehicles[0])
      } else {
        commit('setVehicle', null)
      }
    },

    setVehicle({ commit }, vehicle) {
      commit('setVehicle', vehicle)
    },
    
    setIsVehiclePickerNeeded({ commit }, isNeeded) {
      commit('setIsVehiclePickerNeeded', isNeeded)
    },
  },

  modules: {
    User: user,
    Auth: auth
  }
})
