import Vue from 'vue'

export default {
    async getTrack(tripId, parameters) {
        let track = null
        try {
            let result = await Vue.axios.get('/api/trips/' + tripId + '/track', {
                params: parameters
            })
            track = {
                id: result.data.data.id,
                ...result.data.data.attributes
            }
        } catch (error) {
            //TODO: think of correct error handling
        }
        return track
    },

    async getTrip(tripId) {
        let result = await Vue.axios.get('/api/trips/' + tripId)
        return result.data
    },

    async updateTrip(tripId, attributes) {
        let result = await Vue.axios.patch('/api/trips/' + tripId, {
            data: {
                type: 'trip',
                attributes: attributes
            }
        })
        return result.data
    },

    async deleteTrip(tripId) {
        let result = await Vue.axios.delete('/api/trips/' + tripId)
        return result.data
    },

}