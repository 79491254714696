import Vue from 'vue'

export default {
    async getReportTemplates(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/report-templates', {
            params: parameters
        })
        return result.data
    },

    async getReportTemplate(reportTemplateId) {
        let result = await Vue.axios.get('/api/report-templates/' + reportTemplateId)
        return result
    },

    async addReportTemplate(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/report-templates/', {
            data: {
                type: 'report_template',
                attributes: attributes
            }
        })
        return result.data
    },

    async updateReportTemplate(reportTemplateId, attributes) {
        let result = await Vue.axios.patch('/api/report-templates/' + reportTemplateId, {
            data: {
                id: reportTemplateId,
                type: 'report_template',
                attributes: attributes
            }
        })
        return result.data
    },

    async deleteReportTemplate(reportTemplateId) {
        let result = await Vue.axios.delete('/api/report-templates/' + reportTemplateId)
        return result.data
    },
}