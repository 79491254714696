<template>
  <div>
    <b-modal id="quantity-modal" :title="editorTitle" ok-only no-stacking centered>
      <template #default="">
        <b-container v-if="isMounted && !isBusy" fluid class="p-0">
          <b-row class="details-row">
            <b-col>
              <div>{{ $t('view.subscriptionChangeQuantityModal.newQuantity')}}</div>
            </b-col>
            <b-col>
              <b-form-input v-debounce:500ms="onQuantityChanged" size="sm" type="number" v-model="quantity"
                :number="true" :min="minimumQuantity" :max="500"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="details-row">
            <b-col>
              <div>{{ $t('view.subscriptionChangeQuantityModal.minQuantity')}}</div>
            </b-col>
            <b-col>
              {{ minimumQuantity }}
            </b-col>
          </b-row>
          <b-row class="details-row">
            <b-col class="d-flex">
              <div class="property">{{ $t('view.subscriptionChangeQuantityModal.amount')}}</div>
            </b-col>
            <b-col class="d-flex">
              <div class="value" v-if="quantity == quoteQuantity">{{ formatAmount() }} <span class="vat-not-included"
                  v-if="quote.taxBehavior == 'exclusive'">({{$t('view.subscriptionChangeQuantityModal.taxNotIncluded')}})</span></div>
              <div class="value" v-else>{{ $t('view.subscriptionChangeQuantityModal.calculating') }}</div>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-success" :disabled="quantity != quoteQuantity" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
        <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import SubscriptionApi from '@/api/subscription'
import DisplayUtils from "@/utils/display-utils"

export default {
  props: {
    subscription: { type: Object, required: true },
  },
  components: {
  },
  data: function () {
    return {
      isMounted: false,
      isBusy: false,
      isLoading: false,
      quote: null,
      quoteQuantity: 0,
      quantity: 0,
    }
  },
  computed: {
    editorTitle() {
      return this.$t('view.subscriptionChangeQuantityModal.title')
    },
    minimumQuantity() {
      return Math.max(1, this.subscription.usage)
    }
  },
  methods: {
    formatAmount() {
      let amount = this.quote.amount / 100.0
      let currency = this.subscription.currencyCode
      if (amount === undefined || currency === undefined) {
        return "-"
      }
      return DisplayUtils.formatCurrency(amount, currency, 2)
    },
    async onQuantityChanged() {
      this.quote = await SubscriptionApi.getQuote(
        this.subscription.subscriptionPlan.id,
        this.quantity
      )
      this.quoteQuantity = this.quantity
    },
    onSaveClicked() {
      this.$emit('onUpdateQuantity', this.quoteQuantity)
    },
    formatPrice: function (plan) {
      // let gross = plan?.price?.gross
      let net = plan?.price?.net
      let currency = plan?.currency
      if (net === undefined || currency === undefined) {
        return "-"
      }
      return DisplayUtils.formatCurrency(net, currency, 2)
      // return this.$n(gross) + " " + currency
    },
  },
  mounted: async function () {
    this.isBusy = true
    this.quantity = this.subscription.quantity
    await this.onQuantityChanged()
    this.isBusy = false
    this.isMounted = true;
  },
}
</script>

<style scoped>
.details-row {
  margin: 10px 0px;
}
</style>
