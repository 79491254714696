const DateTime = require('luxon').DateTime

let self = {}

self.stringFromPlace = function(place, defaultString) {
    if (!place) {
        return defaultString || ''
    }
    // if (place.name) {
    //     return place.name
    // }
    let components = []
    if (place.name) {
        components.push(place.name)
    }
    // let result = place.name
    if (place.address) {
        const address = place.address
        // let components = []
        components.push(address.street)
        let cityComponents = []
        if (address.postalCode) {
            cityComponents.push(address.postalCode)
        }
        if (address.city) {
            cityComponents.push(address.city)
        }
        components.push(cityComponents.join(' '))
        // result = components.join(', ')
    }
    let result = components.join(', ')
    return result
}

self.formatRoles = function(appContext, roles, defaultString) {
    if (!roles) {
        return defaultString
    }
    return roles.map(role => {
        return appContext.$t('role.' + role)
    }).join(', ')
}

self.formatCurrency = function(price, currency, digits) {
    const browserLanguage = navigator.language || navigator.userLanguage
    return new Intl.NumberFormat(
        browserLanguage,
        {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: digits,
        }
    ).format(price)
}

self.formatDate = function(date, timeZone) {
    return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.DATETIME_SHORT)
}

self.formatDateOnly = function(date, timeZone) {
    if (date === null || date === undefined) {
        return '-'
    }
    return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.DATE_SHORT)
}


module.exports = self