<template>
    <div>
        <b-modal id="modal-editor" size="md" :title="$t('view.reportTemplates.editor.title')" ok-only no-stacking centered>
            <template #default="">
              <b-container fluid class="p-0">
                <!-- Label -->
                <b-row><b-col>
                    <b-form-group :label="$t('view.reportTemplates.editor.label')" label-class="label">
                        <b-form-input v-model="template.label" type="text" :disabled="isBusy"></b-form-input>
                    </b-form-group>
                </b-col></b-row>
                <!-- Format -->
                  <b-form-row>
                    <b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.format')" label-class="label">
                    <multi-select-box
                      v-model="template.format"
                      :options="formats"
                      :allow-empty="false">
                    </multi-select-box>
                  </b-form-group>
                </b-col>
                    <b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.locale')" label-class="label">
                    <multi-select-box
                      v-model="template.locale"
                      :options="locales"
                      :allow-empty="false">
                    </multi-select-box>
                  </b-form-group>
                </b-col>
                  </b-form-row>
                <!-- <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.format')" label-class="label">
                    <multi-select-box
                      v-model="template.format"
                      :options="formats"
                      :allow-empty="false">
                    </multi-select-box>
                  </b-form-group>
                </b-col></b-row> -->
                <!-- vehicles -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.vehicle')" label-class="label">
                    <multi-select-box
                      v-model="template.vehicleIds"
                      :options="vehicleOptions"
                      :close-on-select="false"
                      :allow-empty="true"
                      :multiple="true"
                      :searchable="true"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      />
                  </b-form-group>
                </b-col></b-row>
                <!-- Period -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.period')" label-class="label">
                    <multi-select-box
                      v-model="periodMode"
                      :options="periodOptions"
                      :allow-empty="true"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      />
                  </b-form-group>
                </b-col></b-row>
                <!-- Absolute periode -->
                <b-row v-if="periodMode === 'absolute'"><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.period')" label-class="label">
                    <b-input-group-append>
                      <b-input-group>
                        <b-form-input class="mr-2" size="sm" type="date" v-model="template.filter.absoluteDateIntervalStart" :disabled="false"></b-form-input>
                        <b-form-input class="mr-2" size="sm" type="date" v-model="template.filter.absoluteDateIntervalEnd" :disabled="false"></b-form-input>
                        </b-input-group>
                      </b-input-group-append>
                  </b-form-group>
                </b-col></b-row>
                <!-- Trips coverage -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.tripsCoverage')" label-class="label">
                    <multi-select-box
                      v-model="template.tripsCoverage"
                      :options="coverageOptions"
                      :allow-empty="false">
                    </multi-select-box>
                  </b-form-group>
                </b-col></b-row>
                <!-- Trip categrories -->
                <b-row v-if="template.tripsCoverage !== 'none'"><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.tripCategories')" label-class="label">
                    <multi-select-box
                      v-model="template.filter.tripCategory"
                      :options="tripTypeOptions"
                      :close-on-select="true"
                      :allow-empty="false"
                      :multiple="false"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      />
                  </b-form-group>
                </b-col></b-row>
                <!-- Driver -->
                <b-row v-if="template.tripsCoverage !== 'none'"><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.driver')" label-class="label">
                    <b-form-input v-model="template.filter.driverName" type="text" :disabled="isBusy" :placeholder="$t('view.reportTemplates.editor.options.all')"></b-form-input>
                  </b-form-group>
                </b-col></b-row>
                <!-- Expenses coverage -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.expensesCoverage')" label-class="label">
                    <multi-select-box
                      v-model="template.expensesCoverage"
                      :options="coverageOptions"
                      :allow-empty="false">
                    </multi-select-box>
                  </b-form-group>
                </b-col></b-row>
                <!-- Expense categories -->
                <b-row v-if="template.expensesCoverage !== 'none'"><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.expenseCategories')" label-class="label">
                    <multi-select-box
                      v-model="template.filter.expenseCategory"
                      :options="expenseCategoryOptions"
                      :close-on-select="true"
                      :allow-empty="false"
                      :multiple="false"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      />
                  </b-form-group>
                </b-col></b-row>
                <!-- Tags -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.tags')" label-class="label">
                    <multi-select-box
                      v-model="template.filter.tags"
                      :options="tagOptions"
                      :close-on-select="false"
                      :allow-empty="true"
                      :multiple="true"
                      :searchable="true"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      :internal-search="false"
                      @search-change="onTagSearchChanged"
                      />
                  </b-form-group>
                </b-col></b-row>
                <!-- Data fields -->
                <b-row><b-col>
                  <b-form-group :label="$t('view.reportTemplates.editor.fields')" label-class="label">
                    <multi-select-box
                      v-model="dataFields"
                      :options="optionalFieldsOptions"
                      :close-on-select="false"
                      :allow-empty="true"
                      :multiple="true"
                      :placeholder="$t('view.reportTemplates.editor.options.all')"
                      />
                  </b-form-group>
                </b-col></b-row>
            </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="template.id" :disabled="isBusy" @click="onDeleteClicked()">Löschen</b-button>
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">Abbrechen</b-button>
              <b-button variant="success" class="ml-2" :disabled="isBusy || !canSave" @click="onSaveClicked()">Speichern</b-button>
              <b-button variant="primary" class="ml-2" :disabled="isBusy" @click="onCreateReportClicked()">Bericht erstellen</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

import CompanyApi from '@/api/company'
import MultiSelectBox from '@/components/MultiSelectBox'
// alternative: vue-search-select

export default {
  components: {
    MultiSelectBox,
  },
  props: {
      template: { type: Object, required: true },
      // isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
      isMounted: false,
      isBusy: false,
      isLoading: false,
      periodMode: null,

      dateStart: null,
      dateEnd: null,
      dataFields: [],
      
      // selectedTags: [],
      companyTags: [],
      templateTags: [],
      queryTag: null,

      vehicleOptions: [],
      vehicleIds: [],
      item: {
        value: '',
        text: ''
      },
      formats: [
        { value: 'pdf', text: this.$t('reportTemplate.formats.pdf')},
        { value: 'csvMultiline', text: this.$t('reportTemplate.formats.csvMultiline')},
        { value: 'csvSingleline', text: this.$t('reportTemplate.formats.csvSingleline')},
      ],
      locales: [
        { value: 'de', text: this.$t('reportTemplate.locales.de')},
        { value: 'en', text: this.$t('reportTemplate.locales.en')},
      ],
      selected: [],
      tripCategories: [],
      expenseCategories: [],
      periodOptions: [
        { value: '', text: 'Alles' },
        { value: 'absolute', text: this.$t('dateInterval.absolute') },
        { value: 'currentWeek', text: this.$t('dateInterval.relative.currentWeek') },
        { value: 'currentMonth', text: this.$t('dateInterval.relative.currentMonth') },
        { value: 'currentQuarter', text: this.$t('dateInterval.relative.currentQuarter') },
        { value: 'currentYear', text: this.$t('dateInterval.relative.currentYear') },
        { value: 'lastWeek', text: this.$t('dateInterval.relative.lastWeek') },
        { value: 'lastMonth', text: this.$t('dateInterval.relative.lastMonth') },
        { value: 'lastQuarter', text: this.$t('dateInterval.relative.lastQuarter') },
        { value: 'lastYear', text: this.$t('dateInterval.relative.lastYear') },
        { value: 'penultimateWeek', text: this.$t('dateInterval.relative.penultimateWeek') },
        { value: 'penultimateMonth', text: this.$t('dateInterval.relative.penultimateMonth') },
        { value: 'penultimateQuarter', text: this.$t('dateInterval.relative.penultimateQuarter') },
        { value: 'penultimateYear', text: this.$t('dateInterval.relative.penultimateYear') },
      ],
      coverageOptions: [
        { value: 'none', text: this.$t('view.reportTemplates.editor.coverage.none') },
        { value: 'itemsAndSummary', text: this.$t('view.reportTemplates.editor.coverage.itemsAndSummary') },
        { value: 'summaryOnly', text: this.$t('view.reportTemplates.editor.coverage.summaryOnly') },
      ],
      tripTypeOptions: [
        { value: '', text: this.$t('view.reportTemplates.editor.option.all') },
        { value: 'business', text: this.$t('trip.categories.business') },
        { value: 'private', text: this.$t('trip.categories.private') },
        { value: 'commute', text: this.$t('trip.categories.commute') },
        { value: 'family', text: this.$t('trip.categories.family') },
        { value: 'honorary', text: this.$t('trip.categories.honorary') },
      ],
      expenseCategoryOptions: [
        { value: '', text: this.$t('view.reportTemplates.editor.option.all') },
        { value: 'fuel', text: this.$t('expense.categories.fuel') },
        { value: 'parking', text: this.$t('expense.categories.parking') },
        { value: 'garage', text: this.$t('expense.categories.garage') },
        { value: 'cleaning', text: this.$t('expense.categories.cleaning') },
        { value: 'tires', text: this.$t('expense.categories.tires') },
        { value: 'other', text: this.$t('expense.categories.other') },
      ],
      optionalFieldsOptions: [
        // { value: 'trips', text: this.$t('view.reportTemplates.editor.optionalFields.trips') },
        // { value: 'expenses', text: this.$t('view.reportTemplates.editor.optionalFields.expenses') },
        { value: 'odometer', text: this.$t('view.reportTemplates.editor.optionalFields.odometer') },
        { value: 'gaps', text: this.$t('view.reportTemplates.editor.optionalFields.gaps') },
        { value: 'changes', text: this.$t('view.reportTemplates.editor.optionalFields.changes') },
        { value: 'createdAt', text: this.$t('view.reportTemplates.editor.optionalFields.createdAt') },
        { value: 'tags', text: this.$t('view.reportTemplates.editor.optionalFields.tags') },
        { value: 'combinePrivateTrips', text: this.$t('view.reportTemplates.editor.optionalFields.combinePrivateTrips') },
        { value: 'anonymousPrivateTrips', text: this.$t('view.reportTemplates.editor.optionalFields.anonymousPrivateTrips') },
      ]
    }
  },
  computed: {
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
    canSave() {
      return this.template.label && this.template.label.length > 0
    },
    tagOptions() {
      let options = {}
      this.companyTags.forEach(tag => {
        options[tag.value] = tag.text
      })
      this.templateTags.forEach(tag => {
        options[tag.value] = tag.text
      })
      if (this.queryTag) {
        options[this.queryTag.value] = this.queryTag.text
      }
      let result = Object.keys(options).map(key => {
        return { value: key, text: options[key] }
      })
      return result
    }
  },
  methods: {
    onSaveClicked: function() {
      // this.applyDataFields()
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete', this.template)
    },
    onCreateReportClicked: function() {
      this.$emit('create-report', this.template)
    },
    onTagSearchChanged: function(query) {
      if (query.length == 0) {
        this.queryTag = null
        return
      }
      this.queryTag = { value: query, text: query }
    },
    applyDataFields: function() {
      // this.template.tripsCoverage = this.dataFields.includes('trips') ? 'itemsAndSummary' : 'none'
      // this.template.expensesCoverage = this.dataFields.includes('expenses') ? 'itemsAndSummary' : 'none'
      this.template.isReportTagsEnabled = this.dataFields.includes('tags')
      this.template.isTripsReportOdometerEnabled = this.dataFields.includes('odometer')
      this.template.isTripsReportGapsEnabled = this.dataFields.includes('gaps')
      this.template.isTripsReportChangesEnabled = this.dataFields.includes('changes')
      this.template.isTripsReportCreationTimestampEnabled = this.dataFields.includes('createdAt')
      this.template.isTripsAnonymousPrivateTripsEnabled = this.dataFields.includes('anonymousPrivateTrips')
      this.template.isTripsCombinePrivateTripsEnabled = this.dataFields.includes('combinePrivateTrips')
    },
    applyPeriod: function() {
      if (!this.template.filter) {
        this.template.filter = {}
      }
      switch(this.periodMode) {
        case 'currentWeek':
          this.template.filter.relativeDateIntervalUnit = 'week'
          this.template.filter.relativeDateIntervalOffset = 0
          break
        case 'currentMonth':
          this.template.filter.relativeDateIntervalUnit = 'month'
          this.template.filter.relativeDateIntervalOffset = 0
          break
        case 'currentQuarter':
          this.template.filter.relativeDateIntervalUnit = 'quarter'
          this.template.filter.relativeDateIntervalOffset = 0
          break
        case 'currentYear':
          this.template.filter.relativeDateIntervalUnit = 'year'
          this.template.filter.relativeDateIntervalOffset = 0
          break
        case 'lastWeek':
          this.template.filter.relativeDateIntervalUnit = 'week'
          this.template.filter.relativeDateIntervalOffset = -1
          break
        case 'lastMonth':
          this.template.filter.relativeDateIntervalUnit = 'month'
          this.template.filter.relativeDateIntervalOffset = -1
          break
        case 'lastQuarter':
          this.template.filter.relativeDateIntervalUnit = 'quarter'
          this.template.filter.relativeDateIntervalOffset = -1
          break
        case 'lastYear':
          this.template.filter.relativeDateIntervalUnit = 'year'
          this.template.filter.relativeDateIntervalOffset = -1
          break
        case 'penultimateWeek':
          this.template.filter.relativeDateIntervalUnit = 'week'
          this.template.filter.relativeDateIntervalOffset = -2
          break
        case 'penultimateMonth':
          this.template.filter.relativeDateIntervalUnit = 'month'
          this.template.filter.relativeDateIntervalOffset = -2
          break
        case 'penultimateQuarter':
          this.template.filter.relativeDateIntervalUnit = 'quarter'
          this.template.filter.relativeDateIntervalOffset = -2
          break
        case 'penultimateYear':
          this.template.filter.relativeDateIntervalUnit = 'year'
          this.template.filter.relativeDateIntervalOffset = -2
          break
        default:
          this.template.filter.relativeDateIntervalUnit = null
          this.template.filter.relativeDateIntervalOffset = 0
          break
      }
    }
  },
  mounted: async function() {
    this.isBusy = true;
    const result = await CompanyApi.getSelectionListTags(this.company.id, {
      // "page[limit]": this.limit,
      // "page[offset]": offset
    })
    if (result.data) {
        result.data.forEach(item => {
            this.companyTags.push({
                value: item.attributes.label,
                text: item.attributes.label,
            })
        })
    }
    const vehicleResult = await CompanyApi.getVehicles(this.company.id, {
      // "page[limit]": this.limit,
      // "page[offset]": offset
    })
    if (vehicleResult.data) {
        vehicleResult.data.forEach(item => {
            this.vehicleOptions.push({
                value: item.id,
                text: item.attributes.licensePlate,
            })
        })
    }
    this.isBusy = false
    this.isMounted = true
  },
  watch: {
    template() {
      if (this.template.filter.tags) {
        this.templateTags = this.template.filter.tags.map(tag => {
          return { value: tag, text: tag }
        })
      } else {
        this.templateTags = []
      }
      let dataFields = []
      // if (this.template.tripsCoverage !== 'none') {
      //   dataFields.push('trips')
      // }
      // if (this.template.expensesCoverage !== 'none') {
      //   dataFields.push('expenses')
      // }
      if (this.template.isReportTagsEnabled) {
        dataFields.push('tags')
      }
      if (this.template.isTripsReportOdometerEnabled) {
        dataFields.push('odometer')
      }
      if (this.template.isTripsReportGapsEnabled) {
        dataFields.push('gaps')
      }
      if (this.template.isTripsReportChangesEnabled) {
        dataFields.push('changes')
      }
      if (this.template.isTripsReportCreationTimestampEnabled) {
        dataFields.push('createdAt')
      }
      if (this.template.isTripsAnonymousPrivateTripsEnabled) {
        dataFields.push('anonymousPrivateTrips')
      }
      if (this.template.isTripsCombinePrivateTripsEnabled) {
        dataFields.push('combinePrivateTrips')
      }
      this.dataFields = dataFields

      let periodMode = ''
      if (this.template.filter.relativeDateIntervalUnit) {
        let offset = null
        let unit = null
        switch(this.template.filter.relativeDateIntervalOffset) {
          case 0: offset = 'current'; break;
          case -1: offset = 'last'; break;
          case -2: offset = 'penultimate'; break;
        }            
        switch(this.template.filter.relativeDateIntervalUnit) {
          case 'week': unit = 'Week'; break;
          case 'month': unit = 'Month'; break;
          case 'quarter': unit = 'Quarter'; break;
          case 'year': unit = 'Year'; break;
        }
        if (offset && unit) {
          periodMode = offset + unit
        }
      } else {
        if (this.template.filter.absoluteDateIntervalStart || this.template.filter.absoluteDateIntervalEnd) {
          periodMode = 'absolute'
        }
      }
      this.periodMode = periodMode

    },
    dataFields() {
      this.applyDataFields()
    },
    periodMode() {
      this.applyPeriod()
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.details-row {
    margin-bottom: 20px;
}

/* .label {
    font-size: 0.8em;
} */
</style>
