<template>
    <div>
        <b-navbar type="dark" variant="dark" class="navbar">
            <b-navbar-nav class="d-md-none icon" @click="onToggleSidebarClicked()">
                <span v-if="isSidebarOpen" class="material-symbols-outlined">close</span>
                <span v-if="!isSidebarOpen" class="material-symbols-outlined">menu</span>
            </b-navbar-nav>

            <b-navbar-brand href="#" class="ml-auto mr-auto ml-md-0">
                <img src="../assets/icon.png" class="icon d-inline-block align-middle" alt="Icon" />
                <span class="brand">Driverslog Pro Connect</span>
            </b-navbar-brand>

            <b-navbar-nav class=""><ProfileMenu /></b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import ProfileMenu from "./ProfileMenu.vue";

export default {
    name: "Navbar",
    components: {
        ProfileMenu,
        // SidebarMenu,
    },
    computed: {
        isSidebarOpen() {
            return this.$store.state.isSidebarOpen
        },
        component() {
            return this.$route.name
        },
        vehicles() {
            return this.$store.state.vehicles
        },
        vehicle() {
            return this.$store.state.vehicle
        },
    },
    methods: {
        onToggleSidebarClicked() {
            this.$store.state.isSidebarOpen = !this.$store.state.isSidebarOpen
        }
    },
    mounted() {
    }
};
</script>

<style scoped>
.navbar {
    height: 64px;
}

.account {
    color: #fff;
}

.brand {
    font-size: 0.8em;
}

.icon {
    width: 24x;
    height: 24px;
    margin-right: 5px;
    color: rgba(255,255,255,.5);
}

h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
