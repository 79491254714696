<template>
  <div>
    <b-modal id="cancel-modal" :title="editorTitle" ok-only no-stacking centered>
      <template #default="">
        <b-container v-if="isMounted && !isBusy" fluid class="p-0">
          <b-row>
            <b-col>
              <div>{{ $t('view.subscriptionCancelModal.text')}}</div>
              <div class="period-end">{{ $t('view.subscriptionCancelModal.periodEnd') }} {{ formatCurrentPeriodEnd() }}</div>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-danger" @click="onCancelSubscriptionClicked()">{{ $t('view.subscriptionCancelModal.cancelSubscriptionButton' )}}</b-button>
        <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import DisplayUtils from "@/utils/display-utils"

export default {
  props: {
    subscription: { type: Object, required: true },
  },
  components: {
  },
  data: function () {
    return {
      isMounted: false,
      isBusy: false,
      isLoading: false,
    }
  },
  computed: {
    editorTitle() {
      return this.$t('view.subscriptionCancelModal.title')
    },
    minimumQuantity() {
      return Math.max(1, this.subscription.usage)
    }
  },
  methods: {
    formatCurrentPeriodEnd() {
      return DisplayUtils.formatDateOnly(this.subscription.currentPeriodEnd)
    },
    onCancelSubscriptionClicked() {
      this.$emit('onCancelSubscription')
    },
  },
  mounted: async function () {
    this.isBusy = true
    this.isBusy = false
    this.isMounted = true;
  },
}
</script>

<style scoped>
.period-end {
  margin: 20px 0px;
  font-weight: 500;
}

</style>
