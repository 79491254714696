<template>
    <page-layout>
        <div>
            <div class="section d-flex">
                <div class="mt">{{ $t('view.vehicles.title') }} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
                <div class="ml-3" v-if="canAddVehicle"><b-button variant="link" @click="onAddVehicleClicked()">{{
                    $t('view.vehicles.addVehicle') }}</b-button></div>
                <b-dropdown v-if="canCreateReport" id="dropdown-form" :text="$t('view.vehicles.createReportDropdown')"
                    ref="dropdown" class="ml-auto">
                    <b-dropdown-form>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="downloadDateStart" class="form-label">{{
                                        $t("view.vehicles.createReportDateStart")
                                    }}</label>
                                    <input id="downloadDateStart" v-model="reportDateStart" type="date"
                                        class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="downloadDateEnd" class="form-label">{{
                                        $t("view.vehicles.createReportDateEnd")
                                    }}</label>
                                    <input id="downloadDateEnd" v-model="reportDateEnd" type="date" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <button id="button-download-report" type="button" class="btn btn-primary"
                            @click.stop="onCreateReportClicked()">
                            {{ $t("view.vehicles.createReportButton") }}
                        </button>
                    </b-dropdown-form>
                </b-dropdown>
            </div>
        </div>
        <div>
            <b-container fluid class="p-0">
                <b-row>
                    <b-col>
                        <SearchField class="w-100" :placeholder="$t('view.vehicles.searchfield.placeholder')"
                            @searchStringChanged="onSearchChanged" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table hover selectable select-mode="single" :items="items" :fields="fields"
                            @row-clicked="onRowClicked">
                            <template #cell(licensePlate)="data">
                                {{ data.item.licensePlate }}
                            </template>
                            <template #cell(odometer)="data">
                                {{ data.item.odometer }}
                            </template>
                            <template #cell(place)="data">
                                {{ formatPlace(data.item.place) }}
                            </template>
                            <template #cell(license)="data">
                                {{ formatSubscriptionPlanName(data.item.subscriptions) }}
                            </template>
                        </b-table>
                        <div v-if="isLoading" class="loading-bar">
                            <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                        </div>
                        <!-- <b-overlay :show="isLoading" no-wrap opacity="0.5"></b-overlay> -->
                        <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                    </b-col>
                </b-row>
            </b-container>
            <VehicleEditor v-if="canAddVehicle" :vehicle="newVehicle" :isBusy="isBusy" @save="onSaveVehicle"
                @delete="onDeleteVehicle" />
        </div>
    </page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'
import CompanyApi from '@/api/company'
import DisplayUtils from '@/utils/display-utils'
import DataUtils from '@/utils/data-utils'
import VehicleEditor from "@/components/VehicleEditor.vue";
import SearchField from "@/components/SearchField.vue";
import Toaster from "@/utils/toaster"
import PermissionUtils from "@/utils/permission-utils"
import { DateTime } from 'luxon'
import ReportApi from '@/api/report'

export default {
    components: {
        PageLayout,
        VehicleEditor,
        SearchField,
    },

    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            newVehicle: {},
            items: [],
            isEndOfTableVisible: true,
            searchString: '',
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            reportDateStart: DateTime.now().minus({month: 1}).startOf('month').toISODate(),
            reportDateEnd: DateTime.now().minus({month: 1}).endOf('month').toISODate(),
            fields: [
                { key: 'licensePlate', label: this.$t('view.vehicles.licensePlate') },
                { key: 'odometer', label: this.$t('view.vehicles.odometer'), thClass: 'text-right', tdClass: 'text-right' },
                { key: 'place', label: this.$t('view.vehicles.place') },
                { key: 'license', label: this.$t('view.vehicles.license') },
            ],

            // sections: [
            //     { label: 'Fahrten', target: 'VehicleTrips'},
            //     { label: 'Ausgaben', target: 'VehicleExpenses'},
            // ]
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        vehicle() {
            return this.$store.state.vehicle
        },
        canAddVehicle() {
            return PermissionUtils.isAuthorizedInCompany('vehicle:add', this.company)
        },
        canCreateReport() {
            return PermissionUtils.isAuthorizedInCompany('company:reportTemplates:report', this.company)
        },
    },

    watch: {
        async company() {
            await this.reloadItems();
        },
    },

    methods: {
        onSearchChanged(searchString) {
            this.searchString = searchString
            this.reloadItems()
        },
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddVehicleClicked() {
            this.newVehicle = {
                timeZone: DateTime.now().zoneName,
                systemOfUnits: 'metric',
                currencyCode: 'EUR',
                isAutoRecordingEnabled: true,
                isRecordTrackEnabled: true,
                isSaveTrackEnabled: true
            }
            this.$bvModal.show('modal-editor')
        },
        async onCreateReportClicked() {
            this.isBusy = true
            Toaster.showSuccess(this, { message: "Creating report." })
            try {
                let companyId = this.company.id

                let createResult = await ReportApi.createVehiclesReport(
                    companyId,
                    {
                        dateStart: DateTime.fromISO(this.reportDateStart).startOf('day').toUTC().toISO(),
                        dateEnd: DateTime.fromISO(this.reportDateEnd).endOf('day').toUTC().toISO()
                    }
                )

                let blob = new Blob([createResult.data], { type: createResult.contentType })
                let link = document.createElement('a')
                let fileName = createResult.filename

                link.href = window.URL.createObjectURL(blob)
                link.download = fileName
                link.click()
            } catch (error) {
                if (error.isAxiosError && error.response.status === 409) {
                    Toaster.showErrorMessage(this, 'Eine Einladung mit dieser E-Mail-Adresse existiert bereits.', {
                        title: `Einladung fehlgeschlagen`
                    })
                } else {
                    Toaster.showError(this, error, {
                        title: `Einladung fehlgeschlagen`
                    })
                }
            }
            this.isBusy = false
        },
        async onSaveVehicle() {
            this.isBusy = true
            try {
                let attributes = { ...this.newVehicle }
                DataUtils.setClientTimestamps(attributes)
                //     licensePlate: this.newVehicle.licensePlate,
                //     timeZone: this.newVehicle.timeZone,
                //     systemOfUnits: this.newVehicle.systemOfUnits,
                //     currencyCode: this.newVehicle.currencyCode,
                //     subscriptions: this.newVehicle.subscriptions,
                // }
                await CompanyApi.addVehicle(
                    this.company.id,
                    attributes
                )
                await this.reloadItems()
                this.$bvModal.hide('modal-editor')
            } catch (error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false

        },
        onDeleteVehicle() {
            // this should not happen
        },
        onRowClicked(item, index) {
            this.selectedItem = this.items[index]
            this.$store.dispatch('setVehicle', this.selectedItem)
            this.$router.push({
                name: 'VehicleTrips',
                params: { vehicleId: this.selectedItem.id }
            })
        },
        formatPlace(place) {
            return DisplayUtils.stringFromPlace(place, '-')
        },
        formatSubscriptionPlanName(subscriptions) {
            if (subscriptions && subscriptions.length > 0) {
                let subscription = subscriptions[0]
                return subscription.planName ?? '-'
            }
            return this.$t('view.vehicles.subscription.none')
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                const result = await CompanyApi.getVehicles(this.company.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset,
                    "filter[licensePlate]": this.searchString,
                })

                if (result.data) {
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        }
    },

    mounted: async function () {
        this.isBusy = true;
        console.log(DateTime.fromISO(undefined).toUTC().toISO())
        try {
            await this.reloadItems();
        } catch (error) {
            Toaster.showError(this, error);
        }
        this.isBusy = false;
        this.isMounted = true;
    },
};
</script>

<style scoped></style>