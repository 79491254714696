<template>
    <div v-if="isMounted">
        <div class="section d-flex">
            <div class="mt">{{$t('view.vehicle.settings.title')}}</div>
        </div>
        <b-container fluid class="p-0">
            <b-row><b-col>
                <b-form-group :label="$t('view.vehicle.settings.licensePlate')" label-class="label">
                    <b-form-input v-model="item.licensePlate" type="text" :disabled="isBusy"></b-form-input>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.vehicle.settings.timeZone')" label-class="label">
                    <b-form-select v-if="isTimeZoneEnabled" v-model="item.timeZone" :options="timeZones" class="form-control" :disabled="isBusy"></b-form-select>
                    <b-form-input v-else v-model="item.timeZone" type="text" :disabled="true"/>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.vehicle.settings.systemOfUnits')" label-class="label">
                    <b-form-select v-model="item.systemOfUnits" :options="systemOfUnits" class="form-control" :disabled="isBusy"></b-form-select>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.vehicle.settings.currencyCode')" label-class="label">
                    <b-form-select v-model="item.currencyCode" :options="currencyCodes" class="form-control" :disabled="isBusy"></b-form-select>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.vehicle.settings.subscription')" label-class="label">
                    <b-form-select v-model="selectedSubscription" :options="subscriptionItems" class="form-control" :disabled="isBusy"></b-form-select>
                </b-form-group>
            </b-col></b-row>

            <b-row>
                <b-col cols="4">
                    <b-form-group :label="$t('view.vehicle.settings.autoRecording')" label-class="label">
                        <b-form-select v-model="item.isAutoRecordingEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group :label="$t('view.vehicle.settings.recordTrack')" label-class="label">
                        <b-form-select v-model="item.isRecordTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group :label="$t('view.vehicle.settings.saveTrack')" label-class="label">
                        <b-form-select v-model="item.isSaveTrackEnabled" :options="onOff" class="form-control" :disabled="isBusy"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="actions"><b-col class="d-flex w-100">
                <b-button variant="outline-danger" v-if="!isNewItem" :disabled="isBusy" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
                <b-button variant="success" class="ml-auto" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
            </b-col></b-row>
        </b-container>
        <VehicleDeletionConfirmationModal :vehicle="item" @delete-clicked="onDeleteClicked"/>
    </div>
</template>

<script>
import VehicleApi from '@/api/vehicle'
import CompanyApi from '@/api/company'
import Toaster from '@/utils/toaster'
import DataUtils from '@/utils/data-utils'
import { zones } from 'tzdata';
import { DateTime } from 'luxon'
import VehicleDeletionConfirmationModal from '@/components/VehicleDeletionConfirmationModal.vue';

export default {
    components: {
    VehicleDeletionConfirmationModal
},

    data: function () {
        return {
            item: {
                systemOfUnits: 'metric',
                currencyCode: 'EUR',
                isAutoRecordingEnabled: true,
                isRecordTrackEnabled: true,
                isSaveTrackEnabled: true,
            },
            timeZones: [],
            isMounted: false,
            isBusy: false,
            isTimeZoneEnabled: false,
            selectedSubscription: null,
            subscription: null,
            systemOfUnits: [
                { value: 'metric', text: this.$t('systemOfUnits.metric') },
                { value: 'imperial', text: this.$t('systemOfUnits.imperial') },
                { value: 'usa', text: this.$t('systemOfUnits.usa') },
            ],
            onOff: [
                { value: true, text: this.$t('option.on') },
                { value: false, text: this.$t('option.off') },
            ],
            currencyCodes: [
                'GBP',
                'EUR',
                'CHF',
                'USD',
            ],
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        subscriptionItems() {
            let items = [
                { value: null, text: this.$t('view.vehicle.settings.subscription.none') }
            ]
            if (this.subscription) {
                let includeIt = this.subscription.usage < this.subscription.quantity
                if (this.item.subscriptions.length > 0 && this.subscription.id == this.item.subscriptions[0].id) {
                    includeIt = true
                }
                if (includeIt) {
                    items = [
                        ...items,
                        {
                            value: this.subscription.id,
                            text: this.formatSubscription(this.subscription)
                        }
                    ]
                }
            }
            return items
        },
        isNewItem() {
            return this.vehicleId === undefined
        },
    },

    methods: {
        formatSubscription(subscription) {
            let text = subscription.subscriptionProduct.name
            text += ' (' + subscription.usage + " / " + subscription.quantity + ")"
            return text
        },
        async onSaveClicked() {
            this.isBusy = true
            try {
                const attributes = {
                    licensePlate: this.item.licensePlate,
                    timeZone: this.item.timeZone,
                    systemOfUnits: this.item.systemOfUnits,
                    currencyCode: this.item.currencyCode,
                    subscriptions: this.selectedSubscription ? [this.selectedSubscription] : [],
                    isAutoRecordingEnabled: this.item.isAutoRecordingEnabled,
                    isRecordTrackEnabled: this.item.isRecordTrackEnabled,
                    isSaveTrackEnabled: this.item.isSaveTrackEnabled,
                    sid: this.item.sid,
                }
                // let attributes = {
                //     ...this.item,
                //     subscriptions: this.selectedSubscription ? [this.selectedSubscription] : [],
                // }
                DataUtils.setClientTimestamps(attributes)
                let result = undefined

                if (this.isNewItem) {
                    result = await CompanyApi.addVehicle(
                        this.company.id,
                        attributes
                    )
                } else {
                    result = await VehicleApi.updateVehicle(
                        this.vehicleId,
                        attributes
                    )
                }
                this.item = {
                    id: result.data.id,
                    ...result.data.attributes
                }
                Toaster.showSuccess(this, {
                    title: 'Hinweis',
                    message: `Ihre Änderungen wurden gespeichert`
                })
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        async onDeleteClicked(confirmedVehicle) {
            if (!confirmedVehicle) {
                this.$bvModal.show('modal-vehicle-deletion-confirmation')
                return
            }
            this.$bvModal.hide('modal-vehicle-deletion-confirmation')
            try {
                await VehicleApi.deleteVehicle(
                    this.vehicleId
                )
                this.$router.replace({
                    name: 'Vehicles',
                })
            } catch (error) {
                if (error.isAxiosError) {
                    console.error(error)
                }
            }
        }
    },

    mounted: async function() {
        this.isBusy = true
        try {
            this.timeZones = Object.entries(zones)
                .filter(([zoneName, v]) => Array.isArray(v))
                .map(([zoneName, v]) => zoneName)
                .filter(tz => DateTime.local().setZone(tz).isValid)
            try {
                this.subscription = await CompanyApi.getSubscription(this.company.id)
            } catch (error) {
                if (error.isAxiosError && error.response.status == 404) {
                    this.subscription = null
                }
            }
            if (!this.isNewItem) {
                const vehicle = await VehicleApi.getVehicle(
                    this.$route.params.vehicleId
                )
                this.item = {
                    id: vehicle.data.id,
                    ...vehicle.data.attributes
                }
                if (this.item.subscriptions && this.item.subscriptions.length > 0) {
                    this.selectedSubscription = this.item.subscriptions[0].id // maybe later there will be more licenses / verhicle
                } else {
                    this.selectedSubscription = null
                }
            }
            this.isTimeZoneEnabled = !this.item.timeZone
            this.isBusy = false
        } catch (error) {
            Toaster.showError(this, error)
        }
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>