<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.vehicle.users.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3"><b-button variant="link" @click="onAddUserClicked()">{{$t('view.vehicle.users.addUser')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table striped hover selectable select-mode="single" :items="getItems" :fields="fields" @row-clicked="onRowClicked" >
                        <template #cell(firstName)="data">
                            {{ data.item.firstName }}
                        </template>
                        <template #cell(lastName)="data">
                            {{ data.item.lastName }}
                        </template>
                        <template #cell(email)="data">
                            {{ data.item.email }}
                        </template>
                        <template #cell(roles)="data">
                            {{ formatRole(data.item.roles[0]) }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <VehicleUserPicker @user-selected="onAddUser" />
        <VehicleUserEditor :user="selectedUser" @delete="onDeleteUserClicked"/>
    </div>
</template>

<script>
import VehicleApi from '@/api/vehicle'
import Toaster from "@/utils/toaster"
import VehicleUserPicker from "@/components//VehicleUserPicker.vue";
import VehicleUserEditor from "@/components//VehicleUserEditor.vue";

export default {
    components: {
        VehicleUserPicker,
        VehicleUserEditor
    },

    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            selectedUser: {},
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            fields: [
                { key: 'firstName', label: this.$t('view.vehicle.users.firstName') },
                { key: 'lastName', label: this.$t('view.vehicle.users.lastName') },
                { key: 'email', label: this.$t('view.vehicle.users.email') },
                { key: 'roles', label: this.$t('view.vehicle.users.role') },
            ]
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicle() {
            return this.$store.state.vehicle
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        getItems() {
            return this.items.map((employee) => {
                return {
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    email: employee.email,
                    roles: employee.roles,
                };
            });
        },
    },
    watch: {
        vehicle: async function () {
            await this.reloadItems()
        }
    },

    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddUserClicked() {
            this.$bvModal.show('modal-editor')
            // this.$router.push({ name: 'VehicleUserAdd' })
        },
        async onAddUser(userId, roles) {
            this.isBusy = true
            try {
                const result = await VehicleApi.setUser(
                    this.vehicleId,
                    userId,
                    roles,
                )
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            await this.reloadItems()
            this.$bvModal.hide('modal-editor')
        },
        async onDeleteUserClicked(user) {
            this.isBusy = true
            try {
                const result = await VehicleApi.setUser(
                    this.vehicleId,
                    user.id,
                    [],
                )
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            await this.reloadItems()
            this.$bvModal.hide('modal-vehicle-user-editor')
        },
        onRowClicked(item, index) {
            this.selectedUser = {
                ...this.items[index]
            }
            this.$bvModal.show('modal-vehicle-user-editor')
            // this.$router.push({
            //     name: 'VehicleUserEdit',
            //     params: {userId: this.selectedItem.id}
            // })
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isBusy = true;
                this.isLoading = true;
                try {
                    // await new Promise(r => setTimeout(r, 2000));
                    let offset = this.items.length
                    const result = await VehicleApi.getUsers(this.vehicleId, {
                        "page[limit]": this.limit,
                        "page[offset]": offset
                    })

                    if (result.data) {
                        result.data.forEach(item => {
                            this.items.push({
                                id: item.id,
                                ...item.attributes
                            })
                        })
                    }
                    var total = this.items.length
                    if (result.meta) {
                        if (result.meta.page) {
                            total = result.meta.page.total
                        }
                    }
                    this.totalItems = total
                    this.isLoading = false;
                    if (result.data.length > 0) {
                        setTimeout(() => {
                            this.checkAutoappendItems()
                        }, 20)
                    }
                } catch (error) {
                    Toaster.showError(this, error);
                }
                this.isLoading = false;
                this.isBusy = false;
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        },
        formatPlace: function(place) {
            if (place) {
                const text = place.name
                return text
            }
            return ''
        },
        formatRole: function(role) {
            return this.$t('role.' + role)
        }
    },

    mounted: async function() {
        this.reloadItems();
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>
