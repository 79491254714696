<template>
  <div v-if="isMounted">
    <div class="section d-flex">
      <div class="mt">
        {{$t('view.selectionlists.contacts.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span>
      </div>
      <div class="ml-3">
        <b-button variant="link" @click="onAddContactClicked()">{{$t('view.selectionlists.contacts.addContact')}}</b-button
        >
      </div>
    </div>
    <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <b-table
            hover
            select-mode="single"
            :items="items"
            :fields="fields"
            @row-clicked="onRowClicked"
          >
            <template #cell(name)="data">
              {{ data.item.firstName }} {{ data.item.lastName }}
            </template>
            <template #cell(lastName)="data">
              {{ data.item.lastName }}
            </template>
            <template #cell(company)="data">
              {{ data.item.company }}
            </template>
            <template #cell(place)="data">
              <div v-if="data.item.place">
                {{ formatPlace(data.item.place) }}
              </div>
            </template>
          </b-table>
          <div v-if="isLoading" class="loading-bar">
            <div>
              <b-spinner small label="Spinning"></b-spinner> Einträge werden
              geladen...
            </div>
          </div>
          <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
        </b-col>
      </b-row>
    </b-container>
    <ContactEditor :contact="selectedItem" :isBusy="isBusy" @save="onSaveContact" @delete="onDeleteContact"/>
    <!-- <b-modal id="modal-editor" size="lg" :title="editorTitle" ok-only no-stacking centered>
             <template #default="">
                <b-container fluid class="p-0">
                    <b-row><b-col class="label">Vorname</b-col><b-col class="label">Nachname</b-col></b-row>
                    <b-row>
                        <b-col><b-form-input v-model="selectedItem.firstName" type="text" :disabled="isBusy"></b-form-input></b-col>
                        <b-col><b-form-input v-model="selectedItem.lastName" type="text" :disabled="isBusy"></b-form-input></b-col>
                    </b-row>
                    <b-row><b-col class="label mt-2">Firma</b-col></b-row>
                    <b-row><b-col><b-form-input v-model="selectedItem.company" type="text" :disabled="isBusy"></b-form-input></b-col></b-row>
                    <b-row><b-col class="label mt-2">Adresse</b-col></b-row>
                    <b-row><b-col>
                        <vue-bootstrap-typeahead
                            :data="addresses"
                            :disabled="isBusy"
                            :serializer="address => address.description"
                            v-model="addressSearch"
                            @hit="onAddressSelected($event)"
                        />
                    </b-col></b-row>
                    <b-row><b-col><Map ref="map" :mapOptions="mapOptions" class="mt-3 p-0"/></b-col></b-row>
                </b-container>
            </template>
            <template #modal-footer="{ cancel}">
                <div class="d-flex w-100">
                        <b-button variant="outline-danger" v-if="selectedItem.id" :disabled="isBusy" @click="onDeleteClicked()">Löschen</b-button>
                        <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">Abbrechen</b-button>
                        <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">Speichern</b-button>
                </div>
            </template>
        </b-modal> -->
  </div>
</template>

<script>
import UserApi from "@/api/user"
import Toaster from "@/utils/toaster"
import ContactEditor from "@/components/selectionlist/ContactEditor"
import DisplayUtils from "@/utils/display-utils"
import DataUtils from '@/utils/data-utils'
// import Map from '@/components/Map'
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// import { Loader } from '@googlemaps/js-api-loader'
import _ from "underscore";

export default {
  components: {
    ContactEditor,
    // Map,
    // VueBootstrapTypeahead
  },

  data: function () {
    return {
      addresses: [],
      addressSearch: "",
      selectedAddress: null,
      // google: null,
      // autocompleteService: null,

      place: {},

      isMounted: false,
      isBusy: false,
      isLoading: false,
      items: [],
      isEndOfTableVisible: true,
      currentPage: 1,
      limit: 50,
      totalItems: -1,
      selectedItem: {},
      address: "",
      mapOptions: {
        center: {
          lat: 48.45342,
          lng: 9.09484,
        },
        zoom: 16,
      },
      fields: [
        { key: "name", label: this.$t('view.selectionlists.contacts.name')},
        { key: "company", label: this.$t('view.selectionlists.contacts.company')},
        { key: "place", label: this.$t('view.selectionlists.contacts.place')},
      ],
    };
  },

  computed: {},

  methods: {
    onEndOfTableVisibilityChanged(el) {
      this.isEndOfTableVisible = el.isIntersecting
      this.checkAutoappendItems()
    },
    async onSaveContact() {
        this.isBusy = true
          try {
            let attributes = { ...this.selectedItem }
            DataUtils.setClientTimestamps(attributes)
            //     firstName: this.selectedItem.firstName,
            //     lastName: this.selectedItem.lastName,
            //     company: this.selectedItem.company,
            //     place: this.selectedItem.place
            // }

            if (this.selectedItem.id) {
                await UserApi.updateSelectionListContact(
                    this.selectedItem.id,
                    attributes
                )
            } else {
                await UserApi.addSelectionListContact(
                    attributes
                )
            }
            this.reloadItems()
            this.$bvModal.hide("modal-editor");
        } catch(error) {
            Toaster.showError(this, error, {
                title: `Speichern fehlgeschlagen`
            })
        }
        this.isBusy = false
      },
    async onDeleteContact() {
        this.isBusy = true
        try {
            await UserApi.deleteSelectionListContact(
                this.selectedItem.id,
            )
            this.reloadItems()
            this.$bvModal.hide("modal-editor");
        } catch(error) {
            Toaster.showError(this, error, {
                title: `Löschen fehlgeschlagen`
            })
        }
        this.isBusy = false
    },
    onAddContactClicked() {
      this.selectedItem = {};
      this.$bvModal.show("modal-editor");
      // this.$router.push({ name: 'ContactAdd' })
    },
    onRowClicked(item, index) {
      this.selectedItem = { ...this.items[index] };
      let center = { lat: 0, lng: 0 };
      let zoom = 4;
      if (this.selectedItem.place && this.selectedItem.place.coordinate) {
        center = {
          lat: this.selectedItem.place.coordinate.latitude,
          lng: this.selectedItem.place.coordinate.longitude,
        };
        zoom = 16;
      }
      this.mapOptions.center = center;
      this.mapOptions.zoom = zoom;
      this.$bvModal.show("modal-editor");

      // this.selectedItem = this.items[index]
      // this.$router.push({
      //     name: 'ContactEdit',
      //     params: {contactId: this.selectedItem.id}
      // })
    },
    async reloadItems() {
      this.items = [];
      this.totalItems = -1;
      await this.appendItems();
    },
    async appendItems() {
      if (this.totalItems == -1 || this.items.length < this.totalItems) {
        this.isBusy = true;
        this.isLoading = true;
        try {
          // await new Promise(r => setTimeout(r, 2000));
          let offset = this.items.length;
          const result = await UserApi.getSelectionListContacts({
            "page[limit]": this.limit,
            "page[offset]": offset,
          });

          if (result.data) {
            result.data.forEach((item) => {
              this.items.push({
                id: item.id,
                ...item.attributes,
              });
            });
          }
          var total = this.items.length;
          if (result.meta) {
            if (result.meta.page) {
              total = result.meta.page.total;
            }
          }
          this.totalItems = total;
          if (result.data.length > 0) {
            setTimeout(() => {
              this.checkAutoappendItems()
            }, 20)
          }
        } catch (error) {
          Toaster.showError(this, error);
        }
        this.isLoading = false;
        this.isBusy = false;
      }
    },
    async checkAutoappendItems() {
      if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
      }
      await this.appendItems()
    },
    formatPlace: function (place) {
      return DisplayUtils.stringFromPlace(place)
    },
    async getAddresses(query) {
      // this.addresses = [
      //     "gomaringen",
      //     // "gomadingen"
      // ]
      let request = {
        input: query,
      };
      this.$refs.map.autocompleteService.getPlacePredictions(
        request,
        (results, status) => {
          if (
            status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK
          ) {
            this.addresses = results;
          }
        }
      );
      // const res = await fetch(API_URL.replace(':query', query))
      // const suggestions = await res.json()
      // this.addresses = suggestions.suggestions
    },
    async onAddressSelected(address) {
      this.selectedAddress = address;
      const request = {
        placeId: address.place_id,
      };
      this.$refs.map.placesService.getDetails(request, (result, status) => {
        if (
          status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK
        ) {
          let details = {};
          result.address_components.forEach((component) => {
            component.types.forEach((type) => {
              details[type] = component.long_name;
              details[type + "short"] = component.short_name;
            });
          });

          // compile dlp address
          let address = {};
          let streetArray = [];
          if (details.route) {
            streetArray.push(details.route);
          }
          if (details.street_number) {
            streetArray.push(details.street_number);
          }
          address.street = streetArray.join(" ");
          address.postalCode = details.postal_code;
          address.city = details.locality;
          address.country = details.country;

          this.place = {};
          this.place.address = address;
          if (result.geometry && result.geometry.location) {
            this.place.coordinate = {
              latitude: result.geometry.location.lat(),
              longitude: result.geometry.location.lng(),
            };
          }
        }
      });
    },
  },

  mounted: async function () {
    this.isBusy = true;

    try {
      await this.reloadItems();
    } catch (error) {
      Toaster.showError(this, error);
    }

    // new this.$refs.map.google.maps.places.Autocomplete(this.$refs.autocomplete, {
    //     componentRestrictions: { country: ["us", "ca"] },
    //     fields: ["address_components", "geometry"],
    //     types: ["address"],
    // });

    // const loader = new Loader({
    //     apiKey: "AIzaSyCeWeZhN86arwOwRKDD66KcbSGLH7448Q8",
    //     version: "weekly",
    //     libraries: ["places"]
    // })

    // this.google = await loader.load()
    // this.autocompleteService = new this.google.maps.places.AutocompleteService()

    this.isBusy = false;
    this.isMounted = true;
  },

  watch: {
    addressSearch: _.debounce(function (addr) {
      this.getAddresses(addr);
    }, 500),
  },
};
</script>

<style scoped>
</style>
