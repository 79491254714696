<template>
    <div>
        <b-modal id="modal-editor" size="lg" :title="editorTitle" ok-only no-stacking centered>
            <template #default="">
              <b-container fluid class="p-0">
                <b-row><b-col>
                  <b-form-group :label="$t('view.reasons.editor.label')" label-class="label">
                    <b-form-input v-model="reason.label" type="text" :disabled="isBusy"/>
                  </b-form-group>
                </b-col></b-row>
              </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="reason.id" :disabled="isBusy" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>


export default {
  components: {
  },
  props: {
      reason: { type: Object, required: true },
      isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
    }
  },
  computed: {
    editorTitle() {
        if (this.reason.id) {
            return this.$t('view.reasons.editor.title.edit')
        } else {
            return this.$t('view.reasons.editor.title.add')
        }
    },
  },
  methods: {
    onSaveClicked: function() {
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete')
    },
  },
  mounted: async function() {
  },
  watch: {
  },

}
</script>

<style scoped>
.label {
    font-size: 0.8em;
}
</style>
