<template>
  <div class="d-flex">
    <div class="" style="height: 100%;">
      <Sidebar class="position-absolute position-md-static" :menu="menu" style="height: 100%; z-index: 1000;" />
    </div>
    <div class="flex-fill px-md-4 px-2 py-3" style="overflow-y: scroll;">
      <div id="main">
        <div class="d-flex">
          <div class="flex-fill">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/Sidebar.vue";
import PermissionUtils from "@/utils/permission-utils"

export default {
  name: "Main",
  components: {
    Sidebar,
  },
  computed: {
    menu() {
      let menu = []
      if (this.isSetupMenuEnabled()) {
        menu.push({ label: '', type: 'title' })
        menu.push({
          icon: 'rocket_launch',
          label: "Setup",
          path: '/setup',
          isSelected: this.$route.meta.menuId == 'setup'
        })
      }
      menu.push({ label: 'Persönliche Daten', type: 'title' })
      menu.push({
        icon: 'list',
        label: this.$t('company.menu.selectionLists'),
        path: '/user/selectionlists',
        isSelected: this.$route.meta.menuId == 'user.selectionLists'
      })
      // menu.push({
      //     icon: 'directions_car',
      //     label: this.$t('company.menu.vehicles'),
      //     path: '/company/vehicles'
      // })
      let companies = this.$store.state.User.companies
      // let companies = JSON.parse(JSON.stringify(this.$store.state.User.companies)).sort((a, b) => {
      //     let x = a.type.toLowerCase();
      //     let y = b.type.toLowerCase();
      //     if (x < y) {return -1;}
      //     if (x > y) {return 1;}
      //     return 0;
      // })
      companies.forEach(company => {
        menu.push({ label: company.name, type: 'title' })
        let isSelectedCompany = this.$route.params?.companyId == company.id
        if (this.isVehiclesMenuEnabled(company)) {
          menu.push({
            icon: 'directions_car',
            label: this.$t('company.menu.vehicles'),
            path: `/company/${company.id}/vehicles`,
            isSelected: isSelectedCompany && this.$route.meta.menuId == 'company.vehicles'
          })
        }
        if (this.isReportsMenuEnabled(company)) {
          menu.push({
            icon: 'description',
            label: this.$t('company.menu.reports'),
            path: `/company/${company.id}/report-templates`,
            isSelected: isSelectedCompany && this.$route.meta.menuId == 'company.reportTemplates'
          })
        }
        if (this.isSelectionListsMenuEnabled(company)) {
          menu.push({
            icon: 'list',
            label: this.$t('company.menu.selectionLists'),
            path: `/company/${company.id}/selectionlists`,
            isSelected: isSelectedCompany && this.$route.meta.menuId == 'company.selectionLists'
          })
        }
        if (this.isEmployeesMenuEnabled(company)) {
          menu.push({
            icon: 'badge',
            label: this.$t('company.menu.employees'),
            path: `/company/${company.id}/employees`,
            isSelected: isSelectedCompany && this.$route.meta.menuId == 'company.employees'
          })
        }
        if (this.isSettingsMenuEnabled(company)) {
          menu.push({
            icon: 'settings',
            label: this.$t('company.menu.settings'),
            path: `/company/${company.id}/settings`,
            isSelected: isSelectedCompany && this.$route.meta.menuId == 'company.settings'
          })
        }
      })
      return menu
    },
  },
  watch: {
  },

  methods: {
    isSetupMenuEnabled() {
      return this.$store.state.User.companies.length == 0
    },
    isVehiclesMenuEnabled(company) {
      // this is always visible. If user cannot list all vehicles of the company, a list of
      // all direct linked vehicles of the user are listed.
      return true
    },
    isReportsMenuEnabled(company) {
      return PermissionUtils.isAuthorizedInCompany('company:reportTemplates:list', company)
    },
    isSelectionListsMenuEnabled(company) {
      return PermissionUtils.isAuthorizedInCompany('company:selectionlists:tags:list', company)
    },
    isSubscriptionsMenuEnabled(company) {
      return PermissionUtils.isAuthorizedInCompany('company:subscriptions:list', company)
    },
    isEmployeesMenuEnabled(company) {
      return PermissionUtils.isAuthorizedInCompany('company:users:list', company)
    },
    isSettingsMenuEnabled(company) {
      return PermissionUtils.isAuthorizedInCompany('company:view', company)
    },
  },
  mounted: function () {
  }
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
