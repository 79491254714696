<template>
  <div>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <div class="header mb-4">{{$t('view.purchaseSubscription.header')}}</div>
        </b-col>
      </b-row>
      <b-row class="purchase-intervall-section mb-4">
        <b-col cols="12" class="d-flex justify-content-center">
          <div class="d-flex billing-interval align-items-center">
            <div :class="{active: !isYearlyBillingSelected}">{{$t('view.purchaseSubscription.billing.monthly')}}</div>
            <div><b-form-checkbox switch v-model="isYearlyBillingSelected" size="lg" class="ml-4 mr-2"></b-form-checkbox></div>
            <div :class="{active: isYearlyBillingSelected}">{{$t('view.purchaseSubscription.billing.annual')}}</div>
          </div>
        </b-col>
      </b-row>
      <b-row align-v="stretch">

        <b-col v-for="(product, index) in products" :key="index" cols="12" lg="4">
          <div class="card h-100">
            <!-- <div class="card-header">Your current plan</div> -->
            <div class="card-header">{{ product.header }}</div>
            <div class="card-body">
              <img src="@/assets/icon.png" class="icon d-inline-block align-middle" alt="Icon" />
              <div class="product-name">{{ product.name }}</div>
              <div class="product-text" v-html="product.description"></div>
              <div class="product-price">{{ getPricePerVehicleAndMonth(product.plans[currentInterval]) }}</div>
              <div class="product-payment">{{ $t('view.purchaseSubscription.perVehicleAndMonth') }}</div>
              <div v-if="currentInterval === 'yearly'" class="subscription-price-interval">
                {{ $t('view.purchaseSubscription.yearlyPaymentPrice') }}
                {{ formatPrice(product.plans[currentInterval]) }}
              </div>
              <div v-if="currentInterval === 'monthly'" class="subscription-price-interval">
                {{ $t('view.purchaseSubscription.monthlyPaymentPrice') }}
                {{ formatPrice(product.plans[currentInterval]) }}
              </div>
              <b-button variant="outline-primary" class="product-button w-100" :disabled="!product.purchasable"
                @click="onPurchasePlan(product.plans[currentInterval].id)">{{
                    $t('view.purchaseSubscription.button.buy')
                }}</b-button>
              <div class="product-features">
                <ul>
                  <li v-for="(feature, index) in product.features" :key="index" v-html="feature"></li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>

      </b-row>
      <!-- <b-row>
        <b-col>
          <div class="text-center mt-3">{{$t('view.purchaseSubscription.vatNotIncluded')}}</div>
        </b-col>
      </b-row> -->
    </b-container>

  </div>
</template>

<script>

import SubscriptionService from "@/services/subscription.service"
import DisplayUtils from "@/utils/display-utils"

export default {
  components: {
  },
  data: function () {
    return {
      isMounted: false,
      isBusy: false,
      isYearlyBillingSelected: true,
      intervals: [
        { value: 'yearly', text: this.$t('subscription.billingInterval.yearly') },
        { value: 'monthly', text: this.$t('subscription.billingInterval.monthly') },
      ],
      products: [
        {
          name: this.$t('product.free.name'),
          header: "Your current plan",
          description: "Free features for<br/>private usage",
          purchasable: false,
          features: [
            "Bis zu 20 Fahrten / Monat"
          ],
          plans: {
            monthly: {
              id: "free",
              currency: 'EUR',
              price: 0
            },
            yearly: {
              id: "free",
              currency: 'EUR',
              price: 0
            }
          }
        },
        {
          name: this.$t('product.privatePlus.name'),
          header: "",
          description: "Track unlimited<br/>private trips",
          purchasable: true,
          features: [
            "Alle Funktionen von Free",
            "Unbegrenzte Anzahl von<br>Fahrten / Monat"
          ],
          plans: {
            monthly: {
              id: "stripe.private_plus.monthly",
              currency: 'EUR',
              price: 0.99
            },
            yearly: {
              id: "stripe.private_plus.yearly",
              currency: 'EUR',
              price: 9.90
            }
          }
        },
        {
          name: this.$t('product.business.name'),
          header: "",
          description: "Business usage for<br/>individuals and organizations",
          purchasable: true,
          features: [
            "Alle Funktionen von Free",
            this.$t('feature.unlimited_trips'),
            this.$t('feature.business_trips'),
            this.$t('feature.tags'),
            this.$t('feature.auto_recording'),

          ],
          plans: {
            monthly: {
              id: "stripe.business.monthly",
              currency: 'EUR',
              price: 4
            },
            yearly: {
              id: "stripe.business.yearly",
              currency: 'EUR',
              price: 45
            }
          }
        },
      ],
    };
  },
  computed: {
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
    currentInterval() {
      return this.isYearlyBillingSelected ? 'yearly' : 'monthly'
    }
  },
  methods: {
    getPricePerVehicleAndMonth: function (plan) {
      let currency = plan.currency
      let pricePerVehicle = plan.price
      if (this.currentInterval == 'yearly') {
        pricePerVehicle /= 12
      }
      return DisplayUtils.formatCurrency(pricePerVehicle, currency, 2)
    },
    formatPrice: function (plan) {
      let pricePerVehicle = plan.price
      let currency = plan?.currency
      if (pricePerVehicle === undefined || currency === undefined) {
        return "-"
      }
      return DisplayUtils.formatCurrency(pricePerVehicle, currency, 2)
    },
    async onPurchasePlan(planId) {
      try {
        const route = this.$router.resolve({ name: 'SettingsBilling' });
        const myUrl = new URL(route.href, window.location.origin).href;
        const result = await SubscriptionService.subscribe(
          this.company.id,
          planId,
          myUrl,
          myUrl)
        const checkoutUrl = result.data.attributes.checkoutUrl
        window.location = checkoutUrl
      } catch (error) {
        console.error(error)
      }
    },

  },
  mounted: async function () {
    this.isMounted = true;
  },
}
</script>

<style scoped>
.header {
  font-size: 2rem;
  text-align: center;
}

.billing-interval {
  font-weight: 500;
}

.active {
}
.icon {
  width: 64px;
  height: 64px;
}

.card {
  text-align: center;
  /* font-size: 1.1rem; */
  font-size: 1rem;
  /* background-color: #333;
  color: white; */
}

.card-header {
  /* background-color: #404040;
  color: white; */
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  height: 52px;
}

.product-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-description {
  white-space: pre-line;
}

.product-features {
  text-align: left;
}

.product-price {
  font-size: 2.75rem;
  font-weight: 600;
}

.product-button {
  height: 42px;
  /* font-size: 1.15rem; */
  font-size: 1.1rem;
  font-weight: 400;
  margin: 20px 0px;
}
</style>