import Vue from 'vue'

export default {
    async getQuote(planId, quantity) {
        let result = await Vue.axios.post('/api/subscriptions/quote', {
            data: {
                attributes: {
                    planId: planId,
                    quantity: quantity
                }
            }
        })
        return result.data?.data?.attributes
    },
    async getSubscription(subscriptionId, parameters) {
        let result = await Vue.axios.get('/api/subscriptions/' + subscriptionId, {
            params: parameters
        })
        return result.data
    },
    async getSubscriptionTargets(subscriptionId, parameters) {
        let result = await Vue.axios.get('/api/subscriptions/' + subscriptionId + '/targets', {
            params: parameters
        })
        return result.data
    },
    async removeSubscriptionTarget(subscriptionId, targetId) {
        let result = await Vue.axios.delete('/api/subscriptions/' + subscriptionId + '/targets/' + targetId)
        return result
    },
}