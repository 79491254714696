import Vue from 'vue'

export default {
    async getExpense(expenseId) {
        let result = await Vue.axios.get('/api/expenses/' + expenseId)
        return result.data
    },

    async updateExpense(expenseId, attributes) {
        let result = await Vue.axios.patch('/api/expenses/' + expenseId, {
            data: {
                type: 'expense',
                attributes: attributes
            }
        })
        return result.data
    },

    async deleteExpense(expenseId) {
        let result = await Vue.axios.delete('/api/expenses/' + expenseId)
        return result.data
    },

}