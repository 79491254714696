<template>
    <div id="sidebar-container" class="sidebar" :class="{collapsed: !isOpen}">
        <div v-for="(item, index) in menu" :key="index">
            <div v-if="item.type === 'title'" class="nav-title">{{item.label}}</div>
            <div v-else>
                <SidebarLink :to="item.path" :selected="item.isSelected">
                    <span class="material-symbols-outlined icon">{{item.icon}}</span>&nbsp;&nbsp;{{item.label}}
                </SidebarLink>
                <div :class="{'hidden': !item.isSelected}">
                    <div v-for="subitem in item.children" :key="subitem.label">
                    <SidebarLink :to="subitem.path" :selected="isMenuItemSelected(subitem)">{{subitem.label}}</SidebarLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SidebarLink from './SidebarLink'

export default {
    name: "Sidebar",
    components: {
        SidebarLink,
    },
    props: {
      menu: { type: Array, required: true },
    },
    data: function() {
        return {
        }
    },
    methods: {
    },
    computed: {
        isOpen() {
            return this.$store.state.isSidebarOpen
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-title {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 7px;
    color: #666;
}

#sidebar-container {
    /* min-height: 100vh; */
    padding-top: 0.1rem;
    left: 0px;
    width: 200px;
    background-color: #f4f4f4;
    border-right: 1px solid #ccc;
    overflow-x: hidden;
    transition: 0.3s all ease;

    box-shadow: 0 0 10px black; /* note 0 offset values */
    clip-path: inset(0px -15px 0px 0px);
}

.collapsed {
    left: -200px !important;
    box-shadow: 0 0 0px black !important;
}

@media (min-width: 768px) {
    .collapsed {
        left: 0px !important;
    }
    #sidebar-container {
        box-shadow: 0 0 0px black; /* note 0 offset values */
    }
}
.icon {
    /* font-weight: 300; */
    /* font-size: 1.25rem; */
}

/* .icon {
    width: 40x;
    height: 40px;
    margin-right: 5px;
}
 */
.section {
    padding-top: 20px;
    padding-left: 15px;
    font-size: 1.2em;
    color: white;
}

.hidden {
    display: none;
}

h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
