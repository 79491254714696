<template>
    <div>
        <div class="section d-flex">
            <div class="">{{$t('view.expenses.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3"><b-button variant="link" @click="onAddExpenseClicked()">{{$t('view.expenses.addExpense')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table v-if="items.length > 0" striped hover selectable select-mode="single" :items="items" :fields="fields" @row-clicked="onRowClicked" >
                        <template #cell(expenseDate)="data">
                            {{ formatDate(data.item.expenseDateIso, data.item.expenseDateTz) }}
                        </template>
                        <template #cell(category)="data">
                            {{ formatCategory(data.item.category) }}
                        </template>
                        <template #cell(grossing)="data">
                            {{ formatCurrency(data.item.grossing, data.item.currencyCode, 2) }}
                        </template>
                        <template #cell(odometer)="data">
                            {{ data.item.odometer }}
                        </template>
                        <template #cell(place)="data">
                            {{formatPlace(data.item.place)}}
                        </template>
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell()="data">
                            <i>{{ data.value }}</i>
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <!-- <b-overlay :show="isLoading" no-wrap opacity="0.5"></b-overlay> -->
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <ExpenseEditor :expense="selectedItem" :isBusy="isBusy" @save="onSaveExpense" @delete="onDeleteExpense"/>
    </div>
</template>

<script>
import VehicleApi from '@/api/vehicle';
import ExpenseApi from '@/api/expense';
import Toaster from "@/utils/toaster"
import ExpenseEditor from "@/components/ExpenseEditor"
import DisplayUtils from "@/utils/display-utils";
import DataUtils from '@/utils/data-utils'
import { DateTime } from 'luxon'

export default {
    props: ["vehicleId"],
    components: {
        ExpenseEditor,
        // PageLayout,
    },
    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            selectedItem: {},
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            fields: [
                // { key: 'select', label: '' },
                { key: 'expenseDate', label: this.$t('view.expenses.expenseDate') },
                { key: 'category', label: this.$t('view.expenses.category') },
                { key: 'place', label: this.$t('view.expenses.place') },
                { key: 'grossing', label: this.$t('view.expenses.grossing') },
                // { key: 'odometer', label: 'Kilometerstand' },
                // { key: 'place', label: 'Start-/Zielort' },
                // { key: 'contactAndReason', label: 'Geschäftspartner / Reisezweck' },
                // { key: 'driver', label: 'Fahrer' },
                // { key: 'distance', label: 'Entfernung' },
            ]
        };
    },
    computed: {
        vehicle() {
            return this.$store.state.vehicle
        },
        // vehicleId() {
        //     return this.$route.params.vehicleId
        // },
    },
    watch: {
        vehicle: function () {
            this.reloadItems()
        }
    },
    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddExpenseClicked() {
            let dateTime = DateTime.now().set({
                millisecond: 0
            })

            this.selectedItem = {
                expenseDateIso: dateTime.toISO(),
                expenseDateTz: dateTime.zoneName,
                odometer: this.vehicle.odometer,
                isReferenceValue: false
            };

            this.$bvModal.show("modal-editor");
        },

        async onSaveExpense() {
            this.isBusy = true
            try {
                const attributes = {
                    category: this.selectedItem.category,
                    productType: this.selectedItem.productType,
                    grossing: this.selectedItem.grossing,
                    tax: this.selectedItem.tax,
                    currencyCode: this.vehicle.currencyCode,
                    amount: this.selectedItem.amount || 1,
                    unit: this.selectedItem.unit,
                    isReferenceValue: this.selectedItem.isReferenceValue,
                    odometer: this.selectedItem.odometer,
                    place: this.selectedItem.place,
                    coordinate: this.selectedItem.coordinate,
                    comment: this.selectedItem.comment,
                    expenseDateIso: this.selectedItem.expenseDateIso,
                    expenseDateTz: this.selectedItem.expenseDateTz,
                    sid: this.selectedItem.sid,
                }
                DataUtils.setClientTimestamps(attributes)
                if (this.selectedItem.id) {
                    await ExpenseApi.updateExpense(
                        this.selectedItem.id,
                        attributes
                    )
                } else {
                    await VehicleApi.addExpense(
                        this.vehicle.id,
                        attributes
                    )
                }
                await this.reloadItems()
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                console.log(error)
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false
        },

        async onDeleteExpense() {
            this.isBusy = true
            try {
                if (this.selectedItem.id) {
                    await ExpenseApi.deleteExpense(this.selectedItem.id)
                    this.$bvModal.hide('modal-editor')
                    await this.reloadItems()
                }
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },

        formatDate(date, timeZone) {
            return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.DATETIME_SHORT)
            // return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.DATETIME_SHORT)
        },

        formatCurrency(number, currency, digits) {
            const browserLanguage = navigator.language || navigator.userLanguage
            return new Intl.NumberFormat(
                browserLanguage,
                {
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits: digits,
                }
            ).format(number)
        },

        formatPlace(place) {
            return DisplayUtils.stringFromPlace(place, '-')
        },

        formatCategory(category) {
            return this.$t('expense.categories.' + category)
        },
        onRowClicked(item, index) {
            this.selectedItem = {
                ...this.items[index]
            }
            this.$bvModal.show('modal-editor')
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.vehicle && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                // console.log(`Fetching trips for vehicle ${this.vehicle.id}, offset = ${offset}`);
                const result = await VehicleApi.getExpenses(this.vehicle.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset
                })

                if (result.data) {
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        }
    },
    created: function () {},
    mounted: async function () {
        this.isBusy = true;
        try {
            await this.reloadItems();
        } catch (error) {
            Toaster.showError(this, error);
        }
        this.isBusy = false;
        this.isMounted = true;
    },
};
</script>

<style scoped>
.title {
    font-size: 2em;
}

.loading-bar {
    padding: 5px;
    background-color: #aaa;
}

</style>