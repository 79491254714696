import Vue from 'vue'

export default {
    async getCompany(companyId) {
        let result = await Vue.axios.get('/api/companies/' + companyId)
        return result.data
    },
    async addCompany(objectInfo) {
        let result = await Vue.axios.post('/api/companies', {
            data: {
                type: 'company',
                attributes: objectInfo
            }
        })
        return result.data
    },
    async updateCompany(companyId, objectInfo) {
        let result = await Vue.axios.patch('/api/companies/' + companyId, {
            data: {
                id: companyId,
                type: 'company',
                attributes: objectInfo
            }
        })
        return result.data
    },
    async getUsers(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/users', {
            params: parameters
        })
        return result.data
    },
    async setUserRole(companyId, userId, roleId) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/users/' + userId, {
            data: {
                id: userId,
                type: 'user',
                attributes: {
                    roleIds: [roleId]
                }
            }
        })
        return result.data
    },
    async deleteUser(companyId, userId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/users/' + userId)
        return result.data
    },
    async getVehicles(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/vehicles', {
            params: parameters
        })
        return result.data
    },
    async addVehicle(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/vehicles', {
            data: {
                type: 'vehicle',
                attributes: attributes
            }
        })
        return result.data
    },
    async getSubscription(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/subscription', {
            params: parameters
        })
        if (result.data?.data) {
            return {
                id: result.data?.data.id,
                ...result.data?.data.attributes
            }
        }
        return null
    },
    async cancelSubscription(companyId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/subscription')
        if (result.data?.data) {
            return {
                id: result.data?.data.id,
                ...result.data?.data.attributes
            }
        }
        return null
    },
    async updateSubscription(companyId, parameters) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/subscription', {
            data: {
                type: 'subscription',
                attributes: parameters
            }
        })
        if (result.data?.data) {
            return {
                id: result.data?.data.id,
                ...result.data?.data.attributes
            }
        }
        return null
    },
    async getInvitations(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/invitations', {
            params: parameters
        })
        return result.data
    },
    async invite(companyId, name, email, roleId, languageIso) {
        let url = '/api/companies/' + companyId + '/invitations'
        let result = Vue.axios.post(url, {
            data: {
                type: 'invitation',
                attributes: {
                    name: name,
                    email: email,
                    roleIds: [roleId],
                    languageIso: languageIso
                }
            }
        })
        return result
    },

    // SelectionLists

    // --------------------------------------
    //  Drivers
    // --------------------------------------
    async getSelectionListDrivers(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/drivers', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListDriver(companyId, driverId) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/drivers/' + driverId)
        return result.data
    },
    async addSelectionListDriver(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/selectionlists/drivers', {
            data: {
                type: 'driver',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListDriver(companyId, driverId, attributes) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/selectionlists/drivers/' + driverId, {
            data: {
                type: 'driver',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListDriver(companyId, driverId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/selectionlists/drivers/' + driverId)
        return result.data
    },


    // --------------------------------------
    //  Contacts
    // --------------------------------------
    async getSelectionListContacts(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/contacts', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListContact(companyId, contactId) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/contacts/' + contactId)
        return result.data
    },
    async addSelectionListContact(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/selectionlists/contacts', {
            data: {
                type: 'contact',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListContact(companyId, contactId, attributes) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/selectionlists/contacts/' + contactId, {
            data: {
                type: 'contact',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListContact(companyId, contactId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/selectionlists/contacts/' + contactId)
        return result.data
    },



    // --------------------------------------
    //  Reasons
    // --------------------------------------
    async getSelectionListReasons(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/reasons', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListReason(companyId, reasonId) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/reasons/' + reasonId)
        return result.data
    },

    async addSelectionListReason(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/selectionlists/reasons', {
            data: {
                type: 'reason',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListReason(companyId, reasonId, attributes) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/selectionlists/reasons/' + reasonId, {
            data: {
                type: 'reason',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListReason(companyId, reasonId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/selectionlists/reasons/' + reasonId)
        return result.data
    },



    // --------------------------------------
    //  Tags
    // --------------------------------------
    async getSelectionListTags(companyId, parameters) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/tags', {
            params: parameters
        })
        return result.data
    },
    async getSelectionListTag(companyId, tagId) {
        let result = await Vue.axios.get('/api/companies/' + companyId + '/selectionlists/tags/' + tagId)
        return result.data
    },

    async addSelectionListTag(companyId, attributes) {
        let result = await Vue.axios.post('/api/companies/' + companyId + '/selectionlists/tags', {
            data: {
                type: 'tag',
                attributes: attributes
            }
        })
        return result.data
    },
    async updateSelectionListTag(companyId, tagId, attributes) {
        let result = await Vue.axios.patch('/api/companies/' + companyId + '/selectionlists/tags/' + tagId, {
            data: {
                type: 'tag',
                attributes: attributes
            }
        })
        return result.data
    },
    async deleteSelectionListTag(companyId, tagId) {
        let result = await Vue.axios.delete('/api/companies/' + companyId + '/selectionlists/tags/' + tagId)
        return result.data
    },


}