<template>
    <div v-if="isMounted" class="mt-4">
        <!-- <div class="section d-flex">
            <div class="mt">{{ $t('view.settings.billing.title') }}</div>
        </div> -->
        <PurchaseSubscription v-if="subscription == null" />
        <b-container v-else fluid class="mb-4">

            <div class="card">
                <div class="card-header">Your current plan</div>
                <div class="card-body">
                    <h5 class="card-title">{{ subscription.subscriptionProduct.name }}<span v-if="planName"> ({{planName}})</span></h5>
                    <b-container fluid>
                        <!-- <b-row class="name-row">
                            <b-col class="d-flex align-items-center justify-content-center">
                                <div class="subscription-name">{{ subscription.subscriptionProduct.name }}<span v-if="planName"> ({{planName}})</span></div>
                                <div v-if="canCancel" class="ml-4"><a href="#" @click.prevent="onCancelSubscriptionClicked()">{{ $t('view.settings.billing.link.cancelSubscription') }}</a></div>
                            </b-col>
                        </b-row> -->
                        <b-row class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.status') }}</div>
                                <div v-if="!isCanceled" class="value">{{ $t(`subscription.status.${subscription.status}`) }}</div>
                                <div v-if="isCanceled" class="value">
                                    <span class="canceled">{{ $t('view.settings.billing.label.cancelAt') }} {{ formatCancelAtEnd() }}</span>
                                    <!-- <span class="revoke ml-4"></span><a href="#" @click.prevent="onRevokeCancellationClicked()">{{ $t('view.settings.billing.link.revokeCancellation') }}</a> -->
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="info-row">
                            <b-col class="d-flex align-items-center">
                                <div class="property">{{ $t('view.settings.billing.label.quantity') }}</div>
                                <div class="value">{{ subscription.quantity }}</div>
                                <div class="ml-4"  v-if="canChangeQuantity"><a href="#" @click.prevent="onChangeQuantityClicked()">{{ $t('view.settings.billing.link.changeQuantity') }}</a></div>
                            </b-col>
                        </b-row>
                        <b-row class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.used') }}</div>
                                <div class="value">{{ subscription.usage }}</div>
                            </b-col>
                        </b-row>
                        <b-row v-if="isAutorenewable" class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.amount') }}</div>
                                <div class="value">{{ formatAmount() }} <span class="vat-not-included" v-if="quote.taxBehavior == 'exclusive'">({{ $t('view.settings.billing.taxNotIncluded') }})</span></div>
                            </b-col>
                        </b-row>
                        <b-row v-if="isAutorenewable" class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.intervall') }}</div>
                                <div class="value">{{ $t(`subscription.billingInterval.${subscription.subscriptionPlan.billingInterval}`) }}</div>
                            </b-col>
                        </b-row>
                        <b-row v-if="subscription.cancelAt == null && isAutorenewable" class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.nextPayment') }}</div>
                                <div class="value">{{ formatCurrentPeriodEnd() }}</div>
                            </b-col>
                        </b-row>
                        <b-row v-if="isConsumable" class="info-row">
                            <b-col class="d-flex">
                                <div class="property">{{ $t('view.settings.billing.label.validUntil') }}</div>
                                <div class="value">{{ formatCurrentPeriodEnd() }}</div>
                            </b-col>
                        </b-row>
                    </b-container>
                    <div class="mt-4">
                        <a v-if="isCanceled" href="#" class="btn btn-outline-success" @click.prevent="onRevokeCancellationClicked()">{{ $t('view.settings.billing.link.revokeCancellation') }}</a>
                        <a v-if="canCancel" href="#" class="btn btn-outline-danger" @click.prevent="onCancelSubscriptionClicked()">{{ $t('view.settings.billing.link.cancelSubscription') }}</a>
                    </div>
                </div>
            </div>
            <!-- <b-row>
                <b-col class="d-flex align-items-center">
                    <div class="headline">Abodetails</div>
                </b-col>
            </b-row> -->
            <!-- <b-row>
                <b-col class="d-flex align-items-center">
                    <div class="subscription-name">{{ subscription.subscriptionProduct.name }}</div>
                    <b-button variant="outline-secondary">Kündigen</b-button>
                </b-col>
            </b-row> -->
            <!-- <b-row>
                <b-col>
                    <hr />
                </b-col>
            </b-row> -->
            <!-- <b-row class="name-row">
                <b-col class="d-flex align-items-center justify-content-center">
                    <div class="subscription-name">{{ subscription.subscriptionProduct.name }}<span v-if="planName"> ({{planName}})</span></div>
                    <div v-if="canCancel" class="ml-4"><a href="#" @click.prevent="onCancelSubscriptionClicked()">{{ $t('view.settings.billing.link.cancelSubscription') }}</a></div>
                </b-col>
            </b-row>
            <b-row class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.status') }}</div>
                    <div v-if="!isCanceled" class="value">{{ $t(`subscription.status.${subscription.status}`) }}</div>
                    <div v-if="isCanceled" class="value">
                        <span class="canceled">{{ $t('view.settings.billing.label.cancelAt') }} {{ formatCancelAtEnd() }}</span>
                        <span class="revoke ml-4"></span><a href="#" @click.prevent="onRevokeCancellationClicked()">{{ $t('view.settings.billing.link.revokeCancellation') }}</a>
                    </div>
                </b-col>
            </b-row>
            <b-row class="info-row">
                <b-col class="d-flex align-items-center">
                    <div class="property">{{ $t('view.settings.billing.label.quantity') }}</div>
                    <div class="value">{{ subscription.quantity }}</div>
                    <div class="ml-4"  v-if="canChangeQuantity"><a href="#" @click.prevent="onChangeQuantityClicked()">{{ $t('view.settings.billing.link.changeQuantity') }}</a></div>
                </b-col>
            </b-row>
            <b-row class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.used') }}</div>
                    <div class="value">{{ subscription.usage }}</div>
                </b-col>
            </b-row>
            <b-row v-if="isAutorenewable" class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.amount') }}</div>
                    <div class="value">{{ formatAmount() }} <span class="vat-not-included" v-if="quote.taxBehavior == 'exclusive'">({{ $t('view.settings.billing.taxNotIncluded') }})</span></div>
                </b-col>
            </b-row>
            <b-row v-if="isAutorenewable" class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.intervall') }}</div>
                    <div class="value">{{ $t(`subscription.billingInterval.${subscription.subscriptionPlan.billingInterval}`) }}</div>
                </b-col>
            </b-row>
            <b-row v-if="subscription.cancelAt == null && isAutorenewable" class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.nextPayment') }}</div>
                    <div class="value">{{ formatCurrentPeriodEnd() }}</div>
                </b-col>
            </b-row>
            <b-row v-if="isConsumable" class="info-row">
                <b-col class="d-flex">
                    <div class="property">{{ $t('view.settings.billing.label.validUntil') }}</div>
                    <div class="value">{{ formatCurrentPeriodEnd() }}</div>
                </b-col>
            </b-row> -->
            <!-- <b-row>
                <b-col class="d-flex">
                    <b-form-group label="Neue Menge" label-class="label">
                        <b-form-input size="sm" type="number" v-model="quantity" :number="true"></b-form-input>
                    </b-form-group>
                    <b-button variant="primary" @click="onUpdateSubscriptionClicked()">Update</b-button>
                </b-col>
            </b-row>
            <b-row class="actions">
                <b-col>
                    <b-button v-if="subscription.status == 'active'" variant="danger"
                        @click="onCancelSubscriptionClicked()">{{ $t('view.subscription.label.cancelSubscription') }}
                    </b-button>
                </b-col>
            </b-row> -->
        </b-container>
        <SubscriptionQuantityModal v-if="subscription" :subscription="subscription" @onUpdateQuantity="onUpdateQuantity($event)"/>
        <SubscriptionCancelModal v-if="subscription" :subscription="subscription" @onCancelSubscription="onCancelSubscription()"/>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import SubscriptionApi from '@/api/subscription'
import DisplayUtils from '@/utils/display-utils'
import PurchaseSubscription from '@/components/PurchaseSubscription.vue'
import SubscriptionQuantityModal from '@/components/SubscriptionQuantityModal.vue'
import SubscriptionCancelModal from '@/components/SubscriptionCancelModal.vue'

export default {
    components: {
        PurchaseSubscription,
        SubscriptionQuantityModal,
        SubscriptionCancelModal,
    },

    data: function () {
        return {
            subscription: null,
            isMounted: false,
            isBusy: false,
            quantity: 0,
            quote: null,
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        isSubscriptionActive() {
            return this.subscription.status === 'active'
        },
        isCanceled() {
            return this.subscription.cancelAt != null
        },
        isAutorenewable() {
            return this.subscription.subscriptionPlan?.type === 'autoRenewable'
        },
        isConsumable() {
            return this.subscription.subscriptionPlan?.type === 'consumable'
        },
        canCancel() {
            if (this.isCanceled) {
                return false
            }
            if (!this.isAutorenewable) {
                return false
            }
            return true
        },
        canChangeQuantity() {
            if (!this.isAutorenewable) {
                return false
            }
            return true
        },
        plan() {
            return this.subscription.subscriptionPlan
        },
        planName() {
            return this.subscription.subscriptionPlan?.name
        },
    },

    watch: {
        async company() {
            await this.reloadSubscription();
        }
    },

    methods: {
        formatCurrentPeriodEnd() {
            return DisplayUtils.formatDateOnly(this.subscription.currentPeriodEnd)
        },
        formatCancelAtEnd() {
            return DisplayUtils.formatDateOnly(this.subscription.cancelAt)
        },
        formatAmount() {
            let amount = this.quote.amount / 100.0
            let currency = this.subscription.currencyCode
            if (amount === undefined || currency === undefined) {
                return "-"
            }
            return DisplayUtils.formatCurrency(amount, currency, 2)
        },
        onCancelSubscriptionClicked() {
            this.$bvModal.show('cancel-modal')
        },
        onChangeQuantityClicked() {
            this.$bvModal.show('quantity-modal')
        },
        async onUpdateQuantity(quantity) {
            try {
                const result = await CompanyApi.updateSubscription(this.company.id, {
                    quantity: quantity
                })
                await this.reloadSubscription()
                this.$bvModal.hide('quantity-modal')
            } catch (error) {
                console.error(error)
            }
        },
        async onCancelSubscription() {
            try {
                const result = await CompanyApi.cancelSubscription(this.company.id)
                this.$bvModal.hide('cancel-modal')
            } catch (error) {
                console.error(error)
            }
        },
        async onRevokeCancellationClicked() {
            try {
                const result = await CompanyApi.updateSubscription(this.company.id, {
                    cancelAtPeriodEnd: false
                })
                await this.reloadSubscription()
            } catch (error) {
                console.error(error)
            }
        },
        async reloadSubscription() {
            this.isLoading = true
            try {
                this.subscription = await CompanyApi.getSubscription(
                    this.company.id,
                )
                this.quote = await SubscriptionApi.getQuote(
                    this.subscription.subscriptionPlan.id,
                    this.subscription.quantity
                )
                this.quantity = this.subscription.quantity
            } catch (error) {
                if (error.isAxiosError && error.response.status == 404) {
                    this.subscription = null
                }
            }
            this.isLoading = false
        }
    },

    mounted: async function () {
        await this.reloadSubscription()
        this.isMounted = true
    },
};
</script>

<style scoped>

.card {
    max-width: 620px;
}

.col {
    padding: 0px 0px;
}

.subscription {
    /* padding: 10px 10px; */
    border-radius: 5px;
    border: 1px solid #aaa;
    margin-bottom: 20px;
    max-width: 500px;
}

.name-row {
    padding: 25px 0px;
    line-height: 0.75rem;
    background-color: #eee;
}

.headline {
    font-weight: 800;
    font-size: 1.25rem;
    margin-right: 25px;
}

.subscription-name {
    font-weight: 800;
    font-size: 1.25rem;
}

.property {
    min-width: 180px;
    max-width: 240px;
}

/* .value {
    min-width: 150px;
    max-width: 240px;
} */

.info-row {
    padding: 10px 0px;
    line-height: 0.75rem;
}

hr {
    margin-top: 10px;
    margin-bottom: 20px;
}

.vat-not-included {
    font-style: italic;
    font-size: 0.75rem;
}

.canceled {
    color: #ee4040;
    font-weight: 500;
}

.revoke {
}

</style>