var self = {}

self.showSuccess = function(component, options) {
    component.$bvToast.toast(options.message, {
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 4000,
        variant: 'success',
        title: 'Hinweis',
        ...options
    })
}

self.showError = function(component, error, options) {
    let title = 'Fehler'
    let message = 'Es ist ein unbekannter Fehler aufgetreten'
    if (error.isAxiosError) {
        switch(error.response.status) {
            case 400:
                message = 'Die Anfrage war fehlerhaft.'
                break
            case 403:
                message = 'Sie haben nicht die notwendige Berechtigung.'
                break
            case 404:
                message = 'Das Objekt wurde nicht gefunden.'
                break
        }
        const errorCode = error?.response?.data?.errors[0]?.code
        if (errorCode) {
            switch(errorCode) {
                case 'license.business_trips_not_available':
                    message = component.$t('errors.' + errorCode)
                    break
                case 'license.tags_not_available':
                    message = component.$t('errors.' + errorCode)
                    break
                case 'license.number_of_free_trips_exceeded':
                    message = component.$t('errors.' + errorCode)
                    break
                case 'UserIsAlreadyMember':
                    message = 'Der Benutzer ist bereits Mitglied.'
                    break
            }
        }

    }
    component.$bvToast.toast(message, {
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 10000,
        variant: 'danger',
        title: title,
        ...options
    })
}

self.showErrorMessage = function(component, message, options) {
    let title = 'Fehler'
    component.$bvToast.toast(message, {
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 4000,
        variant: 'danger',
        title: title,
        ...options
    })
}

module.exports = self
