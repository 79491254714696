<template>
<page-layout :categories="categories">
    <template v-slot:header_large>
        <div class="title">{{$t('view.employees.title')}}</div>
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'
import PermissionUtils from "@/utils/permission-utils"

export default {
    components: {
        PageLayout,
    },

    data: function () {
        return {
            categories: []
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        canListEmployees() {
            return PermissionUtils.isAuthorizedInCompany('company:users:list', this.company)
        },
        canListInvitations() {
            return PermissionUtils.isAuthorizedInCompany('company:invitations:list', this.company)
        },
    },

    methods: {
    },

    mounted: async function() {
        let categories = []
        if (this.canListEmployees) {
            categories.push( { id: 'list', label: this.$t('view.employees.categories.list'), target: 'EmployeesList'} )
        }
        if (this.canListInvitations) {
            categories.push( { id: 'invitations', label: this.$t('view.employees.categories.invitations'), target: 'EmployeesInvitations'} )
        }
        this.categories = categories
    },
};
</script>

<style scoped>
.title {
    font-size: 1.25rem;
}
</style>
