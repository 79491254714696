import Vue from 'vue'

export default {
    async createReports(companyId, attributes) {
        let response = await Vue.axios({
            method: 'post',
            url: '/api/companies/' + companyId + '/reports',
            data: {
                data: {
                    type: 'report_template',
                    attributes: attributes
                },
            },
            responseType: 'blob'
        })

        let filename = response.headers['content-disposition']
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '')

        return {
            filename: filename,
            contentType: response.headers['content-type'],
            data: response.data
        }
    },
    async createVehiclesReport(companyId, attributes) {
        let response = await Vue.axios({
            method: 'post',
            url: '/api/companies/' + companyId + '/vehicles/report',
            data: attributes,
            responseType: 'blob'
        })

        let filename = response.headers['content-disposition']
            .split(';')[1]
            .trim()
            .split('=')[1]
            .replace(/"/g, '')

        return {
            filename: filename,
            contentType: response.headers['content-type'],
            data: response.data
        }
    },
}