<template>
    <div v-if="isMounted">
        <div class="section d-flex">
            <div class="mt">Kalender</div>
        </div>
        <calendar
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            disable-page-swipe
            is-expanded
            >
            <template v-slot:day-content="{ day, attributes }">
                <div class="flex flex-col h-full z-10 overflow-hidden">
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="flex-grow overflow-y-auto overflow-x-auto">
                    <p
                    v-for="attr in attributes"
                    :key="attr.key"
                    class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                    :class="attr.customData.class"
                    >
                    {{ attr.customData.title }}
                    </p>
                </div>
                </div>
            </template>
        </calendar>
    </div>
</template>

<script>
// import VCalendar from 'v-calendar'
import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import VehicleApi from '@/api/vehicle'
import CompanyApi from '@/api/company'
import Toaster from '@/utils/toaster'
import DataUtils from '@/utils/data-utils'
import { zones } from 'tzdata';
import { DateTime } from 'luxon'

const month = new Date().getMonth();
const year = new Date().getFullYear();

export default {
    components: {
        Calendar,
        // DatePicker,
    },

    data: function () {
        return {
            item: {
                systemOfUnits: 'metric',
                currencyCode: 'EUR',
                isAutoRecordingEnabled: true,
                isRecordTrackEnabled: true,
                isSaveTrackEnabled: true,
            },
            timeZones: [],
            isMounted: false,
            isBusy: false,
            isTimeZoneEnabled: false,
            selectedSubscription: null,
            subscriptions: [],
            systemOfUnits: [
                { value: 'metric', text: this.$t('systemOfUnits.metric') },
                { value: 'imperial', text: this.$t('systemOfUnits.imperial') },
                { value: 'usa', text: this.$t('systemOfUnits.usa') },
            ],
            onOff: [
                { value: true, text: this.$t('option.on') },
                { value: false, text: this.$t('option.off') },
            ],
            currencyCodes: [
                'GBP',
                'EUR',
                'CHF',
                'USD',
            ],
            masks: {
                weekdays: 'WWW',
            },
            attributes: [
                {
                key: 1,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 1),
                },
                {
                key: 2,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 2),
                },
                {
                key: 3,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 5),
                },
                {
                key: 4,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 5),
                },
                {
                key: 4,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 7),
                },
                {
                key: 5,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 11),
                },
                {
                key: 6,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: { months: 5, ordinalWeekdays: { 2: 1 } },
                },
                {
                key: 7,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 22),
                },
                {
                key: 8,
                customData: {
                    title: '-',
                    class: 'bg-blue-500 text-white',
                },
                dates: new Date(year, month, 25),
                },
            ],
        };            
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        subscriptionItems() {
            let items = [
                { value: null, text: this.$t('view.vehicle.settings.subscription.none') }
            ]
            if (this.subscriptions) {
                items = [
                    ...items,
                    ...this.subscriptions
                        .filter(subscription => {
                            if (this.item.subscriptions && this.item.subscriptions.length > 0) {
                                if (subscription.id == this.item.subscriptions[0].id) {
                                    return true
                                }
                            }
                            return subscription.targetCount < subscription.totalQuantity
                        })
                        .map(subscription => {
                            return {
                                value: subscription.id,
                                text: this.formatSubscription(subscription)
                            }
                        })
                ]
            }
            return items
        },
        isNewItem() {
            return this.vehicleId === undefined
        },
    },

    methods: {
        formatSubscription(subscription) {
            let text = subscription.subscriptionPlan.name
            text += ' (' + subscription.targetCount + " / " + subscription.totalQuantity + ")"
            return text
        },
        async onSaveClicked() {
            this.isBusy = true
            try {
                const attributes = {
                    licensePlate: this.item.licensePlate,
                    timeZone: this.item.timeZone,
                    systemOfUnits: this.item.systemOfUnits,
                    currencyCode: this.item.currencyCode,
                    subscriptions: this.selectedSubscription ? [this.selectedSubscription] : [],
                    isAutoRecordingEnabled: this.item.isAutoRecordingEnabled,
                    isRecordTrackEnabled: this.item.isRecordTrackEnabled,
                    isSaveTrackEnabled: this.item.isSaveTrackEnabled,
                    sid: this.item.sid,
                }
                // let attributes = {
                //     ...this.item,
                //     subscriptions: this.selectedSubscription ? [this.selectedSubscription] : [],
                // }
                DataUtils.setClientTimestamps(attributes)
                let result = undefined

                if (this.isNewItem) {
                    result = await CompanyApi.addVehicle(
                        this.company.id,
                        attributes
                    )
                } else {
                    result = await VehicleApi.updateVehicle(
                        this.vehicleId,
                        attributes
                    )
                }
                this.item = {
                    id: result.data.id,
                    ...result.data.attributes
                }
                Toaster.showSuccess(this, {
                    title: 'Hinweis',
                    message: `Ihre Änderungen wurden gespeichert`
                })
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        async onDeleteClicked() {
            //TODO
        }
    },

    mounted: async function() {
        this.isBusy = true
        try {
            this.timeZones = Object.entries(zones)
                .filter(([zoneName, v]) => Array.isArray(v))
                .map(([zoneName, v]) => zoneName)
                .filter(tz => DateTime.local().setZone(tz).isValid)
            const subscriptions = await CompanyApi.getSubscriptions(this.company.id)            
            this.subscriptions = subscriptions.data.map(item => {
                return {
                    id: item.id,
                    ...item.attributes
                }
            })

            if (!this.isNewItem) {
                const vehicle = await VehicleApi.getVehicle(
                    this.$route.params.vehicleId
                )
                this.item = {
                    id: vehicle.data.id,
                    ...vehicle.data.attributes
                }
                if (this.item.subscriptions && this.item.subscriptions.length > 0) {
                    this.selectedSubscription = this.item.subscriptions[0].id // maybe later there will be more licenses / verhicle
                } else {
                    this.selectedSubscription = null
                }
            }
            this.isTimeZoneEnabled = !this.item.timeZone
            this.isBusy = false
        } catch (error) {
            Toaster.showError(this, error)
        }
        this.isMounted = true
    },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.custom-calendar.vc-container.vc-weekday {
    background-color: red;
}

.bg-red-600 {
    background-color: rgb(229, 62, 62);
}

.bg-blue-500 {
    background-color: rgb(66, 153, 225);
}

.bg-teal-500 {
    background-color: rgb(56, 178, 172);
}

.bg-pink-500 {
    background-color: rgb(237, 100, 166);
}

.bg-indigo-500 {
    background-color: rgb(102, 126, 234);;
}

 /deep/ .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
 
/deep/ .vc-weeks {
    padding: 0;
  }

/deep/ .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  /deep/ .vc-day-dots {
    margin-bottom: 5px;
  }

/deep/ .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    border: 1px solid #b8c2cc;
    background-color: white;
}

/deep/ .vc-day.weekday-1 {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: #eff8ff;
}

/deep/ .vc-day.weekday-7 {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: #eff8ff;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  /* background-color: red; */
  & .vc-header {
    /* background-color: #f1f5f8; */
  background-color: green;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>