import Vue from 'vue'

export default {
    subscribe: async function(companyId, planId, successUrl, cancelUrl) {
        let result = await Vue.axios.post('/api/subscriptions', {
            data: {
                type: 'subscriptionInfo',
                attributes: {
                    companyId: companyId,
                    planId: planId,
                    quantity: 1,
                    adjustableQuantity: true,
                    successUrl: successUrl,
                    cancelUrl: cancelUrl,
                }
            }
        })
        return result.data
    }
}