<template>
  <div class="account">
    <b-nav-item-dropdown right>
      <template v-slot:button-content>
        <div style="float: left;">
        <div style="display: flex;">
          <div class="info text-right d-none d-md-block">
            <div class="username">{{ userName }}</div>
            <!-- <div class="username">{{ userEMail }}</div> -->
            <!-- <div class="company">{{ companyName }}</div> -->
          </div>
          <div class="d-md-none">
                <b-icon icon="person-fill"></b-icon>
          </div>
          <!-- <div class="avatar">
            <img src="../assets/avatar.png" class="avatar" />
          </div> -->
        </div>
        </div>
      </template>
      <!-- <b-dropdown-item href="#">{{ $t('navigation-bar.profile') }}</b-dropdown-item> -->
      <!-- <b-dropdown-group id="dropdown-group-1" header="Firmen"> -->
      <b-dropdown-item @click="onProfileSettingsClicked()">{{ $t('navigationbar.profileSettings') }}</b-dropdown-item>
      <b-dropdown-item v-if="isCreateCompanyAvailable" @click="onCreateCompanyClicked()">Firma anlegen</b-dropdown-item>
      <!-- <b-dropdown-item @click="onSelectionListsClicked()">{{ $t('navigationbar.selectionlists') }}</b-dropdown-item> -->
      <!-- <b-dropdown-item @click="logout()">{{ $t('navigationbar.lists') }}</b-dropdown-item> -->
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="logout()">{{ $t('navigationbar.logout') }}</b-dropdown-item>
<!-- </b-dropdown-group> -->
    </b-nav-item-dropdown>
  </div>
</template>

<script>
export default {
  name: "AccountDropdown",
  methods: {
    isMenuItemSelected(path) {
      return this.$route.path.startsWith(path)
    },
    async setCompany(company) {
      await this.$store.dispatch('setCompany', company.id)
      this.$router.push({name: 'Vehicles'}).catch(()=>{})
    },
    onProfileSettingsClicked: function() {
      this.navigateToPath('/user/profile')
    },
    onCreateCompanyClicked: function() {
      this.navigateToPath('/setup')
    },
    logout: function() {
      this.$store.dispatch('Auth/logout')
    },
    navigateToPath: function(path) {
      if (!this.isMenuItemSelected(path)) {
        this.$router.replace({path: path})
      }
    }
  },
  computed: {
    userName() {
      return this.$store.state.User.displayName
    },
    userEMail() {
      return this.$store.getters.userInfo.email
    },    
    availableCompanies() {
      return this.$store.state.User.companies
    },
    isCreateCompanyAvailable() {
      return !this.$store.getters['User/ownedCompany']
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .username {
} */

.info {
  margin-right: 10px;
  /* background-color: #f00; */
}

.company {
  font-size: 90%;
}

.avatar {
  width: 48px;
  height: 48px;
}
</style>
