<template>
  <div>
    <router-link :to="to" class="list-group-item list-group-item-action">
        <div class="d-flex w-100 justify-content-start align-items-center">
            <span class="fa fa-tasks fa-fw mr-1"></span>
            <span class="menu-collapsed d-flex align-items-center" :class="{'selected' : selected}"><slot/></span>
        </div>
    </router-link>

      
  </div>
</template>

<script>
export default {
    props: {
        to: { type: String, required: true },
        selected: { type: Boolean, required: false },
    },
    setup(props) {
    }
}
</script>

<style>
.list-group-item {
    border: none;
    color: #222;
    padding: 5px 0px 5px 20px;
    background-color: transparent;
}

.selected {
    font-weight: bold;
    color: black;
}
</style>