<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.invitations.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table hover select-mode="single" :items="items" :fields="fields">
                        <template #cell(name)="data">
                            {{ data.item.name }}
                        </template>
                        <template #cell(email)="data">
                            {{ data.item.email }}
                        </template>
                        <template #cell(roles)="data">
                            {{ formatRoles([data.item.roleId]) }}
                        </template>
                        <template #cell(actions)="data">
                            <div class="d-flex" style="gap: 5px;">
                                <!-- <b-button variant="outline-primary" @click="onReinviteClicked(data.item.id)">{{$t('view.invitations.reinvite')}}</b-button> -->
                                <b-button variant="outline-danger" @click="onDeleteInvitationClicked(data.item.id)">{{$t('view.invitations.delete')}}</b-button>
                            </div>
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <!-- <b-overlay :show="isLoading" no-wrap opacity="0.5"></b-overlay> -->
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import InvitationApi from '@/api/invitation'
import DisplayUtils from '@/utils/display-utils'
import Toaster from '@/utils/toaster'

export default {
    components: {
    },

    data: function () {
        return {
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            fields: [
                { key: 'name', label: this.$t('view.invitations.name')},
                { key: 'email', label: this.$t('view.invitations.email') },
                { key: 'roles', label: this.$t('view.invitations.role') },
                { key: 'actions', label: this.$t('view.invitations.action') },
            ],
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
    },

    watch: {
        async company() {
            await this.reloadItems();
        }
    },

    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        async onDeleteInvitationClicked(invitationId) {
            this.isBusy = true
            try {
                await InvitationApi.deleteInvitation(invitationId)
                await this.reloadItems()
            } catch (error) {
                Toaster.showError(this, error, {
                    title: `Einladung löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        async onReinviteClicked(invitationId) {
            this.isBusy = true
            try {
                await InvitationApi.reinvate(invitationId)
                await this.reloadItems()
            } catch (error) {
                Toaster.showError(this, error, {
                    title: `Erneut einladen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                const result = await CompanyApi.getInvitations(this.company.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset
                })

                if (result.data) {
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        },
        formatRoles: function(roles) {
            return DisplayUtils.formatRoles(this, roles)
        }
    },

    mounted: async function() {
        this.reloadItems()
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>