<template>
<page-layout :categories="categories">
    <template v-slot:header_large>
        <div class="title">
            <router-link :to="{ name: 'Vehicles' }" class="view-back-link d-flex">
                <span class="material-symbols-outlined">navigate_before</span>&nbsp;{{ vehicle.licensePlate }}
            </router-link>
        </div>
        <!-- <vehicle-picker :vehicle="vehicle" @onVehicleChanged="onVehicleChanged"/> -->
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'
import PermissionUtils from "@/utils/permission-utils"
// import VehiclePicker from '@/components/VehiclePicker.vue'

export default {
    components: {
        PageLayout,
        // VehiclePicker,
    },

    data: function () {
        return {
            categories: []
        };
    },

    computed: {
        title() {
            return 'Fahrzeug'
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicle() {
            return this.$store.state.vehicle
        },
        vehicles() {
            return this.$store.state.vehicles
        },
        isTripsMenuEnabled() {
            return PermissionUtils.isAuthorizedInVehicle('vehicle:trips:list', this.vehicle, this.company)
        },
        isExpensesMenuEnabled() {
            return PermissionUtils.isAuthorizedInVehicle('vehicle:expenses:list', this.vehicle, this.company)
        },
        isUsersMenuEnabled() {
            return PermissionUtils.isAuthorizedInVehicle('vehicle:users:list', this.vehicle, this.company)
        },
        isSettingsMenuEnabled() {
            return PermissionUtils.isAuthorizedInVehicle('vehicle:change', this.vehicle, this.company)
        },

    },

    methods: {
        onVehicleChanged(vehicle) {
            this.$store.dispatch('setVehicle', vehicle)
        }
    },

    mounted: async function() {
        let categories = [ ]

        if (this.isTripsMenuEnabled) {
            categories.push( { id: 'trips', label: this.$t('view.vehicle.categories.trips'), target: 'VehicleTrips'} )
        }
        if (this.isExpensesMenuEnabled) {
            categories.push( { id: 'expenses', label: this.$t('view.vehicle.categories.expenses'), target: 'VehicleExpenses'} )
        }
        if (this.isUsersMenuEnabled) {
            categories.push({ id: 'users', label: this.$t('view.vehicle.categories.users'), target: 'VehicleUsers'} )
        }
        // categories.push({ id: 'calendar', label: "Kalender", target: 'VehicleCalendar'} )
        if (this.isSettingsMenuEnabled) {
            categories.push({ id: 'settings', label: this.$t('view.vehicle.categories.settings'), target: 'VehicleSettings'} )
        }

        this.categories = categories
    },
};
</script>

<style scoped>
table {
    width: 100%;
}
th {
    padding: 5px;
}
td {
    padding: 5px;
}
tr:hover {
    background-color: lightgray;
}
</style>