<template>
    <div>
        <b-container>
            <h1>404 - Seite nicht gefunden!</h1>
        </b-container>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>