<template>
    <div>
        <div class="section d-flex">
            <div class="mt">Neue Firma anlegen</div>
        </div>
        <b-container fluid class="p-0">
            <b-row><b-col>
                <b-form-group :label="'Firmenname'" label-class="label">
                    <b-form-input v-model="companyName" type="text" :disabled="isBusy"/>
                </b-form-group>
            </b-col></b-row>
            <b-row class="actions"><b-col>
                <b-button variant="success" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
                <!-- <b-button variant="danger" class="ml-auto" v-if="!isNewItem" @click="onDeleteClicked()">Löschen</b-button> -->
            </b-col></b-row>
        </b-container>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import UserApi from '@/api/user'
import Toaster from '@/utils/toaster'

export default {
    components: {
    },

    data: function () {
        return {
            isBusy: false,
            companyName: '',
        };
    },

    computed: {
    },

    watch: {
    },

    methods: {
        onSaveClicked: async function() {
            try {
                this.isBusy = true
            //     DataUtils.setClientTimestamps(this.item)

                let companies = await UserApi.getCompanies()
                let company = companies.find(company => {
                    return company.roles.includes("company_owner")
                })

                if (company == null) {
                    await CompanyApi.addCompany({
                        name: this.companyName,
                        email: this.$store.state.User.userInfo.email,
                    })
                    companies = await UserApi.getCompanies()
                    company = companies.find(company => {
                        return company.roles.includes("company_owner")
                    })
                } else {
                    await CompanyApi.updateCompany(company.id, {
                        name: this.companyName,
                        email: company.email,
                    })
                }

                this.$store.dispatch('User/updateCompanies')
                await this.$store.dispatch("setCompany", company.id);
                this.$router.push({name: 'Company', params: { companyId: company.id }})
            //     Toaster.showSuccess(this, {
            //         title: 'Hinweis',
            //         message: `Ihre Änderungen wurden gespeichert`
            //     })
                this.isBusy = false
            } catch(error) {
                this.isBusy = false
                console.log(error)
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
        }
    },

    mounted: async function() {
    },
};
</script>

<style scoped>

</style>