import Vue from 'vue'
import VueRouter from 'vue-router'

import User from '../views/User/Index.vue'
import UserProfile from '../views/User/Profile.vue'

// import Company from '../views/Company/Index.vue'

import UserSelectionListsIndex from '../views/User/SelectionLists/Index.vue'
import UserSelectionListDrivers from '../views/User/SelectionLists/Drivers.vue'
import UserSelectionListContacts from '../views/User/SelectionLists/Contacts.vue'
import UserSelectionListReasons from '../views/User/SelectionLists/Reasons.vue'

import PageNotFound from '../views/PageNotFound.vue'

import Main from '../views/Main.vue'
import SetupScreen from '../views/SetupScreen.vue'
import CreateCompany from '../views/CreateCompany.vue'

import companyRoutes from './company'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        // redirect: { name: 'Company' },
        beforeEnter: async (to, from, next) => {
          if (store.state.User.companies.length == 0) {
              next('/setup')
              return
          }
          if (localStorage.companyId != null) {
            next({
              name: "Company",
              params: {
                companyId: localStorage.companyId
              }
            })
            return
          }
          let company = store.state.User.companies[0]
          next({
            name: "Company",
            params: {
              companyId: company.id
            }
        })
        },
      },
      {
        path: 'user', component: User, children: [
          { path: '/', redirect: {name: 'UserProfile'} },
          { path: 'profile', name: 'UserProfile', component: UserProfile, meta: { category: 'profile' } },
          // SelectionLists
          {
            path: 'selectionlists',
            name: 'UserSelectionLists',
            redirect: {name: 'UserSelectionListDrivers'},
            component: UserSelectionListsIndex,
            meta: { menuId: "user.selectionLists" },
            children: [
              { path: 'drivers', name: 'UserSelectionListDrivers', component: UserSelectionListDrivers, meta: { menuId: 'user.selectionLists', category: 'drivers' } },
              { path: 'contacts', name: 'UserSelectionListContacts', component: UserSelectionListContacts, meta: { menuId: 'user.selectionLists', category: 'contacts' } },
              { path: 'reasons', name: 'UserSelectionListReasons', component: UserSelectionListReasons, meta: { menuId: 'user.selectionLists', category: 'reasons' } },
            ]
          },
        ],
      },      
      {
        path: 'setup',
        component: SetupScreen,
        meta: {
          menuId: 'setup'
        },
        beforeEnter: async (to, from, next) => {
          let company = store.getters['User/ownedCompany']
          if (company) {
            next({
              name: "Company",
              params: {
                companyId: company.id
              }
            })
            return
          }
          next()
        }
      },
      {
        path: 'create-company',
        name: 'CreateCompany',
        component: CreateCompany,
        beforeEnter: async (to, from, next) => {
          let company = store.getters['User/ownedCompany']
          if (company) {
            next({
              name: "Company",
              params: {
                companyId: company.id
              }
            })
            return
          }
          next()
        }
      },
      ...companyRoutes,
    ]
  },  // {
  // ...companyRoutes,
  // {
  //   path: '/company', component: Company, children: [
  //     // { path: '/', name: 'Main', component: Driverslog },
  //     { path: '/', redirect: {name: 'Vehicles'} },
  //     { path: '/invitations', name: 'Invitations', component: Invitations },

  //     // Verhicles
  //     { path: 'vehicles', name: 'Vehicles', component: Vehicles },
  //     // { path: 'vehicles/add', name: 'VehicleAdd', component: AddVehicle, meta: { category: 'index' } },
  //     {
  //       path: 'vehicles/:vehicleId', name: 'vehicle', component: VehicleIndex, children: [
  //         { path: 'trips', name: 'VehicleTrips', component: Trips, meta: { category: 'trips' } },
  //         { path: 'expenses', name: 'VehicleExpenses', component: Expenses, meta: { category: 'expenses' } },
  //         { path: 'users', name: 'VehicleUsers', component: VehicleUsers, meta: { category: 'users' } },
  //         { path: 'users/add', name: 'VehicleUserAdd', component: VehicleUserAdd, meta: { category: 'users' } },
  //         { path: 'users/:userId', name: 'VehicleUserEdit', component: VehicleUserEdit, meta: { category: 'users' } },
  //         { path: 'calendar', name: 'VehicleCalendar', component: VehicleCalendar, meta: { category: 'calendar' } },
  //         { path: 'settings', name: 'VehicleSettings', component: VehicleSettings, meta: { category: 'settings' } },
  //       ]
  //     },

  //     // Reports
  //     { path: 'report-templates', name: 'ReportTemplates', component: ReportTemplates },

  //     // SelectionLists
  //     {
  //       path: 'selectionlists', name: 'CompanySelectionLists', redirect: {name: 'CompanySelectionListTags'}, component: CompanySelectionListsIndex, children: [
  //         { path: 'tags', name: 'CompanySelectionListTags', component: CompanySelectionListTags, meta: { category: 'tags' } },
  //       ]
  //     },

  //     // Employees
  //     {
  //       path: 'employees', name: 'Employees', redirect: {name: 'EmployeesList'}, component: EmployeesIndex, children: [
  //         { path: 'list', name: 'EmployeesList', component: Employees, meta: { category: 'list' } },
  //         { path: 'invitations', name: 'EmployeesInvitations', component: EmployeesInvitations, meta: { category: 'invitations' } },
  //       ]
  //     },

  //     // Settings
  //     {
  //       path: 'settings', name: 'Settings', redirect: {name: 'SettingsGeneral'}, component: SettingsIndex, children: [
  //         { path: 'general', name: 'SettingsGeneral', component: SettingsGeneral, meta: { category: 'general' } },
  //         { path: 'billing', name: 'SettingsBilling', component: SettingsBilling, meta: { category: 'billing' } },
  //       ]
  //     },

  //     // { path: '/company', name: 'Company', component: Company, children: [
  //     //     { path: 'settings', name: 'CompanySettings', component: CompanySettings },
  //     //     { path: 'users', name: 'CompanyUsers', component: CompanyUsers },
  //     //     { path: 'vehicle', name: 'CompanyVehicles', component: CompanyVehicles },
  //     // ]},
  //     { path: 'settings', name: 'Settings', component: SettingsGeneral },
  //   ]
  // },
  // { path: '/', redirect: {name: 'Vehicles'} },  // {
  
  { path: '*', component: PageNotFound },  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   let invitations = store.getters['User/invitations']
//   if (to.name !== 'Invitations' && invitations.length > 0) {
//     next({name: 'Invitations'})
//   } else {
//     next()
//   }
// })
export default router
