<template>
  <div id="company">
    <div class="d-flex">
      <!-- <div class="d-none d-md-block">
        <Sidebar/>
      </div> -->
      <div class="flex-fill">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// import Sidebar from "@/components/sidebar/Sidebar.vue";

export default {
  name: "Profile",
  components: {
    // Sidebar,
  },
  computed: {
  },
  watch: {
  },

  methods: {
    updateMainMenu() {
      // console.log(state.company)
      let menu = []
      //   if (state.company && state.company.scopes) {
      //   if (state.company.scopes.includes('vehicle:list')) {
      menu.push({
        icon: 'account_circle',
        label: this.$t('user.menu.profile'),
        path: '/user/profile'
      })
      //   }
      menu.push({
        icon: 'list',
        label: this.$t('user.menu.selectionLists'),
        path: '/user/selectionlists'
      })
      this.$store.dispatch('setMainMenu', menu)
    }
  },
  mounted: function () {
    this.updateMainMenu()
  }
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<!--



<template>
<page-layout :categories="categories" class="px-4">
    <template v-slot:header_large>
        <div class="title">Profileinstellungen</div>
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'

export default {
  components: {
    PageLayout,
  },

  data: function () {
    return {
      categories: [
          { id: 'profile', label: 'Profil', target: 'UserProfile'},
          { id: 'drivers', label: 'Fahrer', target: 'UserSelectionListDrivers'},
          { id: 'contacts', label: 'Kontakte', target: 'UserSelectionListContacts'},
          { id: 'reasons', label: 'Reisezwecke', target: 'UserSelectionListReasons'},
      ]
    };
  },

  computed: {
    title() {
      return 'Profileinstellungen'
    },
  },

  methods: {
    updateMainMenu() {
        let menu = null
        this.$store.dispatch('setMainMenu', menu)
    }
  },
  mounted: function() {
    this.updateMainMenu()
  }
};
</script>

<style scoped>
table {
    width: 100%;
}
th {
    padding: 5px;
}
td {
    padding: 5px;
}
tr:hover {
    background-color: lightgray;
}
</style>

-->