import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Keycloak from 'keycloak-js'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import { prefetchAppData } from '@/services/prefetch.service'
import vueDebounce from 'vue-debounce'
import "material-symbols/outlined.css";

import Config from './config.js'

Config.VUE_APP_PADDLE_VENDOR_ID = parseInt(Config.VUE_APP_PADDLE_VENDOR_ID)

Vue.config.productionTip = false

let keycloakOptions = {
  url: process.env.VUE_APP_KEYCLOAK_AUTH_URL || Config.VUE_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM || Config.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_RESOURCE || Config.VUE_APP_KEYCLOAK_RESOURCE,
  onLoad: 'login-required',
}

// let keycloakOptions = {
//   url: "https://home.isource.de:10010/auth",
//   realm: 'dlp-cloud',
//   clientId: 'dlp-driverslog-website-frontend',
//   onLoad: 'login-required'
// }

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup']
})

let observers = new Map()
Vue.directive('observe-intersection', {
  inserted: (el, binding) => {
    const observer = new IntersectionObserver((entries) => {
      binding.value(entries[0])
    });
    observers.set(el, observer)
    observer.observe(el)
  },
  unbind: (el, binding) => {
    const observer = observers.get(el)
    observer.disconnect()
  }
})


Vue.use(VueAxios, axios)
Vue.axios.interceptors.request.use(request => {
  if (request.url.startsWith('/api/')) {
    let accessToken = store.getters['Auth/getAccessToken']
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request;
})


let keycloak = Keycloak(keycloakOptions);
let kcLogin = keycloak.login
keycloak.login = async function(options) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString);
  const loginHint = urlParams.get('login_hint')
  if (loginHint) {
    options.loginHint = loginHint
  }
  return kcLogin(options)
}

keycloak.onTokenExpired = function() {
  keycloak.updateToken(60 * 60 * 24).then(auth => {
    let payload = {
      idToken: keycloak.idToken,
      accessToken: keycloak.token,
      logoutUrl: keycloak.createLogoutUrl()
    }
    store.dispatch("Auth/login", payload);
  })
}

keycloak.init({ onLoad: keycloakOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    let payload = {
      idToken: keycloak.idToken,
      accessToken: keycloak.token,
      logoutUrl: keycloak.createLogoutUrl()
    }
    if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
      store.dispatch("Auth/login", payload);
      (async () => {
          await prefetchAppData()
          new Vue({
            store,
            router,
            i18n,
            render: h => h(App),
            beforeCreate() {
              this.$store.commit('initialiseStore')
            },
          }).$mount('#app')
        })()
    } else {
      store.dispatch("Auth/logout");
    }
  }
})

