<template>
<page-layout :categories="categories">
    <template v-slot:header_large>
        <div class="title">{{$t('view.selectionlists.title')}}</div>
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'
import PermissionUtils from "@/utils/permission-utils"

export default {
    components: {
        PageLayout,
    },

    data: function () {
        return {
            categories: []
            // { id: 'drivers', label: 'Fahrer', target: 'CompanySelectionListDrivers'},
            // { id: 'contacts', label: 'Kontakte', target: 'CompanySelectionListContacts'},
            // { id: 'reasons', label: 'Reisezwecke', target: 'CompanySelectionListReasons'},
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        canListTags() {
            return PermissionUtils.isAuthorizedInCompany('company:selectionlists:tags:list', this.company)
        },
    },

    methods: {
    },

    mounted: async function() {
        let categories = []

        if (this.canListTags) {
            categories.push( { id: 'tags', label: this.$t('view.selectionlists.categories.tags'), target: 'CompanySelectionListTags'} )
        }

        this.categories = categories
    },
};
</script>

<style scoped>
.title {
    font-size: 1.25rem;
}
</style>