<template>
<page-layout :categories="categories">
    <template v-slot:header_large>
        <div class="title">{{$t('view.selectionlists.title')}}</div>
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'

export default {
    components: {
        PageLayout,
    },

    data: function () {
        return {
            categories: [
                { id: 'drivers', label: this.$t('view.selectionlists.categories.drivers'), target: 'UserSelectionListDrivers'},
                { id: 'contacts', label: this.$t('view.selectionlists.categories.contacts'), target: 'UserSelectionListContacts'},
                { id: 'reasons', label: this.$t('view.selectionlists.categories.reasons'), target: 'UserSelectionListReasons'},
                // { id: 'tags', label: 'Tags', target: 'UserSelectionListTags'},
            ]
        };
    },

    computed: {
    },

    methods: {
    },

    mounted: async function() {
    },
};
</script>

<style scoped>
.title {
    font-size: 1.25rem;
}
</style>