const DateTime = require('luxon').DateTime

let self = {}

self.setClientTimestamps = function(objectInfo) {
    let dateTime = DateTime.now().set({
        millisecond: 0
    })
    if (!objectInfo.clientCreatedAt) {
        objectInfo.clientCreatedAt = dateTime.toISO()
        objectInfo.clientCreatedAtTz = dateTime.zoneName
    }
    objectInfo.clientChangedAt = dateTime.toISO()
    objectInfo.clientChangedAtTz = dateTime.zoneName
    delete objectInfo.createdAt
    delete objectInfo.changedAt

}

module.exports = self

