<template>
    <div>
        <b-modal id="modal-editor" size="md" :title="editorTitle" ok-only no-stacking centered>
            <template #default="">
              <b-container fluid class="p-0">
                    <b-row><b-col>
                    <!-- <Map ref="map"
                      :mapOptions="mapOptions"
                      :markers="markers"
                      class="p-0"
                    /> -->
                  </b-col></b-row>




                  <b-form-row>
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.category')" label-class="label" label-cols="3">
                        <b-form-select size="sm" v-model="category" :options="expenseCategoryOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('fuelType')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.fuelType')" label-class="label" label-cols="3">
                        <b-form-select size="sm" v-model="productType" :options="expenseFuelTypeOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('date')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.date')" label-class="label" label-cols="3">
                        <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="expenseDate" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="expenseTime" :disabled="false"></b-form-input>
                          </b-input-group-append>
                          {{expense.expenseDateTz}}
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('place')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.place')" label-class="label" label-cols="3">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeSearchString"
                          @hit="onAddressSelected($event)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('odometer')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.odometer')" label-class="label" label-cols="3">
                        <b-form-input size="sm" type="number" v-model="expense.odometer" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('referenceValue')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.filledUp')" label-class="label" label-cols="3">
                        <b-form-select size="sm" v-model="expense.isReferenceValue" :options="expenseReferenceValueOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('grossing')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.grossing')" label-class="label" label-cols="3">
                        <b-form-input size="sm" type="number" v-model="expense.grossing" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('tax')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.tax')" label-class="label" label-cols="3">
                        <b-form-input size="sm" type="number" v-model="expense.tax" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('ppu')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.pricePerUnit')" label-class="label" label-cols="3">
                        <b-form-input size="sm" type="number" v-model="expense.pricePerUnit" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row v-if="isCellVisible('amount')">
                    <b-col>
                      <b-form-group :label="$t('view.expenses.editor.amount')" label-class="label" label-cols="3">
                        <b-form-input size="sm" type="number" v-model="expense.amount" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

<!-- 
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Ausgabentyp" label-class="label" label-cols="1">
                        <b-form-select size="sm" v-model="category" :options="expenseCategoryOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="isCellVisible('fuelType')">
                      <b-form-group label="Kraftstoff" label-class="label" >
                        <b-form-select size="sm" v-model="productType" :options="expenseFuelTypeOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col cols="7">
                      <b-form-group v-if="isCellVisible('date')" :label="'Datum (' + expense.expenseDateTz + ')'" label-class="label">
                        <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="expenseDate" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="expenseTime" :disabled="false"></b-form-input>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="isCellVisible('referenceValue')">
                      <b-form-group label="Vollgetankt" label-class="label" >
                        <b-form-select size="sm" v-model="expense.isReferenceValue" :options="expenseReferenceValueOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group v-if="isCellVisible('place')" label="Ort" label-class="label">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeSearchString"
                          @hit="onAddressSelected($event)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group v-if="isCellVisible('odometer')" label="Tacho" label-class="label">
                        <b-form-input size="sm" type="number" v-model="expense.odometer" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group v-if="isCellVisible('grossing')" label="Summe" label-class="label">
                        <b-form-input size="sm" type="number" v-model="expense.grossing" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group v-if="isCellVisible('tax')" label="MwSt." label-class="label">
                        <b-form-input size="sm" type="number" v-model="expense.tax" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group v-if="isCellVisible('ppu')" label="Preis pro Einheit" label-class="label">
                        <b-form-input size="sm" type="number" v-model="expense.pricePerUnit" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="isCellVisible('amount')">
                      <b-form-group label="Menge" label-class="label">
                        <b-form-input size="sm" type="number" v-model="expense.amount" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                  </b-form-row> -->
<!-- 
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Fahrer" label-class="label" >
                        <b-form-input v-model="trip.driverName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Fahrttyp" label-class="label" >
                        <b-form-select v-model="trip.category" :options="tripTypeOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row v-if="trip.category == 'business'">
                    <b-col>
                      <b-form-group label="Geschäftspartner" label-class="label" >
                        <b-form-input v-model="trip.contactName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Firma" label-class="label" >
                        <b-form-input v-model="trip.contactCompany" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group label="Anlass" label-class="label" >
                        <b-form-input v-model="trip.reason" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Bemerkung" label-class="label" >
                        <b-form-input v-model="trip.comment" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row> -->
              </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="expense.id" :disabled="isBusy" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

// import Map from '@/components/Map'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import DisplayUtils from '@/utils/display-utils'
import { DateTime } from 'luxon'
import _ from "underscore";

export default {
//                  | fuel | parking | garage | cleaning | tires | other
// -----------------+------+---------+--------+----------+-------+--------
// place            |  x   |    x    |    x   |     x    |   x   |   x
// odometer         |  x   |    x    |    x   |     x    |   x   |   x
// date             |  x   |    x    |    x   |     x    |   x   |   x
// grossing         |  x   |    x    |    x   |     x    |   x   |   x
// tax              |  x   |    x    |    x   |     x    |   x   |   x
// tags             |  x   |    x    |    x   |     x    |   x   |   x
// comment          |  x   |    x    |    x   |     x    |   x   |   x
// -----------------+------+---------+--------+----------+-------+--------
// fuel_type        |  x   |    -    |    -   |     -    |   -   |   -
// reference_value  |  x   |    -    |    -   |     -    |   -   |   -
// amount           |  x   |    -    |    -   |     -    |   -   |   -
// ppu              |  x   |    -    |    -   |     -    |   -   |   -



  components: {
      // Map,
      VueTypeaheadBootstrap,
  },
  props: {
      expense: { type: Object, required: true },
      isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      category: null,
      productType: null,
      mapOptions: {
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: true,
      },
      markers: [],
      
      placeSuggestions: [],
      placeSearchString: '',
      placeSelectedAddressString: '',

      expenseCategoryOptions: [
        { value: 'fuel', text: this.$t('expense.categories.fuel') },
        { value: 'parking', text: this.$t('expense.categories.parking') },
        { value: 'garage', text: this.$t('expense.categories.garage') },
        { value: 'cleaning', text: this.$t('expense.categories.cleaning') },
        { value: 'tires', text: this.$t('expense.categories.tires') },
        { value: 'other', text: this.$t('expense.categories.other') },
      ],

      expenseFuelTypeOptions: [
        { value: 'adblue', text: this.$t('expense.fuelTypes.adblue') },
        { value: 'benzin', text: this.$t('expense.fuelTypes.benzin') },
        { value: 'cng', text: this.$t('expense.fuelTypes.cng') },
        { value: 'diesel', text: this.$t('expense.fuelTypes.diesel') },
        { value: 'electro', text: this.$t('expense.fuelTypes.electro') },
        { value: 'lpg', text: this.$t('expense.fuelTypes.lpg') },
      ],

      expenseReferenceValueOptions: [
        { value: false, text: 'Nein'},
        { value: true, text: 'Ja'},
      ],

      cells: {
        fuel: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment', 'fuelType', 'referenceValue', 'amount', 'ppu'],
        parking: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment'],
        garage: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment'],
        cleaning: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment'],
        tires: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment'],
        other: ['place', 'odometer', 'date', 'grossing', 'tax', 'tags', 'comment'],
      },
    }
  },
  computed: {
    editorTitle() {
        if (this.expense.id) {
            return this.$t('view.expenses.editor.title.edit')
        } else {
            return this.$t('view.expenses.editor.title.add')
        }
    },
    place() {
      return DisplayUtils.stringFromPlace(this.expense.placeStart)
    },
  },
  methods: {
    onSaveClicked: function() {
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete')
    },
    isCellVisible: function(cellType) {
      const visibleCells = this.cells[this.expense.category]
      const isVisible = visibleCells.includes(cellType)
      return isVisible
    },
    updateMap: function() {
      let markers = []
      let mapOptions = {
        ...this.mapOptions,
        center: {
          lat: 48.45342,
          lng: 9.09484,
        },
        zoom: 3,
      }
      let coordinate = this.expense.coordinate
      if (this.expense.place) {
        if (this.expense.place.coordinate) {
          coordinate = this.expense.place.coordinate
        }
      }
      if (coordinate) {
          markers.push({
            place: this.expense.place,
            lat: coordinate.latitude,
            lng: coordinate.longitude,
          })
      }
      if (markers.length > 0) {
        let centerLat = markers.reduce((prev, current) => prev + current.lat, 0) / markers.length
        let centerLng = markers.reduce((prev, current) => prev + current.lng, 0) / markers.length
        mapOptions = {
          ...this.mapOptions,
          center: {
            lat: centerLat,
            lng: centerLng,
          },
          zoom: 18
        }
      }

      this.mapOptions = mapOptions
      this.markers = markers
    },

    async onAddressSelected(address) {
      this.placeSelectedAddressString = address.description
      const request = {
        placeId: address.place_id,
      };

      this.$refs.map.placesService.getDetails(request, (result, status) => {
        if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
          let details = {};
          result.address_components.forEach((component) => {
            component.types.forEach((type) => {
              details[type] = component.long_name;
              details[type + "short"] = component.short_name;
            });
          });

          // compile dlp address
          let address = {};
          let streetArray = [];
          if (details.route) {
            streetArray.push(details.route);
          }
          if (details.street_number) {
            streetArray.push(details.street_number);
          }
          address.street = streetArray.join(" ");
          address.postalCode = details.postal_code;
          address.city = details.locality;
          address.country = details.country;

          let place = {};
          place.address = address;
          if (result.geometry && result.geometry.location) {
            place.coordinate = {
              latitude: result.geometry.location.lat(),
              longitude: result.geometry.location.lng(),
            };
          }

          this.expense.place = place
          this.updateMap()
        }
      })
    },

    getPlaceSuggestions(queryString, callback) {
      let request = {
        input: queryString
      }

      this.$refs.map.autocompleteService.getPlacePredictions(
        request,
        (results, status) => {
          if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
            callback(results);
          } else {
            callback(null);
          }
        }
      )
    },

    updateDate() {
      this.expense.expenseDateIso = DateTime.fromISO(this.expenseDate+'T'+this.expenseTime, { zone: this.expense.expenseDateTz }).toISO()
    },

  },
  mounted: async function() {
    this.updateMap()
  },
  watch: {
    placeSearchString: _.debounce(function(queryString) {
      if (queryString == this.placeSelectedAddressString) {
        return
      }
      this.expense.place = {
        name: queryString,
        coordinate: null,
      }
      this.getPlaceSuggestions(queryString, results => {
          this.placeSuggestions = results;
      })
    }, 500),

    date: function() {
      this.updateDate()
    },

    time: function() {
      this.updateDate()
    },

    category: function() {
      this.expense.category = this.category
      if (this.category === 'fuel' && !this.expense.productType) {
        // this.expense.productType = 'benzin'
        this.productType = 'benzin'
      }
    },

    productType: function() {
        this.expense.productType = this.productType
    },

    expense: function() {
      if (this.expense.place) {
        this.placeSelectedAddressString = DisplayUtils.stringFromPlace(this.expense.place)
      } else {
        this.placeSelectedAddressString = ''
      }
      if (!this.expense.category) {
        this.expense.category = 'fuel'
      }
      this.category = this.expense.category
      this.placeSearchString = this.placeSelectedAddressString

      let dateTime = DateTime.fromISO(this.expense.expenseDateIso)
      dateTime = dateTime.setZone(this.expense.expenseDateTz)
      this.expenseDate = dateTime.toISODate()
      this.expenseTime = dateTime.toFormat('HH:mm')

      this.updateMap()
    },
  },

}
</script>

<style scoped>

</style>
