<template>
  <div class="search">
    <input v-model="searchString" type="text" class="query-field w-100" :placeholder="placeholder" />
  </div>
</template>

<script>

import _ from "underscore";

export default {
  components: {
  },
  props: {
    'placeholder': { default: null },
  },
  data: function () {
    return {
      searchString: ''
    }
  },
  computed: {
  },
  methods: {
  },
  mounted: async function () {
  },
  watch: {
    searchString: _.debounce(function (searchString) {
      this.$emit('searchStringChanged', searchString)
    }, 500)
  },
}
</script>

<style>
.query-field {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid lightgray;
}
</style>
