<template>
    <div>
        <div class="section d-flex">
            <div class="mt">Benutzer hinzufügen <span v-if="totalItems > -1">({{ totalItems }})</span></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table striped hover selectable select-mode="single" :items="getItems" @row-clicked="onRowClicked" >
                        <template #cell(firstName)="data">
                            {{ data.item.firstName }}
                        </template>
                        <template #cell(lastName)="data">
                            {{ data.item.lastName }}
                        </template>
                        <template #cell(roles)="data">
                            {{ data.item.roles }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import VehicleApi from '@/api/vehicle'

export default {
    components: {
    },

    data: function () {
        return {
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            currentPage: 1,
            limit: 50,
            totalItems: -1,
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicleId() {
            return this.$route.params.vehicleId
        },
        getItems() {
            return this.items.map((employee) => {
                return {
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    roles: employee.roles,
                };
            });
        },
    },

    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        async onRowClicked(item, index) {
            const user = this.items[index]
            const result = await VehicleApi.setUser(
                this.vehicleId,
                user.id,
                ['vehicle_user'],
            )
            this.$router.push({
                name: 'VehicleUsers',
                params: {vehicleId: this.vehicleId}
            })
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                const result = await CompanyApi.getUsers(this.company.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset
                })

                if (result.data) {
                    // console.log(`Received ${result.data.length} trips`);
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        },
        formatPlace: function(place) {
            if (place) {
                const text = place.name
                return text
            }
            return ''
        },
    },

    mounted: async function() {
        this.reloadItems();
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>
