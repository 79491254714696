<template>
    <div v-if="isMounted">
        <div class="section d-flex">
            <div class="mt">{{$t('view.user.profile.title')}}</div>
            <div class="mt ml-auto"><b-button variant="link" class="text-danger" @click="onRequestAccountDeletionClicked()">{{$t('view.user.profile.requestDeletion')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row><b-col>
                <b-form-group :label="$t('view.user.profile.firstName')" label-class="label">
                    <b-form-input v-model="item.firstName" type="text" :disabled="isBusy"/>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.user.profile.lastName')" label-class="label">
                    <b-form-input v-model="item.lastName" type="text" :disabled="isBusy"/>
                </b-form-group>
            </b-col></b-row>
            <b-row><b-col>
                <b-form-group :label="$t('view.user.profile.email')" label-class="label">
                    <b-form-input v-model="item.email" type="text" :disabled="true"/>
                </b-form-group>
            </b-col></b-row>
            <b-row class="actions"><b-col>
                <b-button variant="success" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
                <!-- <b-button variant="danger" class="ml-auto" v-if="!isNewItem" @click="onDeleteClicked()">Löschen</b-button> -->
            </b-col></b-row>
        </b-container>
    </div>
</template>

<script>
import UserApi from '@/api/user'
import Toaster from '@/utils/toaster'

export default {
    components: {
    },

    data: function () {
        return {
            item: {},
            isMounted: false,
            isBusy: false,
        };
    },

    computed: {
    },

    methods: {
        onSaveClicked: async function() {
            try {
                this.isBusy = true
                await UserApi.updateUserInfo(this.item)
                this.$store.dispatch('User/updateUserInfo')
                Toaster.showSuccess(this, {
                    title: 'Hinweis',
                    message: `Ihre Änderungen wurden gespeichert`
                })
                this.isBusy = false
            } catch(error) {
                this.isBusy = false
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
        },
        onRequestAccountDeletionClicked() {
            console.log(this.item)
            let subject = "UserId: " + this.$store.state.User.userInfo.id + "\n"
            subject += "Username: " + this.$store.state.User.userInfo.firstName + " " + this.$store.state.User.userInfo.lastName + "\n"
            subject += "Email: " + this.$store.state.User.userInfo.email + "\n"
            window.open("mailto:support@driverslogpro.com?Subject="+encodeURIComponent("Account deletion")+"&body="+encodeURIComponent(subject))
        }
    },

    mounted: async function() {
        this.item = {
            firstName: this.$store.state.User.userInfo.firstName,
            lastName: this.$store.state.User.userInfo.lastName,
            email: this.$store.state.User.userInfo.email,
        }
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>