<template>
    <multiselect 
        selectedLabel="" selectLabel="" deselectLabel=""
        :id="id"
        :options="values"
        :close-on-select="closeOnSelect"
        :allow-empty="allowEmpty"
        :searchable="searchable"
        :multiple="multiple"
        :placeholder="placeholder"

        :option-height="16"
        v-bind:value="value"
        v-on:input="valueChanged($event)"
        :custom-label="opt => labelForValue(opt)"
        @search-change="onSearchChange"
        >
        <template slot="option" slot-scope="props">
        <div class="option__desc">
            <div v-if="isSelected(props.option)" class="accessory">✓</div>
            <div v-else class="accessory">&nbsp;</div>
            <span class="option__title">{{ labelForValue(props.option) }}</span>
        </div>
        </template>
    </multiselect>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
      'id': null,
      'value': {},
      'options': {},
      'allow-empty': {default: true},
      'close-on-select': {default: true},
      'searchable': {default: false},
      'multiple': {default: false},
      'placeholder': {default: null},
  },
  data: function() {
    return {
    }
  },
  computed: {
      values() {
          return this.options.map(option => option.value)
      }
  },
  methods: {
      valueChanged(value) {
        this.$emit('input', value)
      },
      isSelected(value) {
        if (Array.isArray(this.value)) {
            return this.value.includes(value)
        }
        return value === this.value
      },
      labelForValue(value) {
          let label = this.options.find(option => option.value === value).text
          return label
      },
      onSearchChange(query) {
          this.$emit('search-change', query, this.id)
      }
  },
  mounted: async function() {
  },
  watch: {
  },

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
/* .multiselect input {
  background: red;
}
 */
/* .multiselect.invalid .multiselect__tags,
.multiselect.invalid .multiselect__tags span,
.multiselect.invalid .multiselect__tags input {
  background:red;
}
 */
.accessory {
  width: 16px;
  /* height: 20px; */
  margin-right: 5px;
  font-size: 0.8rem;
  /* background-color: green; */
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.multiselect__tags {
    border: 1px solid rgb(206, 212, 218);
    /* background: transparent; */
    /* color: red; */
    /* font-family: WorkSans-light !important; */
    /* font-size: 14px !important; */
    /* padding: 5px !important; */
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #999;
    font-size: 14px;
}

.multiselect__content {
  color: white;
  /* border-radius: 15px; */
}

.multiselect__content-wrapper {
  background-color: #64676C;
  border: 1px solid 81848A !important;
  /* border-radius: 15px !important; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* font-size: 0.8rem; */
}

.multiselect__element {
  /* height: 24px; */
  /* font-size: 0.8rem; */
}

.multiselect__option--selected {
  background-color: transparent;
  color: white;
  font-weight: 400;  
}

.multiselect__option--highlight {
  background-color: #638EE6 !important;
  /* background-color: #f00; */
}

.multiselect__tag {
    /* border: 1px solid rgba(60, 60, 60, 0.26) !important; */
    background-color: #ddd;
    color: #444;
    margin-bottom: 0px !important;
    margin-right: 5px !important;
    min-height: 20px !important;
    min-width: 25px !important;
}
/* .multiselect__placeholder {
    display: inline-block !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
} */
/* .label {
    font-size: 0.8em;
} */
</style>
