const store = require('../store/index').default

let self = {}

self.isAuthorizedInCompany = function(scope, company) {
    return company.scopes.includes(scope)
}

self.isAuthorizedInVehicle = function(scope, vehicle, company) {
    if (self.isAuthorizedInCompany(scope, company)) {
        return true
    }

    // if (!vehicle) {
    //     vehicle = store.getters.currentVehicle
    // }

    if (!vehicle.scopes) {
        return false
    }
    
    return vehicle.scopes.includes(scope)
}

module.exports = self