<template>
<div>
    <b-container v-if="invitation">
                <b-card
    title="Invitations"
    class="mb-2"
  >
    <b-card-text>
      You have been invited to company {{ invitation.companyId }}
    </b-card-text>

    <b-button @click="decline" variant="danger" :disabled="processing">Decline</b-button>
    <b-button @click="accept" variant="primary" :disabled="processing">Accept</b-button>
  </b-card>
    </b-container>
</div>
</template>

<script>
import InvitationApi from '../api/invitation'

export default {
    data: function() {
        return {
            processing: false,
            invitations: []
        }
    },
    computed: {
        invitation() {
            let invitation = null
            if (this.invitations.length > 0) {
                invitation = this.invitations[0]
            }
            return invitation
        }
    },
    methods: {
        accept: async function() {
            if (!this.processing) {
                this.processing = true
                try {
                    await InvitationApi.acceptInvitation(this.invitation.id)
                    await this.$store.dispatch('User/updateInvitations')
                    await this.$store.dispatch('User/updateCompanies')
                    this.invitations = this.$store.getters['User/invitations']
                    if (this.invitations.length == 0) {
                        this.$router.replace({name: 'Main'})
                    }
                    this.processing = false
                } catch (error) {
                    console.log(error)
                }
            }
        },
        decline: async function() {
            if (!this.processing) {
                this.processing = true
                try {
                    await InvitationApi.deleteInvitation(this.invitation.id)
                    await this.$store.dispatch('User/updateInvitations')
                    this.invitations = this.$store.getters['User/invitations']
                    if (this.invitations.length == 0) {
                        this.$router.replace({name: 'Main'})
                    }
                    this.processing = false
                } catch (error) {
                    console.log(error)
                }
            }
        }
    },
    mounted: function() {
        this.invitations = this.$store.getters['User/invitations']
    }
};
</script>

<style scoped>
</style>