import Vue from 'vue'

export default {
    async acceptInvitation(invitationId) {
        let result = await Vue.axios.post('/api/invitations/' + invitationId)
        return result
    },
    async deleteInvitation(invitationId) {
        let result = await Vue.axios.delete('/api/invitations/' + invitationId)
        return result
    },
    async reinvite(invitationId) {
        let result = await Vue.axios.post('/api/invitations/' + invitationId + '/reinvite')
        return result
    }
}
