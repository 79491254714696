import UserApi from '@/api/user'

// initial state
const state = () => ({
    userInfo: null,
    displayName: null,
    companies: null,
    invitations: null
})

// getters
const getters = {
    userInfo(state) {
        return state.userInfo
    },
    displayName(state) {
        return state.displayName
    },
    companies(state) {
        return state.companies
    },
    company: (state) => (id) => {
        return state.companies.find(company => company.id == id)
    },
    invitations(state) {
        return state.invitations
    },
    ownedCompany(state) {
        let ownedCompany = null
        if (state.companies) {
            ownedCompany = state.companies.find(company => {
                return company.roles.includes("company_owner")
            })
        }
        return ownedCompany
    }
}

// actions
const actions = {
    async updateUserInfo({ commit, rootState }) {
        if (rootState.Auth.user) {
            let userInfo = await UserApi.getUserInfo()
            commit('SET_USER_INFO', userInfo)
        }
    },
    async updateCompanies({ commit, rootState, dispatch }) {
        if (rootState.Auth.user) {
            let companies = await UserApi.getCompanies()
            companies = companies
                .filter(company => company.name)
                .sort((a, b) => {
                    let x = a.name.toLowerCase();
                    let y = b.name.toLowerCase();
                    if (x < y) {return -1;}
                    if (x > y) {return 1;}
                    return 0;
                })
            commit('SET_COMPANIES', companies)
            if (rootState.company) {
                dispatch('setCompany', rootState.company.id, {root: true})
            }
    
        }
    },
    async updateInvitations({ commit, rootState }) {
        if (rootState.Auth.user) {
            let companies = await UserApi.getInvitations()
            commit('SET_INVITATIONS', companies)
        }
    }
}

// mutations
const mutations = {
    SET_USER_INFO(state, userInfo) {
        state.userInfo = userInfo
        let components = []
        if (userInfo.firstName) {
            components.push(userInfo.firstName)
        }
        if (userInfo.lastName) {
            components.push(userInfo.lastName)
        }
        state.displayName = components.join(' ')
    },
    SET_COMPANIES(state, companies) {
        state.companies = companies
    },
    SET_INVITATIONS(state, invitations) {
        state.invitations = invitations
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}