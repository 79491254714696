<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.trips.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3" v-if="canAddTrip && selectedTripIndices.length == 0"><b-button variant="link" @click="onAddTripClicked()">{{$t('view.trips.addTrip')}}</b-button></div>
            <div class="ml-3" v-if="selectedTripIndices.length > 0">
                <b-dropdown variant="link" text="Sammelaktionen">
                    <b-dropdown-group>
                        <b-dropdown-item>{{$t('trip.categories.private')}}</b-dropdown-item>
                        <b-dropdown-item>{{$t('trip.categories.commute')}}</b-dropdown-item>
                        <b-dropdown-item>{{$t('trip.categories.honorary')}}</b-dropdown-item>
                        <b-dropdown-item>{{$t('trip.categories.family')}}</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item :disabled="!canMergeTrips" @click="onMergeTripsClicked()">{{$t('view.trips.mergeTrips')}}</b-dropdown-item>
                    </b-dropdown-group>
                </b-dropdown>
            </div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <b-table v-if="items.length > 0" hover select-mode="single" :items="items" :fields="fields" @row-clicked="onRowClicked" details-td-class="group">
                        <template slot="top-row" slot-scope="{ columns }">
                            <td :colspan="columns" class="group">{{ items[0].dateString }}</td>
                        </template>
                        <template slot="row-details" slot-scope="{ item }">
                        <div>{{ item.nextDateString }}</div>
                        </template>

                        <template #cell(select)="data">
                            <b-form-checkbox size="lg" @input="onTripSelectionChanged(data.index, $event)"></b-form-checkbox>
                        </template>
                        <template #cell(date)="data">
                            {{ formatDate(data.item.dateEndIso, data.item.dateEndTz) }}<br />
                            {{ formatDate(data.item.dateStartIso, data.item.dateStartTz) }}
                        </template>
                        <template #cell(odometer)="data">
                            {{ data.item.odometerEnd }}<br />{{
                                data.item.odometerStart
                            }}
                        </template>
                        <template #cell(place)="data">
                            {{formatPlace(data.item.placeEnd)}}<br>
                            {{formatPlace(data.item.placeStart)}}
                        </template>
                        <template #cell(contactAndReason)="data">
                            {{ formatContact(data.item)}}
                            <!-- <br v-if="formatContact(data.item).length > 0"> -->
                            <br/>
                            {{data.item.reason}}
                        </template>
                        <template #cell(driver)="data">
                            {{ formatDriver(data.item.driverName) }}
                        </template>
                        <template #cell(distance)="data">
                            {{ data.item.odometerEnd - data.item.odometerStart }}
                        </template>
                        <template #cell(category)="data">
                            {{ formatCategory(data.item.category) }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <!-- <b-overlay :show="isLoading" no-wrap opacity="0.5"></b-overlay> -->
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <TripEditor :trip="selectedItem" :isBusy="isBusy" @save="onSaveTrip" @delete="onDeleteTrip"/>
<!-- 
        <TripViewModalDialog
            :trip="selectedItem"
        /> -->
    </div>
</template>

<script>
import VehicleApi from '@/api/vehicle';
import TripApi from '@/api/trip';
import TransactionApi from '@/api/transaction';
import Toaster from "@/utils/toaster"
import TripEditor from "@/components/TripEditor"
import DisplayUtils from "@/utils/display-utils";
import DataUtils from '@/utils/data-utils'
import PermissionUtils from "@/utils/permission-utils"
import { DateTime } from 'luxon'

export default {
    props: ["vehicleId"],
    components: {
        TripEditor,
    },
    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            selectedItem: {},
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            selectedTripIndices: [],
            fields: [
                // { key: 'select', label: '' },
                { key: 'date', label: this.$t('view.trips.date') },
                { key: 'odometer', label: 'Kilometerstand', thClass: 'text-right', tdClass: 'text-right' },
                { key: 'distance', label: this.$t('view.trips.distance'), thClass: 'text-right', tdClass: 'text-right' },
                { key: 'place', label: this.$t('view.trips.place') },
                { key: 'contactAndReason', label: this.$t('view.trips.contactAndReason') },
                { key: 'driver', label: this.$t('view.trips.driver') },
                { key: 'category', label: this.$t('view.trips.category') },
            ]
        };
    },
    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        vehicle() {
            return this.$store.state.vehicle
        },
        canMergeTrips() {
            if (this.selectedTripIndices.length <2) {
                return false
            }
            var nextIndex = -1
            let mergeable = true
            this.selectedTripIndices.forEach(index => {
                if (nextIndex != -1 && index != nextIndex) {
                    mergeable = false
                }
                nextIndex = index + 1
            })
            return mergeable
        },
        canAddTrip() {
            return PermissionUtils.isAuthorizedInVehicle('vehicle:trips:add', this.vehicle, this.company)
        }
    },
    watch: {
        vehicle: function () {
            this.reloadItems()
        }
    },
    methods: {
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddTripClicked() {
            let dateTime = DateTime.now().set({
                second: 0,
            })

            this.selectedItem = {
                dateStartIso: dateTime.toISO(),
                dateStartTz: dateTime.zoneName,
                dateEndIso: dateTime.toISO(),
                dateEndTz: dateTime.zoneName,
                odometerStart: this.vehicle.odometer,
                odometerEnd: this.vehicle.odometer,
                placeStart: this.vehicle.place,
                category: null
            };
            this.$bvModal.show("modal-editor");
        },

        async onSaveTrip() {
            this.isBusy = true
            try {
                let attributes = {
                    category: this.selectedItem.category,
                    comment: this.selectedItem.comment,
                    contactCompany: this.selectedItem.contactCompany,
                    contactName: this.selectedItem.contactName,
                    coordinateEnd: this.selectedItem.coordinateEnd,
                    coordinateStart: this.selectedItem.coordinateStart,
                    dateEndIso: this.selectedItem.dateEndIso,
                    dateEndTz: this.selectedItem.dateEndTz,
                    dateStartIso: this.selectedItem.dateStartIso,
                    dateStartTz: this.selectedItem.dateStartTz,
                    driverName: this.selectedItem.driverName,
                    id: this.selectedItem.id,
                    odometerEnd: this.selectedItem.odometerEnd,
                    odometerStart: this.selectedItem.odometerStart,
                    placeEnd: this.selectedItem.placeEnd,
                    placeStart: this.selectedItem.placeStart,
                    reason: this.selectedItem.reason,
                    sid: this.selectedItem.sid,
                }
                DataUtils.setClientTimestamps(attributes)
                if (this.selectedItem.id) {
                    await TripApi.updateTrip(
                        this.selectedItem.id,
                        attributes
                    )
                } else {
                    await VehicleApi.addTrip(
                        this.vehicle.id,
                        attributes
                    )
                }
                await this.reloadItems()
                await this.$store.dispatch('refreshVehicles')
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Speichern fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        
        async onDeleteTrip() {
            this.isBusy = true
            try {
                if (this.selectedItem.id) {
                    await TripApi.deleteTrip(this.selectedItem.id)
                    this.$bvModal.hide('modal-editor')
                    await this.reloadItems()
                }
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        onRowClicked(item, index) {
            this.selectedItem = { ...this.items[index] };
            this.$bvModal.show("modal-editor");
        },
        onRowSelected(items, index) {
            if (items.length > 0) {
                this.selectedItem = {
                    ...this.items[index]
                }
            } else {
                this.selectedItem = null
            }
            this.$bvModal.show('modal-trip-view')
        },
        onTripSelectionChanged(tripIndex, value) {
            if (value) {
                this.selectedTripIndices.push(tripIndex)
                this.selectedTripIndices.sort()
            } else {
                this.selectedTripIndices = this.selectedTripIndices.filter(index => index != tripIndex)
            }
        },
        async onMergeTripsClicked() {
            let tripIdsToMerge = this.selectedTripIndices.map(index => this.items[index].id)
            if (tripIdsToMerge.length > 1) {
                this.isBusy = true
                try {
                    await TransactionApi.mergeTrips(tripIdsToMerge)
                    this.reloadItems()
                } catch(error) {
                    Toaster.showError(this, error, {
                        title: `Zusammenführen fehlgeschlagen`
                    })
                }
                this.isBusy = false
            }
        },
        formatDate(date, timeZone) {
            return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.TIME_SIMPLE)
            // return DateTime.fromISO(date).setZone(timeZone).toLocaleString(DateTime.DATETIME_SHORT)
        },
        formatPlace(place) {
            return DisplayUtils.stringFromPlace(place, '-')
        },
        formatCategory(category) {
            if (category) {
                return this.$t('trip.categories.' + category)
            }
            return this.$t('trip.categories.empty')
        },
        formatContact(item) {
            let string = ''
            let components = []
            if (item.contactName) {
                components.push(item.contactName)
            }
            if (item.contactCompany) {
                components.push(item.contactCompany)
            }
            string = components.join(', ')
            return string
        },
        formatDriver(driver) {
            return driver || '-'
        },
        async reloadItems() {
            this.items = []
            this.selectedTripIndices = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (this.vehicle && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isBusy = true;
                this.isLoading = true;
                try {
                    // await new Promise(r => setTimeout(r, 2000));
                    let offset = this.items.length
                    // console.log(`Fetching trips for vehicle ${this.vehicle.id}, offset = ${offset}`);
                    const result = await VehicleApi.getTrips(this.vehicle.id, {
                        "page[limit]": this.limit,
                        "page[offset]": offset
                    })

                    // console.log(`Received ${result.data.length} trips`);
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                    var total = this.items.length
                    if (result.meta) {
                        if (result.meta.page) {
                            total = result.meta.page.total
                        }
                    }
                    // let lastDateString = ''
                    if (this.items.length > 0) {
                        this.items[0].dateString = DateTime.fromISO(this.items[0].dateEndIso).setZone(this.items[0].dateEndTz).toLocaleString(DateTime.DATE_SHORT)
                    }
                    this.items.forEach((item, index) => {
                        let dateString = DateTime.fromISO(item.dateEndIso).setZone(item.dateEndTz).toLocaleString(DateTime.DATE_SHORT)
                        if (this.items[index + 1] !== undefined) {
                            let nextDateString = DateTime.fromISO(this.items[index + 1].dateEndIso).setZone(this.items[index + 1].dateEndTz).toLocaleString(DateTime.DATE_SHORT)
                            if (dateString !== nextDateString) {
                                item._showDetails = true
                                item.dateString = dateString
                                item.nextDateString = nextDateString
                            }
                        }
                        // if (dateString != lastDateString) {
                        //     item._showDetails = true
                        //     item.dateString = dateString
                        //     lastDateString = dateString
                        // }
                    })
                    this.totalItems = total
                    if (result.data.length > 0) {
                        setTimeout(() => {
                            this.checkAutoappendItems()
                        }, 20)
                    }
                } catch (error) {
                    Toaster.showError(this, error);
                }
                this.isLoading = false;
                this.isBusy = false;
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        }
    },
    created: function () {},
    mounted: async function () {
        this.isBusy = true;
        try {
            await this.reloadItems();
        } catch (error) {
            Toaster.showError(this, error);
        }
        this.isBusy = false;
        this.isMounted = true;
    },
};
</script>

<style scoped>
.title {
    font-size: 2em;
}

.loading-bar {
    padding: 5px;
    background-color: #aaa;
}

/deep/ .group {
    /* background-color: #fafafa; */
    padding: 0px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #555;
    font-weight: 600;
}
</style>