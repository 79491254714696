<template>
    <div>
        <div class="section d-flex">
            <div class="mt">{{$t('view.employees.title')}} <span v-if="totalItems > -1">({{ totalItems }})</span></div>
            <div class="ml-3" v-if="canInviteEmployees"><b-button variant="link" @click="onAddEmployeeClicked()">{{$t('view.employees.invite')}}</b-button></div>
        </div>
        <b-container fluid class="p-0">
            <b-row>
                <b-col>
                    <SearchField class="w-100" :placeholder="$t('view.employees.searchfield.placeholder')" @searchStringChanged="onSearchChanged"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-table v-if="canListEmployees" hover selectable select-mode="single" :items="items" :fields="fields" @row-clicked="onRowClicked" >
                        <template #cell(firstName)="data">
                            {{ data.item.firstName }}
                        </template>
                        <template #cell(lastName)="data">
                            {{ data.item.lastName }}
                        </template>
                        <template #cell(email)="data">
                            {{ data.item.email }}
                        </template>
                        <template #cell(roles)="data">
                            {{ formatRoles(data.item.roles) }}
                        </template>
                    </b-table>
                    <div v-if="isLoading" class="loading-bar">
                        <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                    </div>
                    <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                </b-col>
            </b-row>
        </b-container>
        <EmployeeEditor :employee="selectedItem" :isBusy="isBusy" @save="onSaveEmployee" @delete="onDeleteEmployee"/>
    </div>
</template>

<script>
import CompanyApi from '@/api/company'
import Toaster from '@/utils/toaster'
import DisplayUtils from '@/utils/display-utils'
import EmployeeEditor from "@/components/EmployeeEditor.vue";
import PermissionUtils from "@/utils/permission-utils"
import SearchField from "@/components/SearchField.vue";

export default {
    components: {
        EmployeeEditor,
        SearchField,
    },

    data: function () {
        return {
            isMounted: false,
            isBusy: false,
            isLoading: false,
            items: [],
            isEndOfTableVisible: true,
            searchString: '',
            currentPage: 1,
            limit: 50,
            totalItems: -1,
            selectedItem: {},
            fields: [
                { key: 'firstName', label: this.$t('view.employees.firstName') },
                { key: 'lastName', label: this.$t('view.employees.lastName') },
                { key: 'email', label: this.$t('view.employees.email') },
                { key: 'roles', label: this.$t('view.employees.roles') },
            ],

        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        canListEmployees() {
            return PermissionUtils.isAuthorizedInCompany('company:users:list', this.company)
        },
        canChangeEmployee() {
            return PermissionUtils.isAuthorizedInCompany('company:users:change', this.company)
        },
        canInviteEmployees() {
            return PermissionUtils.isAuthorizedInCompany('company:users:invite', this.company)
        },
    },

    watch: {
        async company() {
            await this.reloadItems();
        }
    },

    methods: {
        onSearchChanged(searchString) {
            this.searchString = searchString
            this.reloadItems()
        },
        onEndOfTableVisibilityChanged(el) {
            this.isEndOfTableVisible = el.isIntersecting
            this.checkAutoappendItems()
        },
        onAddEmployeeClicked() {
            if (!this.canChangeEmployee) {
                return
            }
            this.selectedItem = {
                languageIso: 'de',
                roleId: 'company_user'
            }
            this.$bvModal.show('modal-editor')
        },
        async onSaveEmployee() {
            this.isBusy = true
            try {
                if (this.selectedItem.id) {
                    await CompanyApi.setUserRole(
                        this.company.id,
                        this.selectedItem.id,
                        this.selectedItem.roleId
                    )
                } else {
                    await CompanyApi.invite(
                        this.company.id,
                        this.selectedItem.name,
                        this.selectedItem.email,
                        this.selectedItem.roleId,
                        this.selectedItem.languageIso
                    )
                }
                await this.reloadItems()
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                if (error.isAxiosError && error.response.status === 409) {
                    Toaster.showErrorMessage(this, 'Eine Einladung mit dieser E-Mail-Adresse existiert bereits.', {
                        title: `Einladung fehlgeschlagen`
                    })
                } else {
                    Toaster.showError(this, error, {
                        title: `Einladung fehlgeschlagen`
                    })
                }
            }
            this.isBusy = false
        },
        async onDeleteEmployee() {
            this.isBusy = true
            try {
                await CompanyApi.deleteUser(
                    this.company.id,
                    this.selectedItem.id,
                )
                this.items = this.items.filter(item => item.id !== this.selectedItem.id)
                this.$bvModal.hide('modal-editor')
            } catch(error) {
                Toaster.showError(this, error, {
                    title: `Löschen fehlgeschlagen`
                })
            }
            this.isBusy = false
        },
        onRowClicked(item, index) {
            this.selectedItem = {
                ...this.items[index],
                roleId: this.items[index].roles[0]
            }
            this.$bvModal.show('modal-editor')
        },
        async reloadItems() {
            this.items = []
            this.totalItems = -1
            await this.appendItems()
        },
        async appendItems() {
            if (!this.canListEmployees) {
                return
            }
            if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
                this.isLoading = true;
                // await new Promise(r => setTimeout(r, 2000));
                let offset = this.items.length
                const result = await CompanyApi.getUsers(this.company.id, {
                    "page[limit]": this.limit,
                    "page[offset]": offset,
                    "filter": this.searchString,
                })
                if (result.data) {
                    result.data.forEach(item => {
                        this.items.push({
                            id: item.id,
                            ...item.attributes
                        })
                    })
                }
                var total = this.items.length
                if (result.meta) {
                    if (result.meta.page) {
                        total = result.meta.page.total
                    }
                }
                this.totalItems = total
                this.isLoading = false;
                if (result.data.length > 0) {
                    setTimeout(() => {
                        this.checkAutoappendItems()
                    }, 20)
                }
            }
        },
        async checkAutoappendItems() {
            if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
                return
            }
            await this.appendItems()
        },
        formatPlace: function(place) {
            if (place) {
                const text = place.name
                return text
            }
            return ''
        },
        formatRoles: function(roles) {
            return DisplayUtils.formatRoles(this, roles)
        }
    },

    mounted: async function() {
        this.reloadItems();
        this.isMounted = true
    },
};
</script>

<style scoped>

</style>
