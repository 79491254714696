<template>
    <div>
        <b-modal id="modal-editor" size="lg" :title="editorTitle" ok-only no-stacking centered>
            <template #default="">
              <b-container fluid class="p-0">
                    <b-row><b-col>
                    <TripMap ref="map"
                      :mapOptions="mapOptions"
                      :markers="markers"
                      :track="track"
                      class="map"
                    />
                  </b-col></b-row>

                  <b-form-row class="mt-3">
                    <b-col cols="4">
                      <b-form-group :label="$t('view.trips.editor.dateStart') +' (' + trip.dateStartTz + ')'" label-class="label">
                          <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="dateStart" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="timeStart" :disabled="false"></b-form-input>
                          </b-input-group-append>
                          </b-input-group>
                          </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.odometerStart')" label-class="label" >
                        <b-form-input size="sm" type="number" v-model="trip.odometerStart" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group :label="$t('view.trips.editor.dateEnd') +'(' + trip.dateEndTz + ')'" label-class="label">
                          <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="dateEnd" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="timeEnd" :disabled="false"></b-form-input>
                          </b-input-group-append>
                          </b-input-group>
                          </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.odometerEnd')" label-class="label">
                        <b-form-input size="sm" type="number" v-model="trip.odometerEnd" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col cols="6">
                      <b-form-group :label="$t('view.trips.editor.placeStart')" label-class="label">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeStartSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeStartSearchString"
                          @hit="onAddressSelected('start', $event)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group :label="$t('view.trips.editor.placeEnd')" label-class="label">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeEndSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeEndSearchString"
                          @hit="onAddressSelected('end', $event)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <!-- <b-form-row class="mt-3">
                    <b-col cols="4">
                      <b-form-group :label="'Ankunftszeit (' + trip.dateEndTz + ')'" label-class="label">
                          <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="dateEnd" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="timeEnd" :disabled="false"></b-form-input>
                          </b-input-group-append>
                          </b-input-group>
                          </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Tacho" label-class="label">
                        <b-form-input size="sm" type="number" v-model="trip.odometerEnd" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Ankunftsort" label-class="label">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeEndSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeEndSearchString"
                          @hit="onAddressSelected('end', $event)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col cols="4">
                      <b-form-group :label="'Abfahrtszeit (' + trip.dateStartTz + ')'" label-class="label">
                          <b-input-group>
                          <b-form-input class="mr-2" size="sm" type="date" v-model="dateStart" :disabled="false"></b-form-input>
                          <b-input-group-append>
                              <b-form-input size="sm" type="time" v-model="timeStart" :disabled="false"></b-form-input>
                          </b-input-group-append>
                          </b-input-group>
                          </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Tacho" label-class="label" >
                        <b-form-input size="sm" type="number" v-model="trip.odometerStart" :number="true"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Abfahrtsort" label-class="label">
                        <vue-typeahead-bootstrap
                          size="sm" 
                          :data="placeStartSuggestions"
                          :disabled="isBusy"
                          :serializer="address => address.description"
                          v-model="placeStartSearchString"
                          @hit="onAddressSelected('start', $event)"
                        />
                      </b-form-group>
                    </b-col>
                  </b-form-row>
 -->
                  <!-- <b-form-row>
                    <b-col>
                      <b-form-group label="Fahrer" label-class="label" label-cols="2">
                        <b-form-input size="sm" v-model="trip.driverName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Fahrttyp" label-class="label" label-cols="2">
                        <b-form-select size="sm" v-model="trip.category" :options="tripTypeOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Geschäftspartner" label-class="label" label-cols="2">
                        <b-form-input size="sm" v-model="trip.contactName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Firma" label-class="label" label-cols="2">
                        <b-form-input size="sm" v-model="trip.contactCompany" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Anlass" label-class="label" label-cols="2">
                        <b-form-input size="sm" v-model="trip.reason" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-group label="Bemerkung" label-class="label" label-cols="2">
                        <b-form-input size="sm" v-model="trip.comment" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row> -->


                  <b-form-row>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.driver')" label-class="label" >
                        <b-form-input v-model="trip.driverName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.category')" label-class="label" >
                        <b-form-select v-model="trip.category" :options="tripTypeOptions"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row v-if="trip.category == 'business'">
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.contactName')" label-class="label" >
                        <b-form-input v-model="trip.contactName" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.contactCompany')" label-class="label" >
                        <b-form-input v-model="trip.contactCompany" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>

                  <b-form-row>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.reason')" label-class="label" >
                        <b-form-input v-model="trip.reason" type="text" :disabled="isBusy"/>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group :label="$t('view.trips.editor.comment')" label-class="label" >
                        <b-form-input v-model="trip.comment" type="text"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-form-row>
              </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-danger" v-if="trip.id" :disabled="isBusy" @click="onDeleteClicked()">{{$t('modal.button.delete')}}</b-button>
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button>
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

import TripMap from '@/components/TripMap'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import TripApi from '@/api/trip';
import DisplayUtils from '@/utils/display-utils'
import { DateTime } from 'luxon'
import _ from "underscore";

export default {
  components: {
      TripMap,
      VueTypeaheadBootstrap,
  },
  props: {
      trip: { type: Object, required: true },
      isBusy: { type: Boolean, default: false },
  },
  data: function() {
    return {
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      track: null,
      mapOptions: {
        streetViewControl: false,
        disableDefaultUI: true,
        zoomControl: true,
      },
      markers: [],
      
      placeStartSuggestions: [],
      placeStartSearchString: '',
      placeStartSelectedAddressString: '',
      
      placeEndSuggestions: [],
      placeEndSearchString: '',
      placeEndSelectedAddressString: '',

      tripTypeOptions: [
        { value: null, text: this.$t('trip.categories.empty') },
        { value: 'business', text: this.$t('trip.categories.business') },
        { value: 'private', text: this.$t('trip.categories.private') },
        { value: 'commute', text: this.$t('trip.categories.commute') },
        { value: 'family', text: this.$t('trip.categories.family') },
        { value: 'honorary', text: this.$t('trip.categories.honorary') },
      ],
    }
  },
  computed: {
    editorTitle() {
        if (this.trip.id) {
            return this.$t('view.trips.editor.title.edit')
        } else {
            return this.$t('view.trips.editor.title.add')
        }
    },
    placeStart() {
      return DisplayUtils.stringFromPlace(this.trip.placeStart)
    },
    placeEnd() {
      return DisplayUtils.stringFromPlace(this.trip.placeEnd)
    },
  },
  methods: {
    onSaveClicked: function() {
      this.$emit('save')
    },
    onDeleteClicked: function() {
      this.$emit('delete')
    },

    getPlaceStartSuggestions(queryString) {
      this.getPlaceSuggestions(queryString, results => {
            this.placeStartSuggestions = results;
      })
    },

    getPlaceEndSuggestions(queryString) {
      this.getPlaceSuggestions(queryString, results => {
            this.placeEndSuggestions = results;
      })
    },

    updateMap: function() {
      let markers = []
      let mapOptions = {
        ...this.mapOptions,
        center: {
          lat: 48.45342,
          lng: 9.09484,
        },
        zoom: 3,
      }
      let coordinateStart = this.trip.coordinateStart
      let coordinateEnd = this.trip.coordinateEnd
      if (this.trip.placeStart) {
        if (this.trip.placeStart.coordinate) {
          coordinateStart = this.trip.placeStart.coordinate
        }
      }
      if (coordinateStart) {
          markers.push({
            place: this.trip.placeStart,
            lat: coordinateStart.latitude,
            lng: coordinateStart.longitude,
          })
      }
      if (this.trip.placeEnd) {
        if (this.trip.placeEnd.coordinate) {
          coordinateEnd = this.trip.placeEnd.coordinate
        }
      }
      if (coordinateEnd) {
          markers.push({
            place: this.trip.placeEnd,
            lat: coordinateEnd.latitude,
            lng: coordinateEnd.longitude,
          })
      }
      if (markers.length > 0) {
        let centerLat = markers.reduce((prev, current) => prev + current.lat, 0) / markers.length
        let centerLng = markers.reduce((prev, current) => prev + current.lng, 0) / markers.length
        mapOptions = {
          ...this.mapOptions,
          center: {
            lat: centerLat,
            lng: centerLng,
          },
          zoom: 18
        }
      }

      this.mapOptions = mapOptions
      this.markers = markers
    },

    async onAddressSelected(placeStartOrEnd, address) {
      if (placeStartOrEnd === 'start') {
        this.placeStartSelectedAddressString = address.description
      } else if (placeStartOrEnd === 'end') {
        this.placeEndSelectedAddressString = address.description
      }
      const request = {
        placeId: address.place_id,
      };

      this.$refs.map.placesService.getDetails(request, (result, status) => {
        if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
          let details = {};
          result.address_components.forEach((component) => {
            component.types.forEach((type) => {
              details[type] = component.long_name;
              details[type + "short"] = component.short_name;
            });
          });

          // compile dlp address
          let address = {};
          let streetArray = [];
          if (details.route) {
            streetArray.push(details.route);
          }
          if (details.street_number) {
            streetArray.push(details.street_number);
          }
          address.street = streetArray.join(" ");
          address.postalCode = details.postal_code;
          address.city = details.locality;
          address.country = details.country;

          let place = {};
          place.address = address;
          if (result.geometry && result.geometry.location) {
            place.coordinate = {
              latitude: result.geometry.location.lat(),
              longitude: result.geometry.location.lng(),
            };
          }

          if (placeStartOrEnd === 'start') {
            this.trip.placeStart = place
          } else if (placeStartOrEnd === 'end') {
            this.trip.placeEnd = place
          }
          this.updateMap()
        }
      })
    },

    getPlaceSuggestions(queryString, callback) {
      let request = {
        input: queryString
      }

      this.$refs.map.autocompleteService.getPlacePredictions(
        request,
        (results, status) => {
          if (status === this.$refs.map.google.maps.places.PlacesServiceStatus.OK) {
            callback(results);
          } else {
            callback(null);
          }
        }
      )
    },

    updateDateStart() {
      this.trip.dateStartIso = DateTime.fromISO(this.dateStart+'T'+this.timeStart, { zone: this.trip.dateStartTz }).toISO()
    },

    updateDateEnd() {
      this.trip.dateEndIso = DateTime.fromISO(this.dateEnd+'T'+this.timeEnd, { zone: this.trip.dateEndTz }).toISO()
    },

  },
  mounted: async function() {
    this.updateMap()
  },
  watch: {
    placeStartSearchString: _.debounce(function(queryString) {
      if (queryString == this.placeStartSelectedAddressString) {
        return
      }
      this.trip.placeStart = {
        name: queryString,
        coordinate: null,
      }
      this.getPlaceStartSuggestions(queryString)
    }, 500),

    placeEndSearchString: _.debounce(function(queryString) {
      if (queryString == this.placeEndSelectedAddressString) {
        return
      }
      this.trip.placeEnd = {
        name: queryString,
        coordinate: null,
      }
      this.getPlaceEndSuggestions(queryString)
    }, 500),

    dateStart: function() {
      this.updateDateStart()
    },

    timeStart: function() {
      this.updateDateStart()
    },

    dateEnd: function() {
      this.updateDateEnd()
    },

    timeEnd: function() {
      this.updateDateEnd()
    },

    async trip() {
      if (this.trip.placeStart) {
        this.placeStartSelectedAddressString = DisplayUtils.stringFromPlace(this.trip.placeStart)
      } else {
        this.placeStartSelectedAddressString = ''
      }
      if (this.trip.placeEnd) {
        this.placeEndSelectedAddressString = DisplayUtils.stringFromPlace(this.trip.placeEnd)
      } else {
        this.placeEndSelectedAddressString = ''
      }
      this.placeStartSearchString = this.placeStartSelectedAddressString
      this.placeEndSearchString = this.placeEndSelectedAddressString

      let dateTimeStart = DateTime.fromISO(this.trip.dateStartIso)
      dateTimeStart = dateTimeStart.setZone(this.trip.dateStartTz)
      this.dateStart = dateTimeStart.toISODate()
      this.timeStart = dateTimeStart.toFormat('HH:mm')
      let dateTimeEnd = DateTime.fromISO(this.trip.dateEndIso)
      dateTimeEnd = dateTimeEnd.setZone(this.trip.dateEndTz)
      this.dateEnd = dateTimeEnd.toISODate()
      this.timeEnd = dateTimeEnd.toFormat('HH:mm')

      this.track = await TripApi.getTrack(this.trip.id)

      this.updateMap()
    }
  },

}
</script>

<style scoped>
.map {
  height: 250px;
}
.label {
    font-size: 0.8em;
    padding-bottom: 0px;
}
</style>
