import Vue from 'vue'

export default {
    async getVehicle(vehicleId) {
        let result = await Vue.axios.get('/api/vehicles/' + vehicleId)
        return result.data
    },

    async updateVehicle(vehicleId, objectInfo) {
        let result = await Vue.axios.patch('/api/vehicles/' + vehicleId, {
            data: {
                id: vehicleId,
                type: 'vehicle',
                attributes: objectInfo
            }
        })
        return result.data
    },

    async deleteVehicle(vehicleId) {
        let result = await Vue.axios.delete('/api/vehicles/' + vehicleId)
        return result.data
    },

    async getUsers(vehicleId, parameters) {
        let result = await Vue.axios.get('/api/vehicles/' + vehicleId + '/users', {
            params: parameters
        })
        return result.data
    },

    async setUser(vehicleId, userId, roles) {
        let result = await Vue.axios.patch('/api/vehicles/' + vehicleId + '/users', {
            data: {
                type: 'user',
                id: userId,
                attributes: {
                    roles: roles
                }
            }
        })
        return result.data
    },

    // --------------------------------------
    //  Trips
    // --------------------------------------
    async getTrips(vehicleId, parameters) {
        let result = await Vue.axios.get('/api/vehicles/' + vehicleId + '/trips', {
            params: parameters
        })
        return result.data
    },

    async addTrip(vehicleId, attributes) {
        let result = await Vue.axios.post('/api/vehicles/' + vehicleId + '/trips/', {
            data: {
                type: 'trip',
                attributes: attributes
            }
        })
        return result.data
    },

    // --------------------------------------
    //  Expenses
    // --------------------------------------
    async getExpenses(vehicleId, parameters) {
        let result = await Vue.axios.get('/api/vehicles/' + vehicleId + '/expenses', {
            params: parameters
        })
        return result.data
    },

    async addExpense(vehicleId, attributes) {
        let result = await Vue.axios.post('/api/vehicles/' + vehicleId + '/expenses/', {
            data: {
                type: 'expense',
                attributes: attributes
            }
        })
        return result.data
    },
    
}