<template>
<page-layout :categories="categories">
    <template v-slot:header_large>
        <div class="title">{{$t('view.settings.title')}}</div>
    </template>
    <div>
        <router-view/>
    </div>
</page-layout>
</template>

<script>
import PageLayout from '@/views/layouts/PageLayout.vue'
import PermissionUtils from "@/utils/permission-utils"

export default {
    components: {
        PageLayout,
    },

    data: function () {
        return {
            categories: []
        };
    },

    computed: {
        company() {
            return this.$store.getters['User/company'](this.$route.params.companyId)
        },
        title() {
            return 'Settings'
        },
        canViewSubscription() {
            return PermissionUtils.isAuthorizedInCompany('company:subscription:view', this.company)
        },
        canViewCompany() {
            return PermissionUtils.isAuthorizedInCompany('company:view', this.company)
        },
    },

    watch: {
        async company() {
            await this.reloadData();
        }
    },

    methods: {
        async reloadData() {
            let categories = []
            if (this.canViewCompany) {
                categories.push({ id: 'general', label: this.$t('view.settings.categories.general'), target: 'SettingsGeneral'})
            }
            if (this.canViewSubscription) {
                categories.push({ id: 'billing', label: this.$t('view.settings.categories.billing'), target: 'SettingsBilling'})
            }
            this.categories = categories
        }
    },

    mounted: async function() {
        await this.reloadData()
    },
};
</script>

<style scoped>
table {
    width: 100%;
}
th {
    padding: 5px;
}
td {
    padding: 5px;
}
tr:hover {
    background-color: lightgray;
}
</style>