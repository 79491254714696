// import UserApi from '../api/user'
import store from '../store'

export async function prefetchAppData() {
  await store.dispatch('User/updateUserInfo')
  await store.dispatch('User/updateCompanies').then(async () => {
    let companies = store.state.User.companies
    // select last selected company if available
    // select own company if activated (name != null)
    // select first 'other' company
    // show 'activate company or get invited' screen

    // select last selected company if available
    if (localStorage.companyId) {
      let company = companies.find(company => company.id == localStorage.companyId)
      if (company) {
        // console.log('Setting last selected company')
        await store.dispatch("setCompany", company.id);
        return
      }
    }

    // select own company if activated (name != null)
    let userCompany = companies.find(company => company.roles.includes("company_owner"))
    if (userCompany?.name != null) {
      // console.log('Setting users company')
      await store.dispatch("setCompany", userCompany.id);
      return
    }

    // select first 'other' company
    let company = companies.find(company => company.name != null)
    if (company) {
      // console.log(`Setting company ${company.id}`)
      store.dispatch('setCompany', company.id)
      return
    }
    console.log("No company found!")

    store.dispatch('setCompany', null)

    // let company = store.state.User.companies.find(company => company.id == localStorage.companyId)
    // if (!company) {
    //   company = store.getters['User/ownedCompany']
    // }
    // await store.dispatch("setCompany", company.id);
  });
  await store.dispatch('User/updateInvitations')

  // return await UserApi.getInvitations()
}