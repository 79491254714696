<template>
    <div>
        <b-modal id="modal-editor" size="lg" :title="$t('view.vehicle.userpicker.title')" ok-only no-stacking centered scrollable>
            <template #default="">
              <b-container fluid class="p-0">
                <b-row>
                  <b-col>
                      <SearchField class="w-100" :placeholder="$t('view.employees.searchfield.placeholder')" @searchStringChanged="onSearchChanged"/>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table striped hover selectable select-mode="single" :items="getItems" @row-clicked="onRowClicked" >
                            <template #cell(firstName)="data">
                                {{ data.item.firstName }}
                            </template>
                            <template #cell(lastName)="data">
                                {{ data.item.lastName }}
                            </template>
                            <template #cell(roles)="data">
                                {{ data.item.email }}
                            </template>
                        </b-table>
                        <div v-if="isLoading" class="loading-bar">
                            <div><b-spinner small label="Spinning"></b-spinner> Einträge werden geladen...</div>
                        </div>
                      <div v-observe-intersection="onEndOfTableVisibilityChanged"></div>
                    </b-col>
                </b-row>
              </b-container>
          </template>
          <template #modal-footer="{ cancel }">
            <div class="d-flex w-100">
              <b-button variant="outline-secondary" class="ml-auto" :disabled="isBusy"  @click="cancel()">{{$t('modal.button.cancel')}}</b-button>
              <!-- <b-button variant="success" class="ml-2" :disabled="isBusy" @click="onSaveClicked()">{{$t('modal.button.save')}}</b-button> -->
            </div>
          </template>
        </b-modal>
  </div>
</template>

<script>

import CompanyApi from '@/api/company'
import SearchField from "@/components/SearchField.vue";

export default {
  components: {
    SearchField,
  },
  props: {
  },
  data: function() {
    return {
      isBusy: false,
      isLoading: false,
      items: [],
      isEndOfTableVisible: true,
      searchString: '',
      currentPage: 1,
      limit: 50,
      totalItems: -1,
    }
  },
  computed: {
    company() {
      return this.$store.getters['User/company'](this.$route.params.companyId)
    },
    getItems() {
        return this.items.map((employee) => {
            return {
                firstName: employee.firstName,
                lastName: employee.lastName,
                email: employee.email,
            };
        });
    },
  },
  methods: {
    onSearchChanged(searchString) {
      this.searchString = searchString
      this.reloadItems()
    },
    onEndOfTableVisibilityChanged(el) {
      this.isEndOfTableVisible = el.isIntersecting
      this.checkAutoappendItems()
    },
    async onRowClicked(item, index) {
      const user = this.items[index]
      this.$emit('user-selected', user.id, ['vehicle_user'])
    },
    async reloadItems() {
      this.items = []
      this.totalItems = -1
      await this.appendItems()
    },
    async appendItems() {
      if (this.company && (this.totalItems == -1 || this.items.length < this.totalItems)) {
        this.isLoading = true;
        // await new Promise(r => setTimeout(r, 2000));
        let offset = this.items.length
        const result = await CompanyApi.getUsers(this.company.id, {
            "page[limit]": this.limit,
            "page[offset]": offset,
            "filter": this.searchString,
        })

        if (result.data) {
          // console.log(`Received ${result.data.length} trips`);
          result.data.forEach(item => {
            this.items.push({
              id: item.id,
              ...item.attributes
            })
          })
        }
        var total = this.items.length
        if (result.meta) {
          if (result.meta.page) {
            total = result.meta.page.total
          }
        }
        this.totalItems = total
        this.isLoading = false;
        if (result.data.length > 0) {
          setTimeout(() => {
            this.checkAutoappendItems()
          }, 20)
        }
      }
    },
    async checkAutoappendItems() {
      if (!this.isMounted || this.isLoading || !this.isEndOfTableVisible) {
          return
      }
      await this.appendItems()
    },
  },
  mounted: async function() {
    this.reloadItems();
    this.isMounted = true
  },
  watch: {
  },

}
</script>

<style scoped>
.label {
    font-size: 0.8em;
}
</style>
