// initial state
const state = () => ({
    user: {
        isAuthenticated: false,
        name: "",
        idToken: "",
        accessToken: "",
        logoutUrl: null
      }
})

// getters
const getters = {
    getAccessToken(state) {
        return state.user.accessToken
    }
}

// actions
const actions = {
    async login({ commit }, payload) {
        commit('SET_LOGIN_STATE', payload)
    },
    async logout({ state, commit }) {
        let logoutUrl = state.user.logoutUrl
        window.location.href = logoutUrl
        commit('SET_LOGOUT_STATE')
    },
}

// mutations
const mutations = {
    SET_LOGIN_STATE(state, payload) {
        state.user.isAuthenticated = true;
        state.user.idToken = payload.idToken;
        state.user.accessToken = payload.accessToken;
        state.user.logoutUrl = payload.logoutUrl;
    },
    SET_LOGOUT_STATE(state) {
        state.user.isAuthenticated = false;
        state.user.name = "";
        state.user.idToken = "";
        state.user.accessToken = "";
        state.user.logoutUrl = null;
    },
  
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}